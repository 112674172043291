import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";

import { withStyles } from "@material-ui/core";
import ChevronRight from "@material-ui/icons/ChevronRight";

import { StatusUpdate, StatusUpdatePartnership } from "../models";

import TextField from "../../common/TextField";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import CustomTableCell from "../../common/TableCell";
import { createSelector } from "../../common/orm";

const getPartnerships = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.StatusUpdatePartnership.filter({ statusupdate: id }).orderBy("id").toModelArray();
    }
);

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
});

const numberRender = (rowData) => {
    return rowData ? formatter.format(rowData) : "-";
};

const styles = theme => ({
    rightAlign: {
        textAlign: "right"
    },
    button: {
        marginRight: theme.spacing(2)
    },
    minWidth: {
        minWidth: 600
    },
    table: {
        width: "100%",
        "& tbody tr:nth-child(even)": {
            backgroundColor: "#ffeff2"
        },
        marginBottom:16
    },
    centerAlign: {
        textAlign: "center"
    },
    totalRow: {
        backgroundColor: "rgb(221, 221, 221) !important",
        borderTop: "3px solid #000",
        height:36
    }
});

class BudgetTab extends Component {
    componentDidMount() {
        const { handleUnsavedFields } = this.props;
        this.props.onRef(this);
        document.title = "Status Update: Budget - Lessard Sams Outdoor Heritage Council";
        const _this = this;
        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted
        setTimeout(function() {
            handleUnsavedFields(false);
            if (_this.props.appContainer.current)
                _this.props.appContainer.current.scrollTop();
        }, 1);
    };

    componentWillUnmount() {
        this.props.onRef(undefined);
    };

    updateStatusReport(values, fromStepper) {
        const { ormStatusUpdateUpdate, history, handleUnsavedFields, handleNext, status_update } = this.props;
        const { id } = status_update;

        if (Number.isInteger(fromStepper)) {
            values.activeStep = fromStepper;
        }

        ormStatusUpdateUpdate({
            id: id,
            activeStep: values.activeStep,
            partner_differences: values.partner_differences,
        });

        handleUnsavedFields(false);

        if (!Number.isInteger(fromStepper)) {
            if (this.state.draftClick) {
                history.push("/dashboard/");
            } else {
                handleNext();
            }
        }
    };

    columnData2 = [
        { id: "partnership", label: "Partnership", allowSort: false },
        { id: "appropriated", label: "Amount Appropriated", allowSort: false },
        { id: "reimbursed", label: "Amount Reimbursed to Date", allowSort: false },
    ];
    typingTimer = [];
    typingTimer2 = [];
    objectsToUpdate = {};
    formkeys = {};

    updateRecord = (value, element, id) => {
        const { ormStatusUpdatePartnershipUpdate, ormStatusUpdatePartnershipUpdateLocalOnly } = this.props;
        const comp = this;

        // Save each individual value locally
        clearTimeout(this.typingTimer2[id + element]);
        this.typingTimer2[id + element] = setTimeout(function() {
            value = (Math.round(parseInt(value) / 100) * 100) || 0;
            var vals = {
                id: id,
                [element]: value
            }
            ormStatusUpdatePartnershipUpdateLocalOnly(vals);
            comp.objectsToUpdate[id] = { ...comp.objectsToUpdate[id], ...vals };
        }, 50);

        // Save the row once finished editing
        clearTimeout(this.typingTimer[id]);
        this.typingTimer[id] = setTimeout(function() {
            ormStatusUpdatePartnershipUpdate(comp.objectsToUpdate[id]);
        }, 2500);
    };

    updateForm = (field, id) => {
        this.formkeys[id + field] = window.performance.now();
        this.setState({foo:"bar"});
    };

    render() {
        const { 
            classes,
            status_update,
            handleUnsavedFields,
            ormStatusUpdateUpdateLocalOnly,
            ormStatusUpdateUpdate,
            ActionMenu, authState, partnerships
        } = this.props;
        var is_read_only = true;
        if ((authState && authState.user && authState.user.role === "admin") || status_update.status === "Draft")
            is_read_only = false;
        if (authState && authState.user && authState.user.role === "readonly")
            is_read_only = true;

        if (partnerships && partnerships.length > 0) {
            var appr = 0
            var reimb = 0
            partnerships.forEach(p => {
                appr += p.appropriated;
                reimb += p.reimbursed;
            })
        }

        return (
            <Form
                getApi={el => (this.form = el)}
                //key={this.formkey}
                dontValidateOnMount={true}
                validateOnSubmit={true}
                defaultValues={status_update.formData}
                formDidUpdate={() => handleUnsavedFields(true)}
                onSubmit={(values, fromStepper) => this.updateStatusReport(values, fromStepper)}>
                {formApi => (
                    <form onSubmit={formApi.submitForm}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} lg={10} xl={8}>
                               {ActionMenu}
                            </Grid>
                            <Grid item xs={12} lg={10} xl={8}>
                                <Typography variant="h3" gutterBottom className={classes.centerAlign}>
                                    For non-state agencies: The budget should reflect the money spent to date on the project, 
                                    which may not necessarily be what has been submitted for reimbursement.
                                </Typography>
                                <BudgetTable
                                    ormStatusUpdateUpdateLocalOnly={ormStatusUpdateUpdateLocalOnly}
                                    ormStatusUpdateUpdate={ormStatusUpdateUpdate}
                                    is_read_only={is_read_only}
                                    status_update={status_update}
                                    classes={classes}
                                />
                            </Grid>
                            {partnerships && partnerships.length > 0 && ( 
                                <>
                                <Grid item xs={12} lg={10} xl={8}>
                                    <Table className={classes.table}>
                                        <EnhancedTableHead columnData={this.columnData2} />
                                        <TableBody>
                                            {partnerships.map(n => {
                                                return (
                                                    <TableRow hover>
                                                        <CustomTableCell>{n.partnership}</CustomTableCell>
                                                        <CustomTableCell>{numberRender(n.appropriated)}</CustomTableCell>
                                                        <CustomTableCell>
                                                            <Form key={this.formkeys[n.id + "reimbursed"] ? this.formkeys[n.id + "reimbursed"]  : n.id + "reimbursed"} defaultValues={n}>
                                                                {formApi => (
                                                                    <form onSubmit={formApi.submitForm}>
                                                                        <TextField 
                                                                            disabled={is_read_only} useTextFormat tableFormat 
                                                                            eventHandle={this.updateRecord}
                                                                            eventHandleProps1={n.id}
                                                                            field="reimbursed" 
                                                                            label="" 
                                                                            onBlur={this.updateForm}
                                                                        />
                                                                    </form>
                                                                )}
                                                            </Form>
                                                        </CustomTableCell>
                                                    </TableRow>
                                                )
                                            })}
                                            <TableRow  className={classes.totalRow}>
                                                <CustomTableCell><b>Total</b></CustomTableCell>
                                                <CustomTableCell>{numberRender(appr)}</CustomTableCell>
                                                <CustomTableCell>{numberRender(reimb)}</CustomTableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Grid>
                                <Grid item xs={12} lg={10} xl={8}>
                                    <TextField 
                                        disabled={is_read_only}
                                        field="partner_differences"
                                        fullWidth
                                        multiline
                                        rows={4}
                                        rowsMax={10}
                                        label="Explain any differences between the total partner reimbursements to date and the budget spent to date total." 
                                    />
                                </Grid>
                                </>
                            )}
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={12} lg={10} xl={8} className={classes.rightAlign}>
                                <Button
                                    variant="contained"
                                    disabled={is_read_only}
                                    type="submit"
                                    onClick={() => this.setState({ draftClick: true, submitClicked: true })}
                                    className={classes.button}>
                                    Save Draft and Return to Dashboard
                                </Button>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={is_read_only}
                                    color="primary"
                                    onClick={() => this.setState({ draftClick: false, submitClicked: true })}>
                                    Save and Proceed to Output Tables <ChevronRight />
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Form>
        );
    }
}

class BudgetTable extends Component {
    columnData = [
        { id: "item", label: "Item", allowSort: false },
        { id: "funding_request", label: "AP Amount", allowSort: false },
        { id: "anticipated_leverage", label: "Spent to Date", allowSort: false },
        { id: "percent_spent", label: "% Spent", allowSort: false },
        { id: "left_toPsned", label: "OHF $ Left to Spend", allowSort: false },
    ];
    typingTimer = [];
    typingTimer2 = [];
    objectsToUpdate = {};
    formkeys = {};

    updateRecord = (value, element) => {
        const { ormStatusUpdateUpdateLocalOnly, ormStatusUpdateUpdate, status_update } = this.props;
        const comp = this;

        // Save each individual value locally
        clearTimeout(this.typingTimer2[element]);
        this.typingTimer2[element] = setTimeout(function() {
            var vals = {
                id: status_update.id,
                [element]: (Math.round(parseInt(value) / 100) * 100) || 0
            };
            ormStatusUpdateUpdateLocalOnly(vals);
            
            // Later properties overwrite earlier properties with the same name.
            comp.objectsToUpdate = { ...comp.objectsToUpdate, ...vals };
        }, 50);

        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(function() {
            ormStatusUpdateUpdate(comp.objectsToUpdate);
        }, 2500);
    };

    updateForm = (field) => {
        this.formkeys[field] = window.performance.now();
        this.setState({foo:"bar"});
    };

    render() {
        const { classes, is_read_only, status_update } = this.props;

        var spent = 0;
        spent += status_update.personnel_spent;
        spent += status_update.contracts_spent;
        spent += status_update.fee_with_pilt_spent;
        spent += status_update.fee_without_pilt_spent;
        spent += status_update.easement_acquisition_spent;
        spent += status_update.easement_stewardship_spent;
        spent += status_update.travel_spent;
        spent += status_update.professional_spent;
        spent += status_update.direction_support_spent;
        spent += status_update.land_acquisition_spent;
        spent += status_update.capital_equipment_spent;
        spent += status_update.other_equipment_spent;
        spent += status_update.supplies_spent;
        spent += status_update.dnr_idp_spent;

        var q1_total = status_update.ap_budgets.find(b => b.item === "Personnel").total;
        var q2_total = status_update.ap_budgets.find(b => b.item === "Contracts").total;
        var q3_total = status_update.ap_budgets.find(b => b.item === "Fee Acquisition w/ PILT").total;
        var q4_total = status_update.ap_budgets.find(b => b.item === "Fee Acquisition w/o PILT").total;
        var q5_total = status_update.ap_budgets.find(b => b.item === "Easement Acquisition").total;
        var q6_total = status_update.ap_budgets.find(b => b.item === "Easement Stewardship").total;
        var q7_total = status_update.ap_budgets.find(b => b.item === "Travel").total;
        var q8_total = status_update.ap_budgets.find(b => b.item === "Professional Services").total;
        var q9_total = status_update.ap_budgets.find(b => b.item === "Direct Support Services").total;
        var q10_total = status_update.ap_budgets.find(b => b.item === "DNR Land Acquisition Costs").total;
        var q11_total = status_update.ap_budgets.find(b => b.item === "Capital Equipment").total;
        var q12_total = status_update.ap_budgets.find(b => b.item === "Other Equipment/Tools").total;
        var q13_total = status_update.ap_budgets.find(b => b.item === "Supplies/Materials").total;
        var q14_total = status_update.ap_budgets.find(b => b.item === "DNR IDP").total;
        var q15_total = status_update.ap_budgets.find(b => b.item === "Grand Total").total;

        return (
            <Table className={classes.table}>
                <EnhancedTableHead columnData={this.columnData} />
                <TableBody>
                    <TableRow hover>
                        <CustomTableCell>Personnel</CustomTableCell>
                        <CustomTableCell>{numberRender(q1_total)}</CustomTableCell>
                        <CustomTableCell>
                            <Form key={this.formkeys["personnel_spent"] ? this.formkeys["personnel_spent"]  : "personnel_spent"} defaultValues={status_update}>
                                {formApi => (
                                    <form onSubmit={formApi.submitForm}>
                                        <TextField 
                                            disabled={is_read_only} useTextFormat tableFormat 
                                            eventHandle={this.updateRecord} 
                                            field="personnel_spent" label="" onBlur={this.updateForm}
                                        />
                                    </form>
                                )}
                            </Form>
                        </CustomTableCell>
                        <CustomTableCell>{!q1_total ? "-" : (((status_update.personnel_spent ? status_update.personnel_spent : 0) / q1_total) * 100).toFixed(2) + "%"}</CustomTableCell>
                        <CustomTableCell>{numberRender(q1_total - (status_update.personnel_spent ? status_update.personnel_spent : 0))}</CustomTableCell>
                    </TableRow>
                    <TableRow hover>
                        <CustomTableCell>Contracts</CustomTableCell>
                        <CustomTableCell>{numberRender(q2_total)}</CustomTableCell>
                        <CustomTableCell>
                            <Form key={this.formkeys["contracts_spent"] ? this.formkeys["contracts_spent"]  : "contracts_spent"} defaultValues={status_update}>
                                {formApi => (
                                    <form onSubmit={formApi.submitForm}>
                                        <TextField 
                                            disabled={is_read_only} useTextFormat tableFormat 
                                            eventHandle={this.updateRecord} 
                                            field="contracts_spent" label="" onBlur={this.updateForm}
                                        />
                                    </form>
                                )}
                            </Form>
                        </CustomTableCell>
                        <CustomTableCell>{!q2_total ? "-" : (((status_update.contracts_spent ? status_update.contracts_spent : 0) / q2_total) * 100).toFixed(2) + "%"}</CustomTableCell>
                        <CustomTableCell>{numberRender(q2_total - (status_update.contracts_spent ? status_update.contracts_spent : 0))}</CustomTableCell>
                    </TableRow>
                    <TableRow hover>
                        <CustomTableCell>Fee Acquisition w/ PILT</CustomTableCell>
                        <CustomTableCell>{numberRender(q3_total)}</CustomTableCell>
                        <CustomTableCell>
                            <Form key={this.formkeys["fee_with_pilt_spent"] ? this.formkeys["fee_with_pilt_spent"]  : "fee_with_pilt_spent"} defaultValues={status_update}>
                                {formApi => (
                                    <form onSubmit={formApi.submitForm}>
                                        <TextField 
                                            disabled={is_read_only} useTextFormat tableFormat 
                                            eventHandle={this.updateRecord} 
                                            field="fee_with_pilt_spent" label="" onBlur={this.updateForm}
                                        />
                                    </form>
                                )}
                            </Form>
                        </CustomTableCell>
                        <CustomTableCell>{!q3_total ? "-" : (((status_update.fee_with_pilt_spent ? status_update.fee_with_pilt_spent : 0) / q3_total) * 100).toFixed(2) + "%"}</CustomTableCell>
                        <CustomTableCell>{numberRender(q3_total - (status_update.fee_with_pilt_spent ? status_update.fee_with_pilt_spent : 0))}</CustomTableCell>
                    </TableRow>
                    <TableRow hover>
                        <CustomTableCell>Fee Acquisition w/o PILT</CustomTableCell>
                        <CustomTableCell>{numberRender(q4_total)}</CustomTableCell>
                        <CustomTableCell>
                            <Form key={this.formkeys["fee_without_pilt_spent"] ? this.formkeys["fee_without_pilt_spent"]  : "fee_without_pilt_spent"} defaultValues={status_update}>
                                {formApi => (
                                    <form onSubmit={formApi.submitForm}>
                                        <TextField 
                                            disabled={is_read_only} useTextFormat tableFormat 
                                            eventHandle={this.updateRecord} 
                                            field="fee_without_pilt_spent" label="" onBlur={this.updateForm}
                                        />
                                    </form>
                                )}
                            </Form>
                        </CustomTableCell>
                        <CustomTableCell>{!q4_total ? "-" : (((status_update.fee_without_pilt_spent ? status_update.fee_without_pilt_spent : 0) / q4_total) * 100).toFixed(2) + "%"}</CustomTableCell>
                        <CustomTableCell>{numberRender(q4_total - (status_update.fee_without_pilt_spent ? status_update.fee_without_pilt_spent : 0))}</CustomTableCell>
                    </TableRow>
                    <TableRow hover>
                        <CustomTableCell>Easement Acquisition</CustomTableCell>
                        <CustomTableCell>{numberRender(q5_total)}</CustomTableCell>
                        <CustomTableCell>
                            <Form key={this.formkeys["easement_acquisition_spent"] ? this.formkeys["easement_acquisition_spent"]  : "easement_acquisition_spent"} defaultValues={status_update}>
                                {formApi => (
                                    <form onSubmit={formApi.submitForm}>
                                        <TextField 
                                            disabled={is_read_only} useTextFormat tableFormat 
                                            eventHandle={this.updateRecord} 
                                            field="easement_acquisition_spent" label="" onBlur={this.updateForm}
                                        />
                                    </form>
                                )}
                            </Form>
                        </CustomTableCell>
                        <CustomTableCell>{!q5_total ? "-" : (((status_update.easement_acquisition_spent ? status_update.easement_acquisition_spent : 0) / q5_total) * 100).toFixed(2) + "%"}</CustomTableCell>
                        <CustomTableCell>{numberRender(q5_total - (status_update.easement_acquisition_spent ? status_update.easement_acquisition_spent : 0))}</CustomTableCell>
                    </TableRow>
                    <TableRow hover>
                        <CustomTableCell>Easement Stewardship</CustomTableCell>
                        <CustomTableCell>{numberRender(q6_total)}</CustomTableCell>
                        <CustomTableCell>
                            <Form key={this.formkeys["easement_stewardship_spent"] ? this.formkeys["easement_stewardship_spent"]  : "easement_stewardship_spent"} defaultValues={status_update}>
                                {formApi => (
                                    <form onSubmit={formApi.submitForm}>
                                        <TextField 
                                            disabled={is_read_only} useTextFormat tableFormat 
                                            eventHandle={this.updateRecord} 
                                            field="easement_stewardship_spent" label="" onBlur={this.updateForm}
                                        />
                                    </form>
                                )}
                            </Form>
                        </CustomTableCell>
                        <CustomTableCell>{!q6_total ? "-" : (((status_update.easement_stewardship_spent ? status_update.easement_stewardship_spent : 0) / q6_total) * 100).toFixed(2) + "%"}</CustomTableCell>
                        <CustomTableCell>{numberRender(q6_total - (status_update.easement_stewardship_spent ? status_update.easement_stewardship_spent : 0))}</CustomTableCell>
                    </TableRow>
                    <TableRow hover>
                        <CustomTableCell>Travel</CustomTableCell>
                        <CustomTableCell>{numberRender(q7_total)}</CustomTableCell>
                        <CustomTableCell>
                            <Form key={this.formkeys["travel_spent"] ? this.formkeys["travel_spent"]  : "travel_spent"} defaultValues={status_update}>
                                {formApi => (
                                    <form onSubmit={formApi.submitForm}>
                                        <TextField 
                                            disabled={is_read_only} useTextFormat tableFormat 
                                            eventHandle={this.updateRecord} 
                                            field="travel_spent" label="" onBlur={this.updateForm}
                                        />
                                    </form>
                                )}
                            </Form>
                        </CustomTableCell>
                        <CustomTableCell>{!q7_total ? "-" : (((status_update.travel_spent ? status_update.travel_spent : 0) / q7_total) * 100).toFixed(2) + "%"}</CustomTableCell>
                        <CustomTableCell>{numberRender(q7_total - (status_update.travel_spent ? status_update.travel_spent : 0))}</CustomTableCell>
                    </TableRow>
                    <TableRow hover>
                        <CustomTableCell>Professional Services</CustomTableCell>
                        <CustomTableCell>{numberRender(q8_total)}</CustomTableCell>
                        <CustomTableCell>
                            <Form key={this.formkeys["professional_spent"] ? this.formkeys["professional_spent"]  : "professional_spent"} defaultValues={status_update}>
                                {formApi => (
                                    <form onSubmit={formApi.submitForm}>
                                        <TextField 
                                            disabled={is_read_only} useTextFormat tableFormat 
                                            eventHandle={this.updateRecord} 
                                            field="professional_spent" label="" onBlur={this.updateForm}
                                        />
                                    </form>
                                )}
                            </Form>
                        </CustomTableCell>
                        <CustomTableCell>{!q8_total ? "-" : (((status_update.professional_spent ? status_update.professional_spent : 0) / q8_total) * 100).toFixed(2) + "%"}</CustomTableCell>
                        <CustomTableCell>{numberRender(q8_total - (status_update.professional_spent ? status_update.professional_spent : 0))}</CustomTableCell>
                    </TableRow>
                    <TableRow hover>
                        <CustomTableCell>Direct Support Services</CustomTableCell>
                        <CustomTableCell>{numberRender(q9_total)}</CustomTableCell>
                        <CustomTableCell>
                            <Form key={this.formkeys["direction_support_spent"] ? this.formkeys["direction_support_spent"]  : "direction_support_spent"} defaultValues={status_update}>
                                {formApi => (
                                    <form onSubmit={formApi.submitForm}>
                                        <TextField 
                                            disabled={is_read_only} useTextFormat tableFormat 
                                            eventHandle={this.updateRecord} 
                                            field="direction_support_spent" label="" onBlur={this.updateForm}
                                        />
                                    </form>
                                )}
                            </Form>
                        </CustomTableCell>
                        <CustomTableCell>{!q9_total ? "-" : (((status_update.direction_support_spent ? status_update.direction_support_spent : 0) / q9_total) * 100).toFixed(2) + "%"}</CustomTableCell>
                        <CustomTableCell>{numberRender(q9_total - (status_update.direction_support_spent ? status_update.direction_support_spent : 0))}</CustomTableCell>
                    </TableRow>
                    <TableRow hover>
                        <CustomTableCell>DNR Land Acquisition Costs</CustomTableCell>
                        <CustomTableCell>{numberRender(q10_total)}</CustomTableCell>
                        <CustomTableCell>
                            <Form key={this.formkeys["land_acquisition_spent"] ? this.formkeys["land_acquisition_spent"]  : "land_acquisition_spent"} defaultValues={status_update}>
                                {formApi => (
                                    <form onSubmit={formApi.submitForm}>
                                        <TextField 
                                            disabled={is_read_only} useTextFormat tableFormat 
                                            eventHandle={this.updateRecord} 
                                            field="land_acquisition_spent" label="" onBlur={this.updateForm}
                                        />
                                    </form>
                                )}
                            </Form>
                        </CustomTableCell>
                        <CustomTableCell>{!q10_total ? "-" : (((status_update.land_acquisition_spent ? status_update.land_acquisition_spent : 0) / q10_total) * 100).toFixed(2) + "%"}</CustomTableCell>
                        <CustomTableCell>{numberRender(q10_total - (status_update.land_acquisition_spent ? status_update.land_acquisition_spent : 0))}</CustomTableCell>
                    </TableRow>
                    <TableRow hover>
                        <CustomTableCell>Capital Equipment</CustomTableCell>
                        <CustomTableCell>{numberRender(q11_total)}</CustomTableCell>
                        <CustomTableCell>
                            <Form key={this.formkeys["capital_equipment_spent"] ? this.formkeys["capital_equipment_spent"]  : "capital_equipment_spent"} defaultValues={status_update}>
                                {formApi => (
                                    <form onSubmit={formApi.submitForm}>
                                        <TextField 
                                            disabled={is_read_only} useTextFormat tableFormat 
                                            eventHandle={this.updateRecord} 
                                            field="capital_equipment_spent" label="" onBlur={this.updateForm}
                                        />
                                    </form>
                                )}
                            </Form>
                        </CustomTableCell>
                        <CustomTableCell>{!q11_total ? "-" : (((status_update.capital_equipment_spent ? status_update.capital_equipment_spent : 0) / q11_total) * 100).toFixed(2) + "%"}</CustomTableCell>
                        <CustomTableCell>{numberRender(q11_total - (status_update.capital_equipment_spent ? status_update.capital_equipment_spent : 0))}</CustomTableCell>
                    </TableRow>
                    <TableRow hover>
                        <CustomTableCell>Other Equipment/Tools</CustomTableCell>
                        <CustomTableCell>{numberRender(q12_total)}</CustomTableCell>
                        <CustomTableCell>
                            <Form key={this.formkeys["other_equipment_spent"] ? this.formkeys["other_equipment_spent"]  : "other_equipment_spent"} defaultValues={status_update}>
                                {formApi => (
                                    <form onSubmit={formApi.submitForm}>
                                        <TextField 
                                            disabled={is_read_only} useTextFormat tableFormat 
                                            eventHandle={this.updateRecord} 
                                            field="other_equipment_spent" label="" onBlur={this.updateForm}
                                        />
                                    </form>
                                )}
                            </Form>
                        </CustomTableCell>
                        <CustomTableCell>{!q12_total ? "-" : (((status_update.other_equipment_spent ? status_update.other_equipment_spent : 0) / q12_total) * 100).toFixed(2) + "%"}</CustomTableCell>
                        <CustomTableCell>{numberRender(q12_total - (status_update.other_equipment_spent ? status_update.other_equipment_spent : 0))}</CustomTableCell>
                    </TableRow>
                    <TableRow hover>
                        <CustomTableCell>Supplies/Materials</CustomTableCell>
                        <CustomTableCell>{numberRender(q13_total)}</CustomTableCell>
                        <CustomTableCell>
                            <Form key={this.formkeys["supplies_spent"] ? this.formkeys["supplies_spent"]  : "supplies_spent"} defaultValues={status_update}>
                                {formApi => (
                                    <form onSubmit={formApi.submitForm}>
                                        <TextField 
                                            disabled={is_read_only} useTextFormat tableFormat 
                                            eventHandle={this.updateRecord} 
                                            field="supplies_spent" label="" onBlur={this.updateForm}
                                        />
                                    </form>
                                )}
                            </Form>
                        </CustomTableCell>
                        <CustomTableCell>{!q13_total ? "-" : (((status_update.supplies_spent ? status_update.supplies_spent : 0) / q13_total) * 100).toFixed(2) + "%"}</CustomTableCell>
                        <CustomTableCell>{numberRender(q13_total - (status_update.supplies_spent ? status_update.supplies_spent : 0))}</CustomTableCell>
                    </TableRow>
                    <TableRow hover>
                        <CustomTableCell>DNR IDP</CustomTableCell>
                        <CustomTableCell>{numberRender(q14_total)}</CustomTableCell>
                        <CustomTableCell>
                            <Form key={this.formkeys["dnr_idp_spent"] ? this.formkeys["dnr_idp_spent"]  : "dnr_idp_spent"} defaultValues={status_update}>
                                {formApi => (
                                    <form onSubmit={formApi.submitForm}>
                                        <TextField 
                                            disabled={is_read_only} useTextFormat tableFormat 
                                            eventHandle={this.updateRecord} 
                                            field="dnr_idp_spent" label="" onBlur={this.updateForm}
                                        />
                                    </form>
                                )}
                            </Form>
                        </CustomTableCell>
                        <CustomTableCell>{!q14_total ? "-" : (((status_update.dnr_idp_spent ? status_update.dnr_idp_spent : 0) / q14_total) * 100).toFixed(2) + "%"}</CustomTableCell>
                        <CustomTableCell>{numberRender(q14_total - (status_update.dnr_idp_spent ? status_update.dnr_idp_spent : 0))}</CustomTableCell>
                    </TableRow>
                    <TableRow className={classes.totalRow}>
                        <CustomTableCell><b>Grand Total</b></CustomTableCell>
                        <CustomTableCell><b>{numberRender(q15_total)}</b></CustomTableCell>
                        <CustomTableCell><b>{numberRender(spent)}</b></CustomTableCell>
                        <CustomTableCell>{((spent / q15_total) * 100).toFixed(2)}%</CustomTableCell>
                        <CustomTableCell>{numberRender(q15_total - spent)}</CustomTableCell>
                    </TableRow>
                </TableBody>
            </Table>
        );
    }
}

BudgetTab = connect(
    (state, ownProps) => ({
        authState: state.auth,
        partnerships: getPartnerships(state, ownProps),
    }),
    {
        ...StatusUpdate.actions,
        ...StatusUpdatePartnership.actions,
    }
)(BudgetTab);

export default withStyles(styles)(withRouter(BudgetTab));
