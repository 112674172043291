import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";

import { withStyles } from "@material-ui/core";
import ChevronRight from "@material-ui/icons/ChevronRight";
import AddCircle from "@material-ui/icons/AddCircle";
import EditIcon from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from "@material-ui/core/IconButton";

import { FinalReport, FinalBudget, FinalBudgetPersonnel, FinalBudgetCapitalEquipment } from "../models";

import TextField from "../../common/TextField";
import Checkbox from "../../common/Checkbox";
import Subquestion from "../../common/Subquestion";
import WarningDialog from "../../common/WarningDialog";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import CustomTableCell from "../../common/TableCell";
import CheckboxGroup from "../../common/CheckboxGroup";
import HelpLabel from "../../common/HelpLabel";
import { createSelector } from "../../common/orm";
import { getValue } from "../../../api/utils";
import { budget_items, MAKE_OPTIONS } from "../../../api/constants";


const getBudgets = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.FinalBudget.filter({ final: id }).orderBy("order").toModelArray()
        .map(budget => ({
            _personnel: budget.budgetpersonnel.all().toRefArray(),
            _capital: budget.budgetcapital.all().toRefArray(),
            _personnelOriginal: budget.budgetpersonneloriginal.all().toRefArray(),
            _capitalOriginal: budget.budgetcapitaloriginal.all().toRefArray(),
            ...budget.ref
        }));;
    }
);


const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
});

const numberRender = (rowData) => {
    return rowData ? formatter.format(rowData) : 0
};

const styles = theme => ({
    rightAlign: {
        textAlign: "right"
    },
    button: {
        marginRight: theme.spacing(2)
    },
    breakoutTable: {
        margin: 16,
        "& thead tr":{
            height: 40
        }
    },
    breakoutButton: {
        marginBottom: 16,
        color: "#fff !important"
    },
    checkbox: {
        display: "inline"
    },
    expansionSummaryRoot: {
        backgroundColor: "#eee",
        minHeight: 64,
        borderBottom: "1px dotted"
    },
    summaryRoot: {
        width: "100%",
        margin: "16px 0"
    },
    editIcon: {
        marginLeft: 8,
        marginBottom: -4
    },
    minWidth: {
        minWidth: 600
    },
    dialogMin: {
        minWidth: "400px",
        paddingTop: 0
    },
    flex: {
        flex: 1
    },
    deleteIcon: {
        float: "right"
    },
    table: {
        width: "100%",
        "& tbody tr:nth-child(even)": {
            backgroundColor: "#ffeff2"
        },
        marginBottom:16
    },
    deleteWidth: {
        minWidth: 32,
        width: 32,
        height: 32,
        marginRight: 8,
    },
    centerAlign: {
        textAlign: "center"
    },
    nowrap: {
        whiteSpace: "nowrap"
    },
    totalRow: {
        backgroundColor: "rgb(221, 221, 221) !important",
        borderTop: "3px solid #000",
        height:36
    },
    totalRow2: {
        backgroundColor: "rgb(221, 221, 221) !important",
        height:36
    },
    widthSixty: {
        width: 160
    },
    hidden: {
        visibility: "hidden"
    }
});

class BudgetTab extends Component {
    state = {
        partnershipActive: false,
        showRename: false,
        renameOpen: false,
        deleteOpen: false,
        renaming: null
    };
    parentFormKey = window.performance.now();

    componentDidMount() {
        const { handleUnsavedFields, final_report } = this.props;
        this.props.onRef(this);
        document.title = "Final Report: Budget - Lessard Sams Outdoor Heritage Council";
        const _this = this;
        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted
        setTimeout(function() {
            handleUnsavedFields(false);
            _this.props.appContainer.current.scrollTop();
        }, 1);

        if (getValue(final_report, "is_partnership")) {
            this.setState({partnershipActive: true});
        }
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    updateAP(values, fromStepper) {
        const { ormFinalReportUpdate, history, handleUnsavedFields, handleNext, final_report } = this.props;
        const { id } = final_report;

        if (Number.isInteger(fromStepper)) {
            values.activeStep = fromStepper;
        }

        values.modify_date_label = final_report.modify_date_label;
        values.signup_criteria = final_report.signup_criteria;
        values.disclose_revenue_balance = final_report.disclose_revenue_balance;
        //delete values["disclose_revenue_balance"]; // this isnt added to values?

        ormFinalReportUpdate({
            id: id,
            ...values
        });

        handleUnsavedFields(false);

        if (!Number.isInteger(fromStepper)) {
            if (this.state.draftClick) {
                history.push("/dashboard/");
            } else {
                handleNext();
            }
        }
    };

    groupBy = (list, keyGetter) => {
        const map = new Map();
        list.forEach((item) => {
             const key = keyGetter(item);
             const collection = map.get(key);
             if (!collection) {
                 map.set(key, [item]);
             } else {
                 collection.push(item);
             }
        });
        return map;
    };

    returnDialog = () => {
        this.setState({ renameOpen: false });
    };

    togglePartnership = (checked) => {
        const { budgets, ormFinalBudgetUpdate } = this.props;
        this.setState({partnershipActive: checked});
        if (checked) {
            budgets.forEach(function(b) {
                ormFinalBudgetUpdate({
                    id: b.id,
                    partnership: "New Partner 1"
                });
            });
        } else {
            budgets.forEach(function(b) {
                ormFinalBudgetUpdate({
                    id: b.id,
                    partnership: null
                });
            });
        }
    };

    addPartnership = () => {
        const { ormFinalBudgetCreate, final_report, budgets } = this.props;
        const grouped = this.groupBy(budgets, b => b.partnership);
        
        budget_items.forEach(function(item, i) {
            var readonly = false;
            if (item === "Personnel" || item === "Capital Equipment" || item === "Grand Total")
                readonly = true;
            ormFinalBudgetCreate({
                final: final_report.id,
                order: i,
                item: item,
                readonly: readonly,
                partnership: "New Partner " + (grouped.size + 1)
            })
        });
    };

    editPartnership = (name) => {
        this.setState({ renameOpen: true, renaming: name });
    };

    deletePartnership = (name) => {
        this.setState({ deleteOpen: true, renaming: name });
    };

    doDeletePartnership = (name) => {
        const { budgets, ormFinalBudgetDelete } = this.props;
        const grouped = this.groupBy(budgets, b => b.partnership);
        const partnershipsToDelete = grouped.get(name);
        partnershipsToDelete.forEach(function(p) {
            ormFinalBudgetDelete(p.id);
        });
        this.setState({ deleteOpen: false });
    };

    render() {
        const { 
            classes,
            final_report,
            handleUnsavedFields,
            budgets,
            ormFinalBudgetUpdate,
            ormFinalBudgetUpdateLocalOnly,
            ormFinalBudgetPersonnelCreate,
            ormFinalBudgetPersonnelUpdate,
            ormFinalBudgetPersonnelUpdateLocalOnly,
            ormFinalBudgetPersonnelDelete,
            ormFinalBudgetCapitalEquipmentCreate,
            ormFinalBudgetCapitalEquipmentUpdateLocalOnly,
            ormFinalBudgetCapitalEquipmentUpdate,
            ormFinalBudgetCapitalEquipmentDelete,
            ormFinalReportUpdateLocalOnly,
            settings, authState
            //ActionMenu
        } = this.props;
        const { partnershipActive, renameOpen, deleteOpen, renaming } = this.state;
        var is_read_only = true;
        if ((authState && authState.user && authState.user.role === "admin") || final_report.status === "Draft")
            is_read_only = false;
        if (authState && authState.user && authState.user.role === "readonly")
            is_read_only = true;

        const grouped = this.groupBy(budgets, b => b.partnership);
        const groupedItem = this.groupBy(budgets, b => b.item);
        var pships = [];
        if (partnershipActive) {
            budgets.forEach(function(b) {
                if (!pships.includes(b.partnership)) {
                    pships.push(b.partnership);
                }
            });
        }

        var _t1 = budgets.filter(b => b.item === "Grand Total");
        var _funding_request = 0;
        _t1.forEach(function(t) {
            _funding_request += t.funding_request_final
        })
        if (_funding_request !== final_report.formData._t1) {
            final_report.formData._t1 = _funding_request
            this.parentFormKey = window.performance.now();
        }
        var temp = parseFloat(final_report.funds_recommended.replace(/,/g, '')) - parseFloat(_funding_request);
        if (temp !== final_report.formData._t4) {
            final_report.formData._t4 = temp;
            this.parentFormKey = window.performance.now();
        }

        return (
            <>
            <Form
                getApi={el => (this.form = el)}
                dontValidateOnMount={true}
                validateOnSubmit={true}
                defaultValues={final_report.formData}
                formDidUpdate={() => handleUnsavedFields(true)}
                onSubmit={(values, fromStepper) => this.updateAP(values, fromStepper)}>
                {formApi => (
                    <form onSubmit={formApi.submitForm}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <HelpLabel
                                    inputLabel="Budget"
                                    title={true}
                                    showLabel={true}
                                    helpText={settings.budget_tab_help}
                                />
                                {/*ActionMenu*/}
                            </Grid>
                            <Grid item xs={12}>
                                {partnershipActive && grouped.size > 1 && (
                                    <Tooltip title="Delete all but one partner to remove this partnership">
                                        <span>
                                            <Checkbox
                                                field="is_partnership"
                                                align={classes.checkbox}
                                                disabled={true}
                                                label="Partnership"
                                            >
                                                <HelpLabel
                                                    inputLabel="Partnership"
                                                    helpText="A partnership budget should be included when different organizations are responsible for different activities AND those organizations will have a separate contract and submit separate reimbursements with the DNR that are not through the main manager of the program.  Please call to discuss if you think this applies to you."
                                                />
                                            </Checkbox>
                                        </span>
                                    </Tooltip>
                                )}
                                {(!partnershipActive || grouped.size <= 1) && (
                                    <Checkbox
                                        field="is_partnership"
                                        eventHandle={this.togglePartnership}
                                        disabled={is_read_only} 
                                        align={classes.checkbox}
                                        label="Partnership"
                                    >
                                        <HelpLabel
                                            inputLabel="Partnership"
                                            helpText="A partnership budget should be included when different organizations are responsible for different activities AND those organizations will have a separate contract and submit separate reimbursements with the DNR that are not through the main manager of the program.  Please call to discuss if you think this applies to you."
                                        />
                                    </Checkbox>
                                )}
                                {partnershipActive && (
                                    <Button
                                        variant="contained"
                                        type="button"
                                        color="primary"
                                        disabled={is_read_only} 
                                        onClick={this.addPartnership}>
                                        <AddCircle />&nbsp;&nbsp;&nbsp;Add Additional Partner
                                    </Button>
                                )}
                                {partnershipActive && pships.map(p => {
                                    const groupedBudgetItems = grouped.get(p);
                                    return (
                                        <ExpansionPanel
                                            square
                                            defaultExpanded={false}
                                            classes={{root: classes.summaryRoot}}
                                        >
                                            <ExpansionPanelSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                classes={{
                                                    root: classes.expansionSummaryRoot
                                                }}>
                                                <Typography variant="h3" style={{width:"100%"}}>
                                                    {p}
                                                    <Tooltip title="Rename Partnership" placement="top">
                                                        <EditIcon disabled={is_read_only} onClick={(e) => {e.stopPropagation();this.editPartnership(p)}} className={classes.editIcon}/>
                                                    </Tooltip>
                                                    {grouped.size > 1 && (
                                                        <Tooltip title="Delete Partnership" placement="top">
                                                            <DeleteIcon disabled={is_read_only} onClick={(e) => {e.stopPropagation();this.deletePartnership(p)}} className={classes.deleteIcon}/>
                                                        </Tooltip>
                                                    )}
                                                </Typography>
                                            </ExpansionPanelSummary>
                                            <ExpansionPanelDetails>
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <Typography variant="h3" gutterBottom>
                                                            Original AP Personnel - Breakout Details
                                                        </Typography>
                                                        <PersonnelTableOriginal
                                                            classes={classes} 
                                                            personnel_row={groupedBudgetItems.find(b => b.item === "Personnel")}
                                                        />
                                                        <Typography variant="h3" gutterBottom>
                                                            Personnel - Breakout Details
                                                            <HelpLabel
                                                                inputLabel="Directions for FTE / Salary calculations"
                                                                special={true}
                                                                helpText={
                                                                    <div>
                                                                        In order to consistently calculate the FTE and salary lines, please use the following guideline:<br/><br/>
                                                                        Annual salary for that position <b>divided by</b> percent of time position will spend on OHF work per calendar year times number 
                                                                        of years this position will work on the program = Amount anticipated to be reimbursed by OHF for this position in this program.<br/><br/>
                                                                        Example:   $80,000 (annual salary) / .33 (percent amount of time) = 24,242.42 x 4 (years) = $96,968
                                                                    </div>
                                                                }
                                                            />
                                                            <Button disabled={is_read_only} style={{marginLeft:16}} variant="contained" color="primary" onClick={() => ormFinalBudgetPersonnelCreate({budget: groupedBudgetItems.find(b => b.item === "Personnel").id })}>
                                                                <AddCircle />
                                                                &nbsp;&nbsp;&nbsp;Add Item
                                                            </Button>
                                                        </Typography>
                                                        <PersonnelTable 
                                                            classes={classes} 
                                                            budgets={groupedBudgetItems}
                                                            personnel_row={groupedBudgetItems.find(b => b.item === "Personnel")}
                                                            ormFinalBudgetPersonnelUpdate={ormFinalBudgetPersonnelUpdate}
                                                            ormFinalBudgetPersonnelUpdateLocalOnly={ormFinalBudgetPersonnelUpdateLocalOnly}
                                                            ormFinalBudgetPersonnelDelete={ormFinalBudgetPersonnelDelete}
                                                            ormFinalBudgetUpdate={ormFinalBudgetUpdate}
                                                            is_read_only={is_read_only}
                                                            ormFinalBudgetUpdateLocalOnly={ormFinalBudgetUpdateLocalOnly}
                                                        />
                                                        <Typography variant="h3" gutterBottom>
                                                            Original AP Capital Equipment - Breakout Details (for items over $10,000)
                                                        </Typography>
                                                        <CapitalTableOriginal
                                                            classes={classes} 
                                                            capital_row={groupedBudgetItems.find(b => b.item === "Capital Equipment")}
                                                        />
                                                        <Typography variant="h3" gutterBottom>
                                                            Capital Equipment - Breakout Details (for items over $10,000)
                                                            <Button disabled={is_read_only} style={{marginLeft:16}} variant="contained" color="primary" onClick={() => ormFinalBudgetCapitalEquipmentCreate({budget: groupedBudgetItems.find(b => b.item === "Capital Equipment").id })}>
                                                                <AddCircle />
                                                                &nbsp;&nbsp;&nbsp;Add Item
                                                            </Button>
                                                        </Typography>
                                                        <CapitalTable 
                                                            classes={classes} 
                                                            budgets={groupedBudgetItems}
                                                            capital_row={groupedBudgetItems.find(b => b.item === "Capital Equipment")}
                                                            ormFinalBudgetCapitalEquipmentUpdate={ormFinalBudgetCapitalEquipmentUpdate}
                                                            ormFinalBudgetCapitalEquipmentUpdateLocalOnly={ormFinalBudgetCapitalEquipmentUpdateLocalOnly}
                                                            ormFinalBudgetCapitalEquipmentDelete={ormFinalBudgetCapitalEquipmentDelete}
                                                            ormFinalBudgetUpdate={ormFinalBudgetUpdate}
                                                            is_read_only={is_read_only}
                                                            ormFinalBudgetUpdateLocalOnly={ormFinalBudgetUpdateLocalOnly}
                                                        />
                                                        <Typography variant="h3" gutterBottom>
                                                            Main Budget Table
                                                        </Typography>
                                                        <Form
                                                            key={this.parentFormKey}
                                                            dontValidateOnMount={true}
                                                            validateOnSubmit={true}
                                                            defaultValues={final_report.formData}>
                                                            {formApi => (
                                                                <form onSubmit={formApi.submitForm}>
                                                                    <div style={{float:"right",marginTop:-20}}>
                                                                        <TextField
                                                                            disabled={true}
                                                                            field="funds_recommended" useTextFormat
                                                                            className={classes.widthSixty}
                                                                        >
                                                                            <i style={{textAlign:'center',display:"block"}}>Appropriated Amount</i>
                                                                        </TextField>
                                                                        <span style={{marginLeft:5,marginRight:5,marginTop:45,display:"inline-block"}}>-</span>
                                                                        <TextField
                                                                            disabled={true}
                                                                            field="_t1" useTextFormat
                                                                            className={classes.widthSixty}
                                                                        >
                                                                            <i style={{textAlign:'center',display:"block"}}>Amount Spent</i>
                                                                        </TextField>
                                                                        <span style={{marginLeft:5,marginRight:5,marginTop:45,display:"inline-block"}}>=</span>
                                                                        <TextField
                                                                            disabled={true}
                                                                            field="_t4" useTextFormat
                                                                            color={final_report.formData._t4 === 0 ? "green" : final_report.formData._t4 > 0 ? "yellow" : "red"}
                                                                            className={classes.widthSixty}
                                                                        >
                                                                            <i style={{textAlign:'center',display:"block"}}>Balance Left Unspent</i>
                                                                        </TextField>
                                                                    </div>
                                                                </form>
                                                            )}
                                                        </Form>
                                                        <BudgetTable
                                                            ormFinalBudgetUpdate={ormFinalBudgetUpdate}
                                                            ormFinalBudgetUpdateLocalOnly={ormFinalBudgetUpdateLocalOnly}
                                                            rows={groupedBudgetItems}
                                                            is_read_only={is_read_only}
                                                            classes={classes}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                    )
                                })}
                                {!partnershipActive && (
                                    <>
                                    <Typography variant="h3" gutterBottom>
                                        Original AP Personnel - Breakout Details
                                    </Typography>
                                    <PersonnelTableOriginal
                                        classes={classes} 
                                        personnel_row={budgets.find(b => b.item === "Personnel")}
                                    />
                                    <Typography variant="h3" gutterBottom>
                                        Personnel - Breakout Details
                                        <HelpLabel
                                            inputLabel="Directions for FTE / Salary calculations"
                                            special={true}
                                            helpText={
                                                <div>
                                                    In order to consistently calculate the FTE and salary lines, please use the following guideline:<br/><br/>
                                                    Annual salary for that position <b>divided by</b> percent of time position will spend on OHF work per calendar year times number 
                                                    of years this position will work on the program = Amount anticipated to be reimbursed by OHF for this position in this program.<br/><br/>
                                                    Example:   $80,000 (annual salary) / .33 (percent amount of time) = 24,242.42 x 4 (years) = $96,968
                                                </div>
                                            }
                                        />
                                        <Button disabled={is_read_only} style={{marginLeft:16}} variant="contained" color="primary" onClick={() => ormFinalBudgetPersonnelCreate({budget: budgets.find(b => b.item === "Personnel").id })}>
                                            <AddCircle />
                                            &nbsp;&nbsp;&nbsp;Add Item
                                        </Button>
                                    </Typography>
                                    <PersonnelTable 
                                        classes={classes} 
                                        budgets={budgets}
                                        personnel_row={budgets.find(b => b.item === "Personnel")}
                                        ormFinalBudgetPersonnelUpdate={ormFinalBudgetPersonnelUpdate}
                                        ormFinalBudgetPersonnelUpdateLocalOnly={ormFinalBudgetPersonnelUpdateLocalOnly}
                                        ormFinalBudgetPersonnelDelete={ormFinalBudgetPersonnelDelete}
                                        ormFinalBudgetUpdate={ormFinalBudgetUpdate}
                                        is_read_only={is_read_only}
                                        ormFinalBudgetUpdateLocalOnly={ormFinalBudgetUpdateLocalOnly}
                                    />
                                    <Typography variant="h3" gutterBottom>
                                        Original AP Capital Equipment - Breakout Details (for items over $10,000)
                                    </Typography>
                                    <CapitalTableOriginal
                                        classes={classes}
                                        capital_row={budgets.find(b => b.item === "Capital Equipment")}
                                    />
                                    <Typography variant="h3" gutterBottom>
                                        Capital Equipment - Breakout Details (for items over $10,000)
                                        <Button disabled={is_read_only} style={{marginLeft:16}} variant="contained" color="primary" onClick={() => ormFinalBudgetCapitalEquipmentCreate({budget: budgets.find(b => b.item === "Capital Equipment").id })}>
                                            <AddCircle />
                                            &nbsp;&nbsp;&nbsp;Add Item
                                        </Button>
                                    </Typography>
                                    <CapitalTable 
                                        classes={classes} 
                                        budgets={budgets}
                                        capital_row={budgets.find(b => b.item === "Capital Equipment")}
                                        ormFinalBudgetCapitalEquipmentUpdate={ormFinalBudgetCapitalEquipmentUpdate}
                                        ormFinalBudgetCapitalEquipmentUpdateLocalOnly={ormFinalBudgetCapitalEquipmentUpdateLocalOnly}
                                        ormFinalBudgetCapitalEquipmentDelete={ormFinalBudgetCapitalEquipmentDelete}
                                        ormFinalBudgetUpdate={ormFinalBudgetUpdate}
                                        is_read_only={is_read_only}
                                        ormFinalBudgetUpdateLocalOnly={ormFinalBudgetUpdateLocalOnly}
                                    />
                                    <Typography variant="h3" gutterBottom>
                                        Main Budget Table
                                    </Typography>
                                    <Form
                                        key={this.parentFormKey}
                                        dontValidateOnMount={true}
                                        validateOnSubmit={true}
                                        defaultValues={final_report.formData}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <div style={{float:"right",marginTop:-20}}>
                                                    <TextField
                                                        disabled={true}
                                                        field="funds_recommended" useTextFormat
                                                        className={classes.widthSixty}
                                                    >
                                                        <i style={{textAlign:'center',display:"block"}}>Appropriated Amount</i>
                                                    </TextField>
                                                    <span style={{marginLeft:5,marginRight:5,marginTop:45,display:"inline-block"}}>-</span>
                                                    <TextField
                                                        disabled={true}
                                                        field="_t1" useTextFormat
                                                        className={classes.widthSixty}
                                                    >
                                                        <i style={{textAlign:'center',display:"block"}}>Amount Spent</i>
                                                    </TextField>
                                                    <span style={{marginLeft:5,marginRight:5,marginTop:45,display:"inline-block"}}>=</span>
                                                    <TextField
                                                        disabled={true}
                                                        field="_t4" useTextFormat
                                                        color={final_report.formData._t4 === 0 ? "green" : final_report.formData._t4 > 0 ? "yellow" : "red"}
                                                        className={classes.widthSixty}
                                                    >
                                                        <i style={{textAlign:'center',display:"block"}}>Balance Left Unspent</i>
                                                    </TextField>
                                                </div>
                                            </form>
                                        )}
                                    </Form>
                                    <BudgetTable
                                        ormFinalBudgetUpdate={ormFinalBudgetUpdate}
                                        ormFinalBudgetUpdateLocalOnly={ormFinalBudgetUpdateLocalOnly}
                                        rows={budgets}
                                        is_read_only={is_read_only}
                                        classes={classes}
                                    />
                                    </>
                                )}
                                {partnershipActive && grouped.size > 1 && (
                                    <ExpansionPanel
                                        square
                                        defaultExpanded={true}
                                        classes={{root: classes.summaryRoot}}
                                    >
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            classes={{
                                                root: classes.expansionSummaryRoot
                                            }}>
                                            <Typography variant="h3" style={{width:"100%"}}>Grand Totals Across All Partners</Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <BudgetTableTotal rows={budgets} classes={classes} />
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                )}
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <OtherFields classes={classes} is_read_only={is_read_only} groupedItem={groupedItem} final_report={final_report} ormFinalReportUpdateLocalOnly={ormFinalReportUpdateLocalOnly} />
                            <Grid item xs={12} className={classes.rightAlign}>
                                <Button
                                    variant="contained"
                                    disabled={is_read_only}
                                    type="submit"
                                    onClick={() => this.setState({ draftClick: true, submitClicked: true })}
                                    className={classes.button}>
                                    Save Draft and Return to Dashboard
                                </Button>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={is_read_only}
                                    color="primary"
                                    onClick={() => this.setState({ draftClick: false, submitClicked: true })}>
                                    Save and Proceed to Output Tables <ChevronRight />
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Form>
            <RenamePartnership 
                ormFinalBudgetUpdate={ormFinalBudgetUpdate} 
                budgets={grouped.get(renaming)} 
                classes={classes} 
                returnDialog={this.returnDialog} 
                open={renameOpen} 
                name={renaming} 
            />
            <WarningDialog
                confirmAction={() => this.doDeletePartnership(renaming)}
                cancelAction={() => this.setState({ deleteOpen: false })}
                open={deleteOpen}
                title="Delete Partner"
                confirmText="Delete Partner"
                text={"Are you sure you wish to delete " + renaming + "? Any budget values entered for this partner will be lost."}
            />
            </>
        );
    }
}

class PersonnelTable extends Component {
    columnData = [
        { id: "position", label: "Position", allowSort: false },
        { id: "fte", label: "Annual FTE Equivalent", allowSort: false },
        { id: "years_working", label: "Years Working on Program", allowSort: false },
        { id: "funding_request", label: "Amount Spent", allowSort: false },
        { id: "anticipated_leverage", label: "Leverage", allowSort: false },
        { id: "leverage_source", label: "Leverage Source", allowSort: false },
        { id: "total", label: "Total", allowSort: false },
        { id: "actions", numeric: false, label: "Actions", allowSort: false, width: "20px" }
    ];
    typingTimer = [];
    typingTimer2 = [];
    objectsToUpdate = [];
    formkeys = {};

    updateRecord = (value, element, id, record) => {
        const { 
            ormFinalBudgetPersonnelUpdate, ormFinalBudgetPersonnelUpdateLocalOnly,
            personnel_row, ormFinalBudgetUpdateLocalOnly, ormFinalBudgetUpdate, budgets 
        } = this.props;
        const comp = this;

        // Save each individual value locally
        clearTimeout(this.typingTimer2[id + element]);
        this.typingTimer2[id + element] = setTimeout(function() {

            if (element === "funding_request" || element === "anticipated_leverage")
                value = (Math.round(parseInt(value) / 100) * 100) || 0;
            else if (!value && (element === "fte" || element === "years_working"))
                value = 0;

            var vals = {
                id: id,
                [element]: value
            }
            if (element === "funding_request") {
                if (comp.objectsToUpdate[id] && comp.objectsToUpdate[id]["anticipated_leverage"])
                    vals["total"] = value + comp.objectsToUpdate[id]["anticipated_leverage"];
                else
                    vals["total"] = value + record.anticipated_leverage;
            }
            else if (element === "anticipated_leverage") {
                if (comp.objectsToUpdate[id] && comp.objectsToUpdate[id]["funding_request"])
                    vals["total"] = value + comp.objectsToUpdate[id]["funding_request"];
                else
                    vals["total"] = value + record.funding_request;
            } else {
                vals["total"] = record.funding_request + record.anticipated_leverage;
            }
            ormFinalBudgetPersonnelUpdateLocalOnly(vals);
            comp.objectsToUpdate[id] = { ...comp.objectsToUpdate[id], ...vals };

            if (element !== "funding_request" && element !== "anticipated_leverage" && element !== "leverage_source")
                return;

            var totals = {
                id: personnel_row.id,
                funding_request_final: 0,
                anticipated_leverage_final: 0,
                total_final: 0,
                leverage_source: null
            };
            var leverage_array = [];
            personnel_row._personnel.forEach(function(p) {
                if (p.id === id) {
                    if (element === "funding_request") {
                        totals["funding_request_final"] += value;
                        if (comp.objectsToUpdate[id] && comp.objectsToUpdate[id]["anticipated_leverage"])
                            totals["anticipated_leverage_final"] += comp.objectsToUpdate[id]["anticipated_leverage"];
                        else
                            totals["anticipated_leverage_final"] += (parseInt(p.anticipated_leverage) || 0);
                    }
                    else if (element === "anticipated_leverage") {
                        if (comp.objectsToUpdate[id] && comp.objectsToUpdate[id]["funding_request"])
                            totals["funding_request_final"] += comp.objectsToUpdate[id]["funding_request"];
                        else
                            totals["funding_request_final"] += (parseInt(p.funding_request) || 0);
                        totals["anticipated_leverage_final"] += value;
                    } else {
                        if (comp.objectsToUpdate[id] && comp.objectsToUpdate[id]["funding_request"])
                            totals["funding_request_final"] += comp.objectsToUpdate[id]["funding_request"];
                        else
                            totals["funding_request_final"] += (parseInt(p.funding_request) || 0);
                        if (comp.objectsToUpdate[id] && comp.objectsToUpdate[id]["anticipated_leverage"])
                            totals["anticipated_leverage_final"] += comp.objectsToUpdate[id]["anticipated_leverage"];
                        else
                            totals["anticipated_leverage_final"] += (parseInt(p.anticipated_leverage) || 0);

                    }
                    totals["total_final"] += vals["total"];
                    if (comp.objectsToUpdate[id] && comp.objectsToUpdate[id]["leverage_source"])
                        leverage_array.push(comp.objectsToUpdate[id]["leverage_source"]);
                    else if (element !== "leverage_source")
                        leverage_array.push(p.leverage_source);
                } else {
                    totals["funding_request_final"] += (parseInt(p.funding_request) || 0);
                    totals["anticipated_leverage_final"] += (parseInt(p.anticipated_leverage) || 0);
                    totals["total_final"] += (parseInt(p.total) || 0);
                    leverage_array.push(p.leverage_source);
                }
            });      
            var uniq = [...new Set(leverage_array)];
            totals["leverage_source"] = uniq.filter(n => n).join(", ");      
            
            var grand_totals = {
                id: budgets.find(b => b.item === "Grand Total").id,
                funding_request_final: totals["funding_request_final"],
                anticipated_leverage_final: totals["anticipated_leverage_final"],
                total_final: totals["total_final"]
            };
            budgets.forEach(function(b) {
                if (b.item !== "Personnel" && b.item !== "Grand Total") {
                    grand_totals["funding_request_final"] += b.funding_request_final;
                    grand_totals["anticipated_leverage_final"] += b.anticipated_leverage_final;
                    grand_totals["total_final"] += b.total_final;
                }
            });

            ormFinalBudgetUpdateLocalOnly(totals);
            ormFinalBudgetUpdateLocalOnly(grand_totals);

            // Later properties overwrite earlier properties with the same name.
            comp.objectsToUpdate["parent"] = { ...comp.objectsToUpdate["parent"], ...totals };
            comp.objectsToUpdate["grand_parent"] = { ...comp.objectsToUpdate["grand_parent"], ...grand_totals };
        }, 50);

        // Save the row once finished editing
        clearTimeout(this.typingTimer[id]);
        this.typingTimer[id] = setTimeout(function() {
            ormFinalBudgetPersonnelUpdate(comp.objectsToUpdate[id]);
        }, 2500);

        // Parent Total
        clearTimeout(this.typingTimer["parent"]);
        this.typingTimer["parent"] = setTimeout(function() {
            if (comp.objectsToUpdate["parent"]) {
                ormFinalBudgetUpdate(comp.objectsToUpdate["parent"]);
                ormFinalBudgetUpdate(comp.objectsToUpdate["grand_parent"]);
            }
        }, 2500);
    };

    recalculateTotal = (deleteId) => {
        const { ormFinalBudgetUpdate, budgets, personnel_row, ormFinalBudgetPersonnelDelete } = this.props;

        var totals = {
            id: personnel_row.id,
            funding_request_final: 0,
            anticipated_leverage_final: 0,
            total_final: 0,
            leverage_source: null
        };
        var leverage_array = [];

        personnel_row._personnel.forEach(function(p) {
            if (p.id !== deleteId) {
                totals["funding_request_final"] += (parseInt(p.funding_request) || 0);
                totals["anticipated_leverage_final"] += (parseInt(p.anticipated_leverage) || 0);
                totals["total_final"] += (parseInt(p.total) || 0);
                leverage_array.push(p.leverage_source);
            }
        });   
        var uniq = [...new Set(leverage_array)];
        totals["leverage_source"] = uniq.filter(n => n).join(", ");         
        
        var grand_totals = {
            id: budgets.find(b => b.item === "Grand Total").id,
            funding_request_final: totals["funding_request_final"],
            anticipated_leverage_final: totals["anticipated_leverage_final"],
            total_final: totals["total_final"]
        };
        budgets.forEach(function(b) {
            if (b.item !== "Personnel" && b.item !== "Grand Total") {
                grand_totals["funding_request_final"] += b.funding_request_final;
                grand_totals["anticipated_leverage_final"] += b.anticipated_leverage_final;
                grand_totals["total_final"] += b.total_final;
            }
        });

        ormFinalBudgetPersonnelDelete(deleteId);
        ormFinalBudgetUpdate(totals);
        ormFinalBudgetUpdate(grand_totals);
    }

    render() {
        const { classes, personnel_row, is_read_only } = this.props;

        return (
            <Table className={classes.table}>
                <EnhancedTableHead columnData={this.columnData} />
                <TableBody>
                    {personnel_row && personnel_row._personnel.map(n => {
                        return (
                            <TableRow hover key={n.id}>
                                <CustomTableCell>
                                    <Form key={n.id + "position"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={is_read_only} tableFormat eventHandleProps1={n.id} eventHandleProps2={n} eventHandle={this.updateRecord} field="position" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form key={n.id + "fte"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={is_read_only} tableFormat useAcres eventHandleProps1={n.id} eventHandleProps2={n} eventHandle={this.updateRecord} field="fte" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form key={n.id + "years_working"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={is_read_only} tableFormat useAcres eventHandleProps1={n.id} eventHandleProps2={n} eventHandle={this.updateRecord} field="years_working" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form key={this.formkeys[n.id + "funding_request"] ? this.formkeys[n.id + "funding_request"]  : n.id + "funding_request"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={is_read_only} onBlur={this.updateForm}
                                                    eventHandleProps1={n.id} eventHandleProps2={n}
                                                    tableFormat useTextFormat eventHandle={this.updateRecord} field="funding_request" label="" fullWidth 
                                                />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form key={this.formkeys[n.id + "anticipated_leverage"] ? this.formkeys[n.id + "anticipated_leverage"]  : n.id + "anticipated_leverage"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={is_read_only} onBlur={this.updateForm}
                                                    eventHandleProps1={n.id} eventHandleProps2={n}
                                                    tableFormat useTextFormat eventHandle={this.updateRecord} field="anticipated_leverage" label="" fullWidth 
                                                />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form key={n.id + "leverage_source"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={is_read_only} tableFormat eventHandleProps1={n.id} eventHandle={this.updateRecord} eventHandleProps2={n} field="leverage_source" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    {numberRender(n.total)}
                                </CustomTableCell>
                                <CustomTableCell className={classes.nowrap}>
                                    <Tooltip title="Delete Record">
                                        <Button
                                            color="primary"
                                            disabled={is_read_only}
                                            className={classes.deleteWidth}
                                            onClick={() => this.recalculateTotal(n.id)}>
                                            <DeleteIcon color="primary" />
                                        </Button>
                                    </Tooltip>
                                </CustomTableCell>
                            </TableRow>
                        );
                    })}
                    {(!personnel_row || personnel_row._personnel < 1) && (
                        <TableRow className={classes.totalRow2}>
                            <CustomTableCell colSpan={this.columnData.length} className={classes.centerAlign}>
                                No Records Found
                            </CustomTableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        );
    }
}

class PersonnelTableOriginal extends Component {
    columnData = [
        { id: "position", label: "Position", allowSort: false },
        { id: "fte", label: "Annual FTE Equivalent", allowSort: false },
        { id: "years_working", label: "Years Working on Program", allowSort: false },
        { id: "funding_request", label: "Amount Spent", allowSort: false },
        { id: "anticipated_leverage", label: "Leverage", allowSort: false },
        { id: "leverage_source", label: "Leverage Source", allowSort: false },
        { id: "total", label: "Total", allowSort: false }
    ];

    render() {
        const { classes, personnel_row } = this.props;

        return (
            <Table className={classes.table}>
                <EnhancedTableHead columnData={this.columnData} />
                <TableBody>
                    {personnel_row && personnel_row._personnelOriginal.map(n => {
                        return (
                            <TableRow hover key={n.id}>
                                <CustomTableCell>
                                    <Form defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={true} tableFormat field="position" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={true} tableFormat useAcres field="fte" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={true} tableFormat useAcres field="years_working" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={true} tableFormat useTextFormat field="funding_request" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={true} tableFormat useTextFormat field="anticipated_leverage" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={true} tableFormat field="leverage_source" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    {numberRender(n.total)}
                                </CustomTableCell>
                            </TableRow>
                        );
                    })}
                    {(!personnel_row || personnel_row._personnelOriginal < 1) && (
                        <TableRow className={classes.totalRow2}>
                            <CustomTableCell colSpan={this.columnData.length} className={classes.centerAlign}>
                                No Records Found
                            </CustomTableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        );
    }
}

class CapitalTable extends Component {
    columnData = [
        { id: "position", label: "Item", allowSort: false },
        { id: "funding_request", label: "Amount Spent", allowSort: false },
        { id: "anticipated_leverage", label: "Leverage", allowSort: false },
        { id: "leverage_source", label: "Leverage Source", allowSort: false },
        { id: "total", label: "Total", allowSort: false },
        { id: "actions", numeric: false, label: "Actions", allowSort: false, width: "20px" }
    ];
    typingTimer = [];
    typingTimer2 = [];
    objectsToUpdate = [];
    formkeys = {};

    updateRecord = (value, element, id, record) => {
        const { 
            ormFinalBudgetCapitalEquipmentUpdate, ormFinalBudgetCapitalEquipmentUpdateLocalOnly, 
            capital_row, ormFinalBudgetUpdateLocalOnly, ormFinalBudgetUpdate, budgets } = this.props;
        const comp = this;

        // Save each individual value locally
        clearTimeout(this.typingTimer2[id + element]);
        this.typingTimer2[id + element] = setTimeout(function() {

            if (element === "funding_request" || element === "anticipated_leverage")
                value = (Math.round(parseInt(value) / 100) * 100) || 0;

            var vals = {
                id: id,
                [element]: value
            }
            if (element === "funding_request") {
                if (comp.objectsToUpdate[id] && comp.objectsToUpdate[id]["anticipated_leverage"])
                    vals["total"] = value + comp.objectsToUpdate[id]["anticipated_leverage"];
                else
                    vals["total"] = value + record.anticipated_leverage;
            }
            else if (element === "anticipated_leverage") {
                if (comp.objectsToUpdate[id] && comp.objectsToUpdate[id]["funding_request"])
                    vals["total"] = value + comp.objectsToUpdate[id]["funding_request"];
                else
                    vals["total"] = value + record.funding_request;
            } else {
                vals["total"] = record.funding_request + record.anticipated_leverage;
            }
            ormFinalBudgetCapitalEquipmentUpdateLocalOnly(vals);
            comp.objectsToUpdate[id] = { ...comp.objectsToUpdate[id], ...vals };

            if (element !== "funding_request" && element !== "anticipated_leverage" && element !== "leverage_source")
                return;
            
            var totals = {
                id: capital_row.id,
                funding_request_final: 0,
                anticipated_leverage_final: 0,
                total_final: 0,
                leverage_source: null
            };
            var leverage_array = [];
            capital_row._capital.forEach(function(p) {
                if (p.id === id) {
                    if (element === "funding_request") {
                        totals["funding_request_final"] += value;
                        if (comp.objectsToUpdate[id] && comp.objectsToUpdate[id]["anticipated_leverage"])
                            totals["anticipated_leverage_final"] += comp.objectsToUpdate[id]["anticipated_leverage"];
                        else
                            totals["anticipated_leverage_final"] += (parseInt(p.anticipated_leverage) || 0);
                    }
                    else if (element === "anticipated_leverage") {
                        if (comp.objectsToUpdate[id] && comp.objectsToUpdate[id]["funding_request"])
                            totals["funding_request_final"] += comp.objectsToUpdate[id]["funding_request"];
                        else
                            totals["funding_request_final"] += (parseInt(p.funding_request) || 0);
                        totals["anticipated_leverage_final"] += value;
                    } else {
                        if (comp.objectsToUpdate[id] && comp.objectsToUpdate[id]["funding_request"])
                            totals["funding_request_final"] += comp.objectsToUpdate[id]["funding_request"];
                        else
                            totals["funding_request_final"] += (parseInt(p.funding_request) || 0);
                        if (comp.objectsToUpdate[id] && comp.objectsToUpdate[id]["anticipated_leverage"])
                            totals["anticipated_leverage_final"] += comp.objectsToUpdate[id]["anticipated_leverage"];
                        else
                            totals["anticipated_leverage_final"] += (parseInt(p.anticipated_leverage) || 0);
                    }
                    totals["total_final"] += vals["total"];
                    if (comp.objectsToUpdate[id] && comp.objectsToUpdate[id]["leverage_source"])
                        leverage_array.push(comp.objectsToUpdate[id]["leverage_source"]);
                    else if (element !== "leverage_source")
                        leverage_array.push(p.leverage_source);
                } else {
                    totals["funding_request_final"] += (parseInt(p.funding_request) || 0);
                    totals["anticipated_leverage_final"] += (parseInt(p.anticipated_leverage) || 0);
                    totals["total_final"] += (parseInt(p.total) || 0);
                    leverage_array.push(p.leverage_source);
                }
            }); 
            var uniq = [...new Set(leverage_array)];
            totals["leverage_source"] = uniq.filter(n => n).join(", ");  
            
            var grand_totals = {
                id: budgets.find(b => b.item === "Grand Total").id,
                funding_request_final: totals["funding_request_final"],
                anticipated_leverage_final: totals["anticipated_leverage_final"],
                total_final: totals["total_final"]
            };
            budgets.forEach(function(b) {
                if (b.item !== "Capital Equipment" && b.item !== "Grand Total") {
                    grand_totals["funding_request_final"] += b.funding_request_final;
                    grand_totals["anticipated_leverage_final"] += b.anticipated_leverage_final;
                    grand_totals["total_final"] += b.total_final;
                }
            });

            ormFinalBudgetUpdateLocalOnly(totals);
            ormFinalBudgetUpdateLocalOnly(grand_totals);

            // Later properties overwrite earlier properties with the same name.
            comp.objectsToUpdate["parent"] = { ...comp.objectsToUpdate["parent"], ...totals };
            comp.objectsToUpdate["grand_parent"] = { ...comp.objectsToUpdate["grand_parent"], ...grand_totals };
        }, 50);

        // Save the row once finished editing
        clearTimeout(this.typingTimer[id]);
        this.typingTimer[id] = setTimeout(function() {
            ormFinalBudgetCapitalEquipmentUpdate(comp.objectsToUpdate[id]);
        }, 2500);

        // Parent Total
        clearTimeout(this.typingTimer["parent"]);
        this.typingTimer["parent"] = setTimeout(function() {
            if (comp.objectsToUpdate["parent"]) {
                ormFinalBudgetUpdate(comp.objectsToUpdate["parent"]);
                ormFinalBudgetUpdate(comp.objectsToUpdate["grand_parent"]);
            }
        }, 2500);
    };

    recalculateTotal = (deleteId) => {
        const { ormFinalBudgetUpdate, budgets, capital_row, ormFinalBudgetCapitalEquipmentDelete } = this.props;

        var totals = {
            id: capital_row.id,
            funding_request_final: 0,
            anticipated_leverage_final: 0,
            total_final: 0,
            leverage_source: null
        };
        var leverage_array = [];

        capital_row._capital.forEach(function(p) {
            if (p.id !== deleteId) {
                totals["funding_request_final"] += (parseInt(p.funding_request) || 0);
                totals["anticipated_leverage_final"] += (parseInt(p.anticipated_leverage) || 0);
                totals["total_final"] += (parseInt(p.total) || 0);
                leverage_array.push(p.leverage_source);
            }
        });            
        var uniq = [...new Set(leverage_array)];
        totals["leverage_source"] = uniq.filter(n => n).join(", ");  
        
        var grand_totals = {
            id: budgets.find(b => b.item === "Grand Total").id,
            funding_request_final: totals["funding_request_final"],
            anticipated_leverage_final: totals["anticipated_leverage_final"],
            total_final: totals["total_final"]
        };
        budgets.forEach(function(b) {
            if (b.item !== "Capital Equipment" && b.item !== "Grand Total") {
                grand_totals["funding_request_final"] += b.funding_request_final;
                grand_totals["anticipated_leverage_final"] += b.anticipated_leverage_final;
                grand_totals["total_final"] += b.total_final;
            }
        });

        ormFinalBudgetCapitalEquipmentDelete(deleteId);
        ormFinalBudgetUpdate(totals);
        ormFinalBudgetUpdate(grand_totals);
    };

    render() {
        const { classes, capital_row, is_read_only } = this.props;

        return (
            <Table className={classes.table}>
                <EnhancedTableHead columnData={this.columnData} />
                <TableBody>
                    {capital_row && capital_row._capital.map(n => {
                        return (
                            <TableRow hover key={n.id}>
                                <CustomTableCell>
                                    <Form key={n.id + "position"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={is_read_only} tableFormat eventHandleProps1={n.id} eventHandleProps2={n} eventHandle={this.updateRecord} field="position" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form key={this.formkeys[n.id + "funding_request"] ? this.formkeys[n.id + "funding_request"]  : n.id + "funding_request"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={is_read_only} onBlur={this.updateForm}
                                                    eventHandleProps1={n.id} eventHandleProps2={n}
                                                    tableFormat useTextFormat eventHandle={this.updateRecord} field="funding_request" label="" fullWidth 
                                                />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form key={this.formkeys[n.id + "anticipated_leverage"] ? this.formkeys[n.id + "anticipated_leverage"]  : n.id + "anticipated_leverage"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={is_read_only} onBlur={this.updateForm}
                                                    eventHandleProps1={n.id} eventHandleProps2={n}
                                                    tableFormat useTextFormat eventHandle={this.updateRecord} field="anticipated_leverage" label="" fullWidth 
                                                />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form key={n.id + "leverage_source"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={is_read_only} tableFormat eventHandleProps1={n.id} eventHandle={this.updateRecord} eventHandleProps2={n} field="leverage_source" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    {numberRender(n.total)}
                                </CustomTableCell>
                                <CustomTableCell className={classes.nowrap}>
                                    <Tooltip title="Delete Record">
                                        <Button
                                            color="primary"
                                            disabled={is_read_only}
                                            className={classes.deleteWidth}
                                            onClick={() => this.recalculateTotal(n.id)}>
                                            <DeleteIcon color="primary" />
                                        </Button>
                                    </Tooltip>
                                </CustomTableCell>
                            </TableRow>
                        );
                    })}
                    {(!capital_row || capital_row._capital < 1) && (
                        <TableRow className={classes.totalRow2}>
                            <CustomTableCell colSpan={this.columnData.length} className={classes.centerAlign}>
                                No Records Found
                            </CustomTableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        );
    }
}

class CapitalTableOriginal extends Component {
    columnData = [
        { id: "position", label: "Item", allowSort: false },
        { id: "funding_request", label: "Amount Spent", allowSort: false },
        { id: "anticipated_leverage", label: "Leverage", allowSort: false },
        { id: "leverage_source", label: "Leverage Source", allowSort: false },
        { id: "total", label: "Total", allowSort: false }
    ];   

    render() {
        const { classes, capital_row } = this.props;

        return (
            <Table className={classes.table}>
                <EnhancedTableHead columnData={this.columnData} />
                <TableBody>
                    {capital_row && capital_row._capitalOriginal.map(n => {
                        return (
                            <TableRow hover key={n.id}>
                                <CustomTableCell>
                                    <Form defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={true} tableFormat field="position" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={true} tableFormat useTextFormat field="funding_request" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={true} tableFormat useTextFormat field="anticipated_leverage" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={true} tableFormat field="leverage_source" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    {numberRender(n.total)}
                                </CustomTableCell>
                            </TableRow>
                        );
                    })}
                    {(!capital_row || capital_row._capitalOriginal < 1) && (
                        <TableRow className={classes.totalRow2}>
                            <CustomTableCell colSpan={this.columnData.length} className={classes.centerAlign}>
                                No Records Found
                            </CustomTableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        );
    }
}

class BudgetTable extends Component {
    columnData = [
        { id: "item", label: "Item", allowSort: false },
        { id: "funding_request", label: "Original Funding Request", allowSort: false },
        { id: "funding_request_final", label: "Amount Spent", allowSort: false },
        { id: "anticipated_leverage", label: "Leverage", allowSort: false },
        { id: "anticipated_leverage_final", label: "Amount Leveraged", allowSort: false },
        { id: "leverage_source", label: "Leverage Source", allowSort: false },
        { id: "total", label: "Total Requested", type: "numeric", allowSort: false },
        { id: "total_final", label: "Total Spent", type: "numeric", allowSort: false },
    ];
    typingTimer = [];
    typingTimer2 = [];
    objectsToUpdate = [];
    formkeys = {};

    updateRecord = (value, element, id, record) => {
        const { ormFinalBudgetUpdate, ormFinalBudgetUpdateLocalOnly, rows } = this.props;
        const comp = this;
        var row = rows.find(r => r.item === "Grand Total");

        // Save each individual value locally
        clearTimeout(this.typingTimer2[id + element]);
        this.typingTimer2[id + element] = setTimeout(function() {

            if (element === "funding_request_final" || element === "anticipated_leverage_final")
                value = (Math.round(parseInt(value) / 100) * 100) || 0;

            var vals = {
                id: id,
                [element]: value
            }
            if (element === "funding_request_final") {
                if (comp.objectsToUpdate[id] && comp.objectsToUpdate[id]["anticipated_leverage_final"])
                    vals["total_final"] = value + comp.objectsToUpdate[id]["anticipated_leverage_final"];
                else
                    vals["total_final"] = value + record.anticipated_leverage_final;
            }
            else if (element === "anticipated_leverage_final") {
                if (comp.objectsToUpdate[id] && comp.objectsToUpdate[id]["funding_request_final"])
                    vals["total_final"] = value + comp.objectsToUpdate[id]["funding_request_final"];
                else
                    vals["total_final"] = value + record.funding_request_final;
            }
            ormFinalBudgetUpdateLocalOnly(vals);
            comp.objectsToUpdate[id] = { ...comp.objectsToUpdate[id], ...vals };

            if (element === "leverage_source")
                return;

            var totals = {
                id: row.id,
                funding_request_final: 0,
                anticipated_leverage_final: 0,
                total_final: 0
            };
            rows.forEach(function(p) {
                if (p.id !== row.id) {
                    if (p.id === id) {
                        if (element === "funding_request_final") {
                            totals["funding_request_final"] += value;
                            if (comp.objectsToUpdate[id] && comp.objectsToUpdate[id]["anticipated_leverage_final"])
                                totals["anticipated_leverage_final"] += comp.objectsToUpdate[id]["anticipated_leverage_final"];
                            else
                                totals["anticipated_leverage_final"] += (parseInt(p.anticipated_leverage_final) || 0);
                        }
                        else if (element === "anticipated_leverage_final") {
                            if (comp.objectsToUpdate[id] && comp.objectsToUpdate[id]["funding_request_final"])
                                totals["funding_request_final"] += comp.objectsToUpdate[id]["funding_request_final"];
                            else
                                totals["funding_request_final"] += (parseInt(p.funding_request_final) || 0);
                            totals["anticipated_leverage_final"] += value;
                        }
                        totals["total_final"] += vals["total_final"];
                    } else {
                        totals["funding_request_final"] += (parseInt(p.funding_request_final) || 0);
                        totals["anticipated_leverage_final"] += (parseInt(p.anticipated_leverage_final) || 0);
                        totals["total_final"] += (parseInt(p.total_final) || 0);
                    }
                }
            });

            ormFinalBudgetUpdateLocalOnly(totals);

            // Later properties overwrite earlier properties with the same name.
            comp.objectsToUpdate[row.id] = { ...comp.objectsToUpdate[row.id], ...totals };
        }, 50);

        // Save the row once finished editing
        clearTimeout(this.typingTimer[id]);
        this.typingTimer[id] = setTimeout(function() {
            ormFinalBudgetUpdate(comp.objectsToUpdate[id]);
        }, 2500);

        // Grand Total
        clearTimeout(this.typingTimer[row.id]);
        this.typingTimer[row.id] = setTimeout(function() {
            ormFinalBudgetUpdate(comp.objectsToUpdate[row.id]);
        }, 2500);
    };

    render() {
        const { rows, classes, is_read_only } = this.props;
        
        return (
            <Table className={classes.table}>
                <EnhancedTableHead columnData={this.columnData} />
                <TableBody>
                    {rows.map(n => {
                        if (n.item === "Grand Total") {
                            return (
                                <TableRow className={classes.totalRow}>
                                    <CustomTableCell><b>Grand Total</b></CustomTableCell>
                                    <CustomTableCell><b>{numberRender(n.funding_request)}</b></CustomTableCell>
                                    <CustomTableCell><b>{numberRender(n.funding_request_final)}</b></CustomTableCell>
                                    <CustomTableCell><b>{numberRender(n.anticipated_leverage)}</b></CustomTableCell>
                                    <CustomTableCell><b>{numberRender(n.anticipated_leverage_final)}</b></CustomTableCell>
                                    <CustomTableCell></CustomTableCell>
                                    <CustomTableCell><b>{numberRender(n.total)}</b></CustomTableCell>
                                    <CustomTableCell><b>{numberRender(n.total_final)}</b></CustomTableCell>
                                </TableRow>
                            )
                        }
                        if (n.item === "Personnel" || n.item === "Capital Equipment") {
                            return (
                                <TableRow className={classes.totalRow2}>
                                    <CustomTableCell>{n.item}</CustomTableCell>
                                    <CustomTableCell>{numberRender(n.funding_request)}</CustomTableCell>
                                    <CustomTableCell>{numberRender(n.funding_request_final)}</CustomTableCell>
                                    <CustomTableCell>{numberRender(n.anticipated_leverage)}</CustomTableCell>
                                    <CustomTableCell>{numberRender(n.anticipated_leverage_final)}</CustomTableCell>
                                    <CustomTableCell>{n.leverage_source}</CustomTableCell>
                                    <CustomTableCell>{numberRender(n.total)}</CustomTableCell>
                                    <CustomTableCell>{numberRender(n.total_final)}</CustomTableCell>
                                </TableRow>
                            )
                        }
                        return (
                            <TableRow hover key={n.id}>
                                <CustomTableCell>
                                    {n.item}
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={true} tableFormat useTextFormat field="funding_request" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form key={this.formkeys[n.id + "funding_request_final"] ? this.formkeys[n.id + "funding_request_final"]  : n.id + "funding_request_final"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={is_read_only} onBlur={this.updateForm}
                                                    eventHandleProps1={n.id} eventHandleProps2={n}
                                                    tableFormat useTextFormat eventHandle={this.updateRecord} field="funding_request_final" label="" fullWidth 
                                                />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={true} tableFormat useTextFormat field="anticipated_leverage" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form key={this.formkeys[n.id + "anticipated_leverage_final"] ? this.formkeys[n.id + "anticipated_leverage_final"]  : n.id + "anticipated_leverage_final"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={is_read_only} onBlur={this.updateForm}
                                                    eventHandleProps1={n.id} eventHandleProps2={n}
                                                    tableFormat useTextFormat eventHandle={this.updateRecord} field="anticipated_leverage_final" label="" fullWidth 
                                                />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form key={n.id + "leverage_source"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={is_read_only} tableFormat eventHandleProps1={n.id} eventHandle={this.updateRecord} field="leverage_source" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    {numberRender(n.total)}
                                </CustomTableCell>
                                <CustomTableCell>
                                    {numberRender(n.total_final)}
                                </CustomTableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        );
    }
}

class BudgetTableTotal extends Component {
    columnData = [
        { id: "item", label: "Item", allowSort: false },
        { id: "funding_request", label: "Original Funding Request", allowSort: false },
        { id: "funding_request_final", label: "Amount Spent", allowSort: false },
        { id: "anticipated_leverage", label: "Leverage", allowSort: false },
        { id: "anticipated_leverage_final", label: "Amount Leveraged", allowSort: false },
        { id: "leverage_source", label: "Leverage Source", allowSort: false },
        { id: "total", label: "Total Requested", allowSort: false },
        { id: "total_final", label: "Total Spent", allowSort: false },
    ];
    
    shouldComponentUpdate(nextProps) {
        return nextProps.rows !== this.props.rows;
    };

    render() {
        const { classes, rows } = this.props;

        var groupedRows = [];
        budget_items.forEach(function(b) {
            groupedRows.push({item: b, funding_request: 0, anticipated_leverage: 0, leverage_source: null, total: 0 , funding_request_final: 0, anticipated_leverage_final: 0, total_final: 0});
        });
        rows.forEach(function(r, i) {
            var total = groupedRows.find(gr => gr.item === r.item); 
            total["funding_request"] += r["funding_request"]; 
            total["anticipated_leverage"] += r["anticipated_leverage"]; 
            total["total"] += r["total"];
            total["funding_request_final"] += r["funding_request_final"]; 
            total["anticipated_leverage_final"] += r["anticipated_leverage_final"]; 
            total["total_final"] += r["total_final"];
        });

        return (
            <Table className={classes.table}>
                <EnhancedTableHead columnData={this.columnData} />
                <TableBody>
                    {groupedRows.map(n => {
                        return (
                            <TableRow className={classes.totalRow2}>
                                <CustomTableCell> {n.item} </CustomTableCell>
                                <CustomTableCell> {numberRender(n.funding_request)} </CustomTableCell>
                                <CustomTableCell> {numberRender(n.funding_request_final)} </CustomTableCell>
                                <CustomTableCell> {numberRender(n.anticipated_leverage)} </CustomTableCell>
                                <CustomTableCell> {numberRender(n.anticipated_leverage_final)} </CustomTableCell>
                                <CustomTableCell></CustomTableCell>
                                <CustomTableCell> {numberRender(n.total)} </CustomTableCell>
                                <CustomTableCell> {numberRender(n.total_final)} </CustomTableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        );
    }
}

class RenamePartnership extends Component {
    state = {
        loading: false
    };
    rename = (values) => {
        const { budgets, ormFinalBudgetUpdate, returnDialog } = this.props;
        this.setState({loading: true}, function() {
            budgets.forEach(function(b) {
                ormFinalBudgetUpdate({
                    id: b.id,
                    partnership: values.name
                });
            });
            returnDialog();
            this.setState({loading: false});
        });
    }

    errorValidator = values => {
        const isRequired = val => {
            return !val ? "Required" : null;
        };
        var valObj = {
            name: isRequired(values.name)
        };

        return valObj;
    };

    render() {
        const { classes, open, returnDialog, name } = this.props;
        const { loading } = this.state;
        return (
            <Dialog open={open} classes={{ paper: classes.minWidth }}>
                <Toolbar>
                    <Typography variant="h3" className={classes.flex}>
                        Rename Partner
                    </Typography>
                    <IconButton aria-label="Close Dialog" onClick={() => returnDialog()}>
                        <Close />
                    </IconButton>
                </Toolbar>
                <DialogContent className={classes.dialogMin}>
                    <DialogContentText>
                        <Form
                            dontValidateOnMount={true}
                            validateOnSubmit={true}
                            validateError={this.errorValidator}
                            defaultValues={{name: name}}
                            onSubmit={values => this.rename(values)}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={0}>
                                        <Grid item xs={12}>
                                            <TextField
                                                field="name"
                                                label="Partner Name"
                                                autoFocus
                                                fullWidth
                                            />
                                            <br/><br/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {loading && (
                                                <CircularProgress size={24} />
                                            )}
                                            {!loading && (
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary">
                                                    Save
                                                </Button>
                                            )}
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}

class OtherFields extends Component {
    budgetKey = window.performance.now();
    updateDisclose = (value, element) => {
        const { ormFinalReportUpdateLocalOnly, final_report } = this.props;
        var temp = 0;
        if (element === "disclose_total_revenue") {
            temp = parseInt(value) - parseInt(final_report.disclose_revenue_spent);
            ormFinalReportUpdateLocalOnly({
                id: final_report.id,
                disclose_total_revenue: value,
                disclose_revenue_balance: temp.toString()
            });
        } else {
            temp = parseInt(final_report.disclose_total_revenue) - parseInt(value);
            ormFinalReportUpdateLocalOnly({
                id: final_report.id,
                disclose_revenue_spent: value,
                disclose_revenue_balance: temp.toString()
            });
        }
        this.budgetKey = window.performance.now();
    }

    render() {
        const { groupedItem, is_read_only, final_report, classes } = this.props;

        var has_direct_support_services = false;
        groupedItem.get("Direct Support Services").forEach(function(gi) {
            if (gi.funding_request_final > 0)
                has_direct_support_services = true;
        });

        return (
            <Grid item xs={12} lg={10} xl={7}>
                {has_direct_support_services && (
                    <>
                    <Typography variant="h3">
                        Direct Support Services
                    </Typography>
                    <Subquestion component={
                        <TextField
                            field="direct_services_portion"
                            disabled={is_read_only}
                            label="How did you determine which portions of the Direct Support Services of your shared support services is direct to this program?"
                            fullWidth
                            multiline
                            rows={4}
                            rowsMax={20}
                        />
                    } />    
                    </>
                )}
                <TextField
                    field="budget_challenges"
                    label="Explain any budget challenges or successes."
                    maxWords={!is_read_only && "100"}
                    multiline 
                    disabled={is_read_only}
                    rows={4}
                    rowsMax={20}
                    fullWidth
                />
                <div style={{float:"left",marginTop:-20}}>
                    <TextField
                        disabled={is_read_only}
                        eventHandle={(v, e) => this.updateDisclose(v, e)}
                        field="disclose_total_revenue" useTextFormat
                        className={classes.widthSixty}
                    >
                        <i style={{textAlign:'center',display:"block"}}>Total Revenue</i> <a rel="noopener noreferrer" href='https://www.revisor.mn.gov/statutes/cite/97A.056#stat.97A.056.22' target='_blank'>per 97A.056 subd 22(a)</a>
                    </TextField>
                    <span style={{marginLeft:5,marginRight:5,marginTop:65,display:"inline-block"}}>-</span>
                    <TextField
                        disabled={is_read_only}
                        eventHandle={(v, e) => this.updateDisclose(v, e)}
                        field="disclose_revenue_spent" useTextFormat
                        className={classes.widthSixty}
                    >
                        <i style={{textAlign:'center',display:"block"}}>Revenue Spent</i> <a className={classes.hidden} rel="noopener noreferrer" href='https://www.revisor.mn.gov/statutes/cite/97A.056#stat.97A.056.22' target='_blank'>per 97A.056 subd 22(a)</a>
                    </TextField>
                    <span style={{marginLeft:5,marginRight:5,marginTop:65,display:"inline-block"}}>=</span>
                    <Form
                        key={this.budgetKey}
                        dontValidateOnMount={true}
                        validateOnSubmit={true}
                        defaultValues={final_report.formData}>
                        {formApi => (
                            <form onSubmit={formApi.submitForm} style={{display:"inline"}}>
                                <TextField
                                    disabled={true} key={this.budgetKey}
                                    field="disclose_revenue_balance" useTextFormat
                                    className={classes.widthSixty}
                                >
                                    <i style={{textAlign:'center',display:"block"}}>	Revenue Balance</i><a className={classes.hidden} rel="noopener noreferrer" href='https://www.revisor.mn.gov/statutes/cite/97A.056#stat.97A.056.22' target='_blank'>per 97A.056 subd 22(a)</a>
                                </TextField>
                            </form>
                        )}
                    </Form>
                </div>
                <CheckboxGroup
                    field="appropriate_uses"
                    label="Of the money disclosed above, please check all of the appropriate uses of the money."
                    disabled={is_read_only}
                    options={MAKE_OPTIONS([
                        'A. This revenue, or a portion of it, was used according to the appropriation purposes approved in the AP Forest.',
                        'B. This revenue, or a portion of it, was used for other purposes as approved in the AP by the LSOHC.',
                        'C. This revenue, or a portion of it, was transferred back to the OHF.',
                        'D. This is not applicable because the recipient is a state agency or department.',
                        'E. This is not applicable as there was no revenue generated.'
                    ])}
                />
                {final_report.disclose_total_revenue && final_report.disclose_total_revenue > 0 && (
                    <TextField
                        field="itemize_revenue"
                        label="Itemize out how the revenues were spent:"
                        multiline 
                        disabled={is_read_only}
                        rows={4}
                        rowsMax={20}
                        fullWidth
                    />
                )}
            </Grid>
        )
    }
}

BudgetTab = connect(
    (state, ownProps) => ({
        budgets: getBudgets(state, ownProps),
        authState: state.auth
    }),
    {
        ...FinalReport.actions,
        ...FinalBudget.actions,
        ...FinalBudgetPersonnel.actions,
        ...FinalBudgetCapitalEquipment.actions
    }
)(BudgetTab);

export default withStyles(styles)(withRouter(BudgetTab));
