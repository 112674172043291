import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import { Form } from "react-form";
import { connect } from "react-redux";
import isEqual from "lodash-es/isEqual";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";

import { withStyles } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import ChevronRight from "@material-ui/icons/ChevronRight";
import CloudDownload from "@material-ui/icons/CloudDownload";
import CloudUpload from "@material-ui/icons/CloudUpload";
import Warning from "@material-ui/icons/Warning";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddCircleIcon from "@material-ui/icons/AddCircle";

import { Proposal, ProposalParcel } from "../models";

import TextField from "../../common/TextField";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import TablePaginationActionsWrapped from "../../common/Paginator";
import CustomTableCell from "../../common/TableCell";
import FileInput from "../../common/FileInput";
import Select from "../../common/Select";
import HelpLabel from "../../common/HelpLabel";
import Switch from "../../common/Switch";
import { createSelector } from "../../common/orm";
import { COUNTIES, TOWNSHIPS, RANGES, SECTIONS, ACTIVITIES, OPENS } from "../../../api/constants";


const getParcels = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.ProposalParcel.filter({ proposal: id }).orderBy("id", "desc").toModelArray();
    }
);

const styles = theme => ({
    titleContainer: {
        backgroundColor: theme.palette.primary.main,
        textAlign: "center"
    },
    centerButtons: {
        justifyContent: "center",
        marginTop: 24
    },
    closeIcon: {
        float: "right",
        color: "#fff",
        marginTop: -16,
        marginRight: -16
    },
    supportIcon: {
        width: "3em",
        height: "3em",
        color: "#fff",
        marginLeft: 32
    },
    rightAlign: {
        textAlign: "right"
    },
    button: {
        marginRight: theme.spacing(2)
    },
    flex: {
        flex: 1
    },
    minWidth: {
        minWidth: 400
    },
    buttonFloat: {
        marginLeft: "auto",
        marginRight: "auto",
        display: "table"
    },
    table: {
        width: "auto",
        "& tbody tr:nth-child(even)": {
            backgroundColor: "#ffeff2"
        }
    },
    deleteWidth: {
        minWidth: 32,
        width: 32,
        height: 32,
        marginRight: 8,
    },
    centerAlign: {
        textAlign: "center"
    },
    nowrap: {
        whiteSpace: "nowrap"
    },
    floatLeft: {
        float: "left"
    },
    trds: {
        backgroundColor: "#FF9999"
    },
    widthTwoHundred: {
        width: 200
    },
    widthOneFifty: {
        width: 150
    },
    widthOneHundred: {
        width: 100
    },
    widthOneHundred2: {
        width: 100,
        marginTop: 10
    },
    widthThreeHundred: {
        width: 300
    },
    filter: {
        float:"right",
        marginTop:-4
    }
});

class ParcelTab extends Component {
    state = {
        uploadEdit: false,
        open: false,
        proposalFilter: null
    }
    componentDidMount() {
        const { handleUnsavedFields } = this.props;
        document.title = "Proposal: Parcels - Lessard Sams Outdoor Heritage Council";
        this.props.onRef(this);
        const _this = this;
        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted
        setTimeout(function() {
            handleUnsavedFields(false);
            _this.props.appContainer.current.scrollTop();
        }, 1);
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    updateProposal(values, fromStepper) {
        const { id } = this.props.proposal;
        const { ormProposalUpdate, history, handleUnsavedFields, handleNext, proposal } = this.props;
        const { uploadEdit } = this.state;

        if (Number.isInteger(fromStepper)) {
            values.activeStep = fromStepper;
        }
        if (!uploadEdit && proposal.signup_criteria !== "") {
            values.signup_criteria = proposal.signup_criteria;
        }
        values.federal_funds_confirmation_document = proposal.federal_funds_confirmation_document;
        values.modify_date_label = proposal.modify_date_label;
        values.illustration = proposal.illustration;
        values.financial_audit = proposal.financial_audit;
        values.board_members = proposal.board_members;

        ormProposalUpdate({
            id: id,
            ...values
        });

        handleUnsavedFields(false);

        if (!Number.isInteger(fromStepper)) {
            if (this.state.draftClick) {
                history.push("/dashboard/");
            } else {
                handleNext();
            }
        }
    }

    fileChange = () => {
        this.setState({uploadEdit: true});
    };

    returnDialog = () => {
        this.setState({ open: false });
    };

    filterByValue = (array, string) => {
        return array.filter(o =>
            Object.keys(o).some(k => o[k] && o[k].toString().toLowerCase().includes(string.toLowerCase())));
    };

    setFilter = (val) => {
        this.setState({ proposalFilter: val });
    };

    render() {
        const { 
            classes,
            proposal,
            handleUnsavedFields,
            ormProposalParcelCreate,
            ormProposalParcelDelete,
            ormProposalParcelUpdate,
            ormProposalParcelReload,
            authState,
            settings,  
            ActionMenu
        } = this.props;
        var is_read_only = true;
        if ((authState && authState.user && authState.user.role === "admin") || proposal.status === "New")
            is_read_only = false;
        if (authState && authState.user && authState.user.role === "readonly")
            is_read_only = true;
        var { parcels } = this.props;
        const { open, proposalFilter } = this.state;

        if (proposalFilter) {
            parcels = this.filterByValue(parcels, proposalFilter);
        }

        const year = parseInt(proposal.ml_year);

        return (
            <>
                <Form
                    getApi={el => (this.form = el)}
                    key={proposal.id}
                    dontValidateOnMount={true}
                    validateOnSubmit={true}
                    defaultValues={proposal.formData}
                    formDidUpdate={() => handleUnsavedFields(true)}
                    onSubmit={(values, fromStepper) => this.updateProposal(values, fromStepper)}>
                    {formApi => (
                        <form onSubmit={formApi.submitForm}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <HelpLabel
                                        inputLabel="Parcels"
                                        showLabel={true}
                                        title={true}
                                        helpText={settings.parcels_tab_help}
                                    />
                                    {ActionMenu}
                                </Grid>
                                <Grid item xs={12} md={10} lg={8} xl={7}>
                                    <div className="insborder">
                                        <Typography gutterBottom>
                                            Programs must provide a parcel lists showing specific parcels where work is anticipated to take place.  
                                            If this is a program with a sign up program and parcels are to be determined during that process, please
                                            attach details on the program and the specific prioritization criteria used for that program.  
                                        </Typography>
                                        <Typography>
                                            Uncertain which regions your parcel is in? – <a rel="noopener noreferrer" href="https://mnatlas.org/gis-tool/?id=k_0414" target="_blank">click here to zoom in on a map</a>. If you need to change the Eco Region, update the check boxes are under the General Tab.   
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={10} lg={8} xl={7}>
                                    <FileInput
                                        field="signup_criteria"
                                        id="signup_criteria"
                                        label="Sign-up Criteria"
                                        disabled={is_read_only}
                                        eventHandle={this.fileChange}
                                    >
                                        <HelpLabel
                                            inputLabel="Sign-up Criteria"
                                            showLabel={true}
                                            helpText="The sign-up criteria is only required for projects that currently have no parcels."
                                        />
                                    </FileInput>
                                    {year >= 2017 && (
                                        <TextField
                                            field="explain_parcel_process"
                                            label="Explain the process used to identify, prioritize, and select the parcels on your list"
                                            maxWords={!is_read_only && "250"}
                                            multiline
                                            disabled={is_read_only}
                                            rows={2}
                                            rowsMax={20}
                                            fullWidth
                                        />
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <Button disabled={is_read_only} variant="contained" color="primary" onClick={() => ormProposalParcelCreate({proposal: proposal.id, direction: "2"})}>
                                        <AddCircleIcon />
                                        &nbsp;&nbsp;&nbsp;Add New Parcel
                                    </Button>
                                    <TextField
                                        eventHandle={this.setFilter}
                                        field="_filter"
                                        label="Filter"
                                        filterInput
                                        className={classes.filter}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <div style={{overflowX: "auto"}}>
                                        <ParcelTable
                                            ormProposalParcelUpdate={ormProposalParcelUpdate}
                                            ormProposalParcelDelete={ormProposalParcelDelete}
                                            rows={parcels}
                                            proposal={proposal}
                                            is_read_only={is_read_only}
                                            classes={classes}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} className={classes.centerAlign}>
                                    <Button variant="contained" type="button" className={classes.button} onClick={() => window.open("/static/templates/ParcelTemplate.xlsx")}>
                                        Download Excel Parcel Template&nbsp;&nbsp;&nbsp;<CloudDownload />
                                    </Button>
                                    <Button variant="contained" type="button" className={classes.button} onClick={() => window.open("/reports/proposal/" + proposal.id + "/parcel_download")}>
                                        Download Excel Parcel List&nbsp;&nbsp;&nbsp;<CloudDownload />
                                    </Button>
                                    <Button disabled={is_read_only} variant="contained" type="button" onClick={() => this.setState({ open: true })}>
                                        Upload Excel Parcel List&nbsp;&nbsp;&nbsp;<CloudUpload />
                                    </Button>
                                </Grid>
                                <Grid item xs={12} className={classes.rightAlign}>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        disabled={is_read_only}
                                        onClick={() => this.setState({ draftClick: true, submitClicked: true })}
                                        className={classes.button}>
                                        Save Draft and Return to Dashboard
                                    </Button>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        disabled={is_read_only}
                                        color="primary"
                                        onClick={() => this.setState({ draftClick: false, submitClicked: true })}>
                                        Save and Proceed to Attachments <ChevronRight />
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Form>
                <ParcelUpload ormProposalParcelReload={ormProposalParcelReload} authState={authState} proposal={proposal} classes={classes} open={open} returnDialog={this.returnDialog} />
            </>
        );
    }
}

class ParcelTable extends Component {
    state = {
        warningOpen: false,
        page: 0,
        rowsPerPage: 10,
        order: "desc",
        orderBy: null,
        open: false,
        popupId: null,
        test: window.performance.now()
    };
    
    columnData = [
        { id: "actions", numeric: false, label: "Actions", allowSort: false, width: "20px" },
        { id: "name", numeric: false, label: "Name", allowSort: true },
        { id: "county", numeric: false, label: "County", allowSort: true },
        { id: "township", numeric: false, label: "Township", allowSort: true },
        { id: "range", numeric: false, label: "Range", allowSort: true },
        { id: "direction", numeric: false, label: "Direction", allowSort: true },
        { id: "section", numeric: false, label: "Section", allowSort: true },
        { id: "acres", numeric: true, label: "# of Acres", allowSort: true },
        { id: "cost_estimate", numeric: true, label: "Budgeting Estimated Cost ($)", allowSort: true },
        { id: "description", numeric: false, label: "Description", allowSort: true },
        { id: "activity", numeric: false, label: "Activity", allowSort: true },
        { id: "existing_protection", numeric: false, label: "Existing Protection?", allowSort: true },
        { id: "open_hunting", numeric: false, label: "Open to hunting per State of Minnesota regulations?", allowSort: true },
        { id: "open_fishing", numeric: false, label: "Open to fishing per State of Minnesota regulations?", allowSort: true },
        { id: "buildings", numeric: true, label: "# of Buildings / Improvements", allowSort: true },
        { id: "buildings_description", numeric: false, label: "Buildings / Improvements Description", allowSort: true },
        { id: "estimated_building_value", numeric: true, label: "Value of Buildings / Improvements", allowSort: true },
        { id: "disposition", numeric: false, label: "Disposition of Improvements", allowSort: true },
    ];
    typingTimer = [];
    objectsToUpdate = [];

    componentDidUpdate(prevProps) {
        console.log(prevProps.rows);
        console.log(this.props.rows);
        if (!isEqual(prevProps.rows, this.props.rows) && this.props.rows.length > 0 && this.state.orderBy) {
            this.handleRequestSort(null, this.state.orderBy, true);
        }
    }

    updateRecord = (value, element, id) => {
        const { ormProposalParcelUpdate } = this.props;
        const comp = this;

        const val = {
            id: id,
            [element]: value
        }

        comp.objectsToUpdate[id] = { ...comp.objectsToUpdate[id], ...val };

        clearTimeout(this.typingTimer[id]);
        this.typingTimer[id] = setTimeout(function() {
            ormProposalParcelUpdate(comp.objectsToUpdate[id]);
        }, 1000);
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value, page: 0 });
    };

    handleRequestSort = (event, property, refresh) => {
        const orderBy = property;
        let order = "asc";

        if (this.state.orderBy === property && this.state.order === "asc") {
            order = "desc";
        }
        if (refresh === true)
            order = this.state.order;

        var columnData = this.columnData;
        if (!columnData)
            return;

        this.props.rows.sort(function(a, b) {
            var numeric = columnData.find(cD => cD.id === orderBy).numeric;
            if (numeric) {
                if (order === "desc") return parseFloat(b[orderBy]) < parseFloat(a[orderBy]) ? -1 : 1;
                else return parseFloat(a[orderBy]) < parseFloat(b[orderBy]) ? -1 : 1;
            } else {
                if (order === "desc")
                    return (b[orderBy] || "").toUpperCase() < (a[orderBy] || "").toUpperCase() ? -1 : 1;
                else return (a[orderBy] || "").toUpperCase() < (b[orderBy] || "").toUpperCase() ? -1 : 1;
            }
        });

        this.setState({ order, orderBy });
    };

    returnDialog2 = () => {
        this.setState({ open: false });
    };

    updateForm = () => {
        this.setState({test: window.performance.now()})
    };

    render() {
        const { rows, ormProposalParcelDelete, classes, is_read_only, ormProposalParcelUpdate } = this.props;
        const { warningOpen, page, rowsPerPage, order, orderBy, open, popupId, test } = this.state;

        return (
            <>
            <Table className={classes.table} key={test}>
                <EnhancedTableHead 
                    columnData={this.columnData} 
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={this.handleRequestSort}/>
                <TableBody>
                    {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(n => {
                        return (
                            <TableRow hover key={n.id}>
                                <CustomTableCell className={classes.nowrap}>
                                    <Tooltip title="Edit Parcel in Popup">
                                        <Button
                                            color="primary"
                                            disabled={is_read_only}
                                            className={classes.deleteWidth}
                                            onClick={() => this.setState({popupId: n, open: true})}>
                                            <EditIcon color="primary" />
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title="Delete Parcel">
                                        <Button
                                            color="primary"
                                            disabled={is_read_only}
                                            className={classes.deleteWidth}
                                            onClick={() => ormProposalParcelDelete(n.id)}>
                                            <DeleteIcon color="primary" />
                                        </Button>
                                    </Tooltip>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form key={n.id + "name"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={is_read_only} className={classes.widthTwoHundred} multiline tableFormat eventHandleProps1={n.id} eventHandle={this.updateRecord} field="name" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form key={n.id + "county"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <Select disabled={is_read_only} className={classes.widthOneFifty} tableFormat options={COUNTIES} eventHandleProps1={n.id} eventHandle={this.updateRecord} field="county" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell className={!n.has_geom && classes.trds}>
                                    <Form key={n.id + "township"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                               <Select disabled={is_read_only} className={classes.widthOneHundred} tableFormat options={TOWNSHIPS} eventHandleProps1={n.id} eventHandle={this.updateRecord} field="township" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell className={!n.has_geom && classes.trds}>
                                    <Form key={n.id + "range"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                               <Select disabled={is_read_only} className={classes.widthOneHundred} tableFormat options={RANGES} eventHandleProps1={n.id} eventHandle={this.updateRecord} field="range" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell className={!n.has_geom && classes.trds}>
                                    <Form key={n.id + "direction"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={is_read_only} className={classes.widthOneHundred} multiline tableFormat eventHandleProps1={n.id} eventHandle={this.updateRecord} field="direction" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell className={classNames(classes.nowrap, !n.has_geom && classes.trds)}>
                                    <Form key={n.id + "section"} defaultValues={n}>
                                        {formApi => (
                                            <form style={{display:"inline"}} onSubmit={formApi.submitForm}>
                                               <Select disabled={is_read_only} className={n.has_geom ? classes.widthOneHundred : classes.widthOneHundred2} tableFormat options={SECTIONS} eventHandleProps1={n.id} eventHandle={this.updateRecord} field="section" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                    {!n.has_geom && (
                                        <Tooltip title="Warning">
                                            <Button
                                                color="primary"
                                                className={classes.deleteWidth}
                                                onClick={() => this.setState({warningOpen: true})}>
                                                <Warning color="primary" />
                                            </Button>
                                        </Tooltip>
                                    )}
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form key={n.id + "acres"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={is_read_only} multiline tableFormat className={classes.widthOneHundred} useNumber eventHandleProps1={n.id} eventHandle={this.updateRecord} field="acres" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form key={n.id + "cost_estimate"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={is_read_only} multiline tableFormat className={classes.widthOneHundred} useTextFormat eventHandleProps1={n.id} eventHandle={this.updateRecord} field="cost_estimate" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form key={n.id + "description"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={is_read_only} multiline tableFormat className={classes.widthThreeHundred} eventHandleProps1={n.id} eventHandle={this.updateRecord} field="description" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form key={n.id + "activity"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <Select disabled={is_read_only} className={classes.widthTwoHundred} tableFormat options={ACTIVITIES} eventHandleProps1={n.id} eventHandle={this.updateRecord} field="activity" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form key={n.id + "existing_protection"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <Select disabled={is_read_only} className={classes.widthOneHundred} tableFormat options={[{label: "Yes", value: "Yes"}, {label: "No", value: "No"}]} eventHandleProps1={n.id} eventHandle={this.updateRecord} field="existing_protection" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form key={n.id + "open_hunting"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <Select disabled={is_read_only} className={classes.widthOneFifty} tableFormat options={OPENS} eventHandleProps1={n.id} eventHandle={this.updateRecord} field="open_hunting" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form key={n.id + "open_fishing"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <Select disabled={is_read_only} className={classes.widthOneFifty} tableFormat options={OPENS} eventHandleProps1={n.id} eventHandle={this.updateRecord} field="open_fishing" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form key={n.id + "buildings"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={is_read_only} multiline tableFormat className={classes.widthOneHundred} useNumber eventHandleProps1={n.id} eventHandle={this.updateRecord} field="buildings" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form key={n.id + "buildings_description"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={is_read_only} className={classes.widthThreeHundred} multiline tableFormat eventHandleProps1={n.id} eventHandle={this.updateRecord} field="buildings_description" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form key={n.id + "estimated_building_value"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={is_read_only} multiline tableFormat className={classes.widthOneFifty} useTextFormat eventHandleProps1={n.id} eventHandle={this.updateRecord} field="estimated_building_value" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form key={n.id + "disposition"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={is_read_only} multiline tableFormat className={classes.widthTwoHundred} eventHandleProps1={n.id} eventHandle={this.updateRecord} field="disposition" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                            </TableRow>
                        );
                    })}
                    {rows.length < 1 && (
                        <TableRow>
                            <CustomTableCell colSpan={this.columnData.length} className={classes.centerAlign}>
                                No Parcels Found
                            </CustomTableCell>
                        </TableRow>
                    )}
                </TableBody>
                {rows.length > 10 && (
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                colSpan={this.columnData.length}
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                classes={{
                                    toolbar: classes.floatLeft
                                }}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActionsWrapped}
                            />
                        </TableRow>
                    </TableFooter>
                )}
            </Table>
            
            <Dialog open={warningOpen} classes={{ paper: classes.minWidth }}>
                <DialogTitle className={classes.titleContainer}>
                    <Warning className={classes.supportIcon} />
                    <IconButton onClick={() => this.setState({warningOpen: false})} className={classes.closeIcon}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText style={{marginTop:24}}>
                        Parcel has an invalid TRDS and will need to be corrected before it can be displayed on the parcel map.
                    </DialogContentText>
                    <DialogActions className={classes.centerButtons}>
                        <Button onClick={() => this.setState({warningOpen: false})} color="primary" variant="contained">
                            Ok
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>    
            <EditParcel updateForm={this.updateForm} ormProposalParcelUpdate={ormProposalParcelUpdate} classes={classes} parcel={popupId} open={open} returnDialog={this.returnDialog2} />
            </>
        );
    }
}

class ParcelUpload extends Component {
    state = {
        loading: false,
        results: null
    };

    uploadDocument = values => {
        var { authState, history, authLogout, proposal, ormProposalParcelReload } = this.props;
        this.setState({loading: true, results: null});
        fetch("/reports/proposal/" + proposal.id + "/parcel_upload", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Token " + authState.user.auth_token
            },
            body: JSON.stringify(values)
        }).then(e => {
            if (e.status === 401) {
                history.push("/");
                authLogout();
            } else if (e.status === 500) {
                this.setState({loading: false, results: "There has been an error uploading parcels. Please check your document for any errant data and contact LSOHC staff if the problem still persists."});
            } else {
                e.json().then(data => {
                    var msg = "<b>Upload Results:</b><br/>";
                    data.message.forEach(function(m) {
                        msg += m.name + ": " + m.message + "<br/>";
                    })
                    this.setState({loading: false, results: msg});
                    ormProposalParcelReload();
                });
            }
        });
    };

    errorValidator = values => {
        const isRequired = val => {
            return !val ? "Required" : null;
        };
        var valObj = {
            file: isRequired(values.file)
        };

        return valObj;
    };

    render() {
        const { classes, open, returnDialog } = this.props;
        const { loading, results } = this.state;

        return (
            <Dialog open={open} classes={{ paper: classes.minWidth }}>
                <Toolbar>
                    <Typography variant="h3" className={classes.flex}>
                        Upload Parcels
                    </Typography>
                    <IconButton aria-label="Close Dialog" onClick={() => {this.setState({results:null});returnDialog();}}>
                        <Close />
                    </IconButton>
                </Toolbar>
                <DialogContent>
                    <DialogContentText>
                        <Form
                            dontValidateOnMount={true}
                            validateOnSubmit={true}
                            validateError={this.errorValidator}
                            onSubmit={values => this.uploadDocument(values)}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <FileInput field="file" id="file_upl" label="Parcel Spreadsheet" fullWidth>
                                                <HelpLabel
                                                    inputLabel="Parcel Spreadsheet"
                                                    showLabel={true}
                                                    helpText="Upload an Excel spreadsheet (.xlsx) containing your parcels on each row. The column names should match the spreadsheet generated from the 'Download Excel Parcel Template' or 'Download Excel Parcel List' buttons."
                                                />
                                            </FileInput>
                                            <Switch
                                                field="delete_current"
                                                name="delete_current">
                                                <HelpLabel
                                                    inputLabel="Remove All Existing Parcels?"
                                                    showLabel={true}
                                                    helpText="Select Yes to overwrite any existing parcels in your list prior to uploading these new parcels. Warning: Deletion of parcels is permanent."
                                                />
                                            </Switch>
                                            <div dangerouslySetInnerHTML={{ __html: results }} />
                                            {loading && (
                                                <CircularProgress size={24} className={classes.buttonFloat} />
                                            )}
                                            {!loading && (
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.buttonFloat}>
                                                    Upload
                                                </Button>
                                            )}
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}

class EditParcel extends Component {
    errorValidator = values => {
        const isRequired = val => {
            return !val ? "Required" : null;
        };
        var valObj = {
            name: isRequired(values.name)
        };

        return valObj;
    };

    editParcel = values => {
        var { ormProposalParcelUpdate, returnDialog, updateForm, parcel } = this.props;

        ormProposalParcelUpdate({
            id: parcel.id,
            ...values
        });
        updateForm();
        returnDialog();
    };

    render() {
        const { classes, open, returnDialog, parcel } = this.props;

        return (
            <Dialog open={open} classes={{ paper: classes.minWidth }}>
                <Toolbar>
                    <Typography variant="h3" className={classes.flex}>
                        Edit Parcel
                    </Typography>
                    <IconButton aria-label="Close Dialog" onClick={returnDialog}>
                        <Close />
                    </IconButton>
                </Toolbar>
                <DialogContent>
                    <DialogContentText>
                        <Form
                            dontValidateOnMount={true}
                            validateOnSubmit={true}
                            validateError={this.errorValidator}
                            defaultValues={parcel}
                            onSubmit={values => this.editParcel(values)}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <TextField field="name" label="Name" fullWidth />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Select options={COUNTIES} field="county" label="County" fullWidth />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Select options={TOWNSHIPS} field="township" label="Township" fullWidth />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Select options={RANGES} field="range" label="Range" fullWidth />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField field="direction" label="Direction" fullWidth />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Select options={SECTIONS} field="section" label="Section" fullWidth />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField field="acres" useNumber label="# of Acres" fullWidth />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField field="cost_estimate" useTextFormat label="Budgeting Estimated Cost" fullWidth />    
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField field="description" label="Description" fullWidth />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Select options={ACTIVITIES} field="activity" label="Activity" fullWidth />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Select options={[{label: "Yes", value: "Yes"}, {label: "No", value: "No"}]} field="existing_protection" label="Existing Protection?" fullWidth />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Select options={OPENS} field="open_hunting" label="Open to hunting per State of Minnesota regulations?" fullWidth />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Select options={OPENS} field="open_fishing" label="Open fishing per State of Minnesota regulations?" fullWidth />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField useNumber field="buildings" label="# of Buildings/Improvements" fullWidth />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField useTextFormat field="estimated_building_value" label="Value of Buildings/Improvements" fullWidth />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField field="buildings_description" label="Buildings/Improvements Description" fullWidth />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField field="disposition" label="Disposition of Improvements" fullWidth />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button
                                                onClick={formApi.submitForm}
                                                variant="contained"
                                                color="primary"
                                                className={classes.buttonFloat}>
                                                Save
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}

ParcelTab = connect(
    (state, ownProps) => ({
        parcels: getParcels(state, ownProps),
        authState: state.auth
    }),
    {
        ...Proposal.actions,
        ...ProposalParcel.actions
    }
)(ParcelTab);

export default withStyles(styles)(withRouter(ParcelTab));
