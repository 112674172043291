import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepButton from "@material-ui/core/StepButton";
import Menu from '@material-ui/core/Menu';
import Button from "@material-ui/core/Button";
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import BuildIcon from '@material-ui/icons/Build';
import GetApp from '@material-ui/icons/GetApp';
import { withStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import Warning from "@material-ui/icons/Warning";
import Close from "@material-ui/icons/Close";
import MailIcon from '@material-ui/icons/Mail';
import Done from "@material-ui/icons/Done";
import IconButton from "@material-ui/core/IconButton";

import DialogActions from "@material-ui/core/DialogActions";

import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import QuestionAnswer from '@material-ui/icons/QuestionAnswer';

import { getValue } from "../../api/utils";
import AppContainer from "../common/AppContainer";
import PageHeader from "../common/PageHeader";
import Snackbar from "../common/Snackbar";
import { createSelector } from "../common/orm";
import GeneralTab from "./tabs/General";
import NarrativeTab from "./tabs/Narrative";
import BudgetTab from "./tabs/Budget";
import OutputTab from "./tabs/Output";
import OutcomesTab from "./tabs/Outcomes";
import ParcelTab from "./tabs/Parcel";
import AttachmentTab from "./tabs/Attachment";
import { 
    FinalReport, FinalFutureMaintain, FinalBudget, FinalAttachment,
    FinalBudgetPersonnel, FinalBudgetCapitalEquipment, 
    FinalBudgetPersonnelOriginal, FinalBudgetCapitalEquipmentOriginal
} from "./models";
import { AccomplishmentParcel } from "../accomplishment/models";
import { SystemSetting } from "../proposal/models";

const getFinalReport = FinalReport.selectByUrlId(ap => ({
    formData: ap.ref,
    ...ap.ref
}));
const getSystemSettings = SystemSetting.selectMessage();


const getParcels = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.AccomplishmentParcel.filter({ final: id }).orderBy("name", "asc").toModelArray();
    }
);

const getBudgets = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.FinalBudget.filter({ final: id }).orderBy("order").toModelArray()
        .map(budget => ({
            _personnel: budget.budgetpersonnel.all().toRefArray(),
            _capital: budget.budgetcapital.all().toRefArray(),
            ...budget.ref
        }));;
    }
);

const styles = theme => ({
    titleContainer: {
        backgroundColor: theme.palette.primary.main,
        textAlign: "center"
    },
    minWidth: {
        minWidth: 400
    },
    closeIcon: {
        float: "right",
        color: "#fff",
        marginTop: -16,
        marginRight: -16
    },
    supportIcon: {
        width: "3em",
        height: "3em",
        color: "#fff",
        marginLeft: 32
    },
    stepRoot: {
        backgroundColor: "#eeeeee",
        marginBottom: theme.spacing(2),
        padding: theme.spacing(1),
        paddingLeft: 0,
        paddingRight: 0
    },
    pointer: {
        cursor: "pointer",
        "& > span:first-child": {
            cursor: "pointer"
        },
        borderBottom: "8px solid transparent",
        marginBottom: -8,
        paddingRight: "8px !important",
        paddingLeft: "8px !important",
        "&:hover": {
            borderBottom: "8px solid lightgray"
        }
    },
    pointerr: {
        borderBottom: "8px solid lightgray",
        marginBottom: "-8px !important"
    },
    pointer2: {
        cursor: "pointer",
        "& > span:first-child": {
            cursor: "pointer"
        },
        marginBottom: -8,
        paddingRight: "8px !important",
        paddingLeft: "8px !important",
        borderBottom: "8px solid " + theme.palette.primary.main
    },
    btnroot: {
        padding: 0,
        margin: 0,
    },
    stepColor: {
        "& svg path": {
            color: theme.palette.secondary.main // + " !important",
        }
    },
    lessMargin: {
        "& span": {
            marginTop: "8px !important",
            color: "#000"
        }
    },
    stepperWidth: {
        marginLeft: -20,
        marginRight: -20,
        maxWidth: "calc(100% + 40px)",
        flexBasis: "unset",
        width: "calc(100% + 40px)",
        padding: "0 !important"
    },
    list: {
        "& ul": {
            padding: 0
        }
    },
    topListText: {
        textAlign: "center",
        padding: 0
    },
    centerButtons: {
        justifyContent: "center",
        marginTop: 24
    },
});

function getSteps() {
    return [
        "General",
        "Narrative",
        "Budget",
        "Output Tables",
        "Outcomes",
        "Parcels",
        "Attachments"
    ];
}

class List extends Component {
    constructor(props) {
        super(props);
        this.appContainer = React.createRef();
    }
    state = {
        unsavedFields: false,
        snackbarOpen: false,
        anchorEl: null,
        setAnchorEl: null,
        submissionErrorOpen: false,
        submissionOpen: false,
        message: []
    };

    handleUnsavedFields = unsavedFields => {
        this.setState({ unsavedFields });
    };

    isLastStep(step) {
        return step === getSteps().length - 1;
    }

    handleNext = () => {
        let { activeStep } = this.props.final_report;
        const { history } = this.props;
        if (this.isLastStep(activeStep)) {
            activeStep = 0;
            history.push("/dashboard");
        } else {
            activeStep = (activeStep || 0) + 1;
        }
        this.handleStep(activeStep);
    };

    handleStep = (step, fromStepper) => {
        const { authState, final_report } = this.props;
        const { id } = this.props.final_report;

        this.handleUnsavedFields(false);

        // Hacky method to save form when using stepper
        if (fromStepper && final_report.status === "Draft") {
            // Apparently just having a single "activeForm" works because only one stepper detail can be active at once
            if (this.activeForm) {
                // Pass true to the submit function
                // Seems to come out as the second argument in onSubmit
                // Don't do the handleNext's if integer (normally an event) - set activeStep to this number
                this.activeForm.setState({ submitClicked: true });
                const f = this;
                setTimeout(function() {
                    // The state is not set right away...
                    f.activeForm.form.submitForm(step);
                }, 50);
            }
        } else {
            this.props.ormFinalReportUpdateLocalOnly({
                id: id,
                activeStep: step
            });
        }
        if (authState && authState.user && authState.user.role === "readonly")  {

        } else if (final_report.status === "Draft") {
            this.setState({ snackbarOpen: true });
        }
    };

    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    };

    componentDidMount() {
        const { 
            /*final_report,*/ ormFinalReportLoadDetail, match, authState, ormFinalFutureMaintainLoadDetailChild,
            ormFinalBudgetLoadDetailChild, ormFinalBudgetPersonnelLoadDetailChild, ormFinalBudgetCapitalEquipmentLoadDetailChild,
            ormFinalBudgetPersonnelOriginalLoadDetailChild, ormFinalBudgetCapitalEquipmentOriginalLoadDetailChild, 
            ormAccomplishmentParcelLoadDetailChild2, ormFinalAttachmentLoadDetailChild, history
        } = this.props;
        if (match.params.id === "edit.php") {
            history.push("/dashboard");
        }
        if (authState && authState.user /*&& (authState.user.role === "admin" || authState.user.role === "readonly" || (!final_report || !final_report.visible))*/) {
            ormFinalReportLoadDetail(match.params.id);
            ormFinalFutureMaintainLoadDetailChild(match.params.id);
            ormFinalBudgetLoadDetailChild(match.params.id);
            ormFinalBudgetPersonnelLoadDetailChild(match.params.id);
            ormFinalBudgetCapitalEquipmentLoadDetailChild(match.params.id);
            ormFinalBudgetPersonnelOriginalLoadDetailChild(match.params.id);
            ormFinalBudgetCapitalEquipmentOriginalLoadDetailChild(match.params.id);
            ormAccomplishmentParcelLoadDetailChild2(match.params.id);
            ormFinalAttachmentLoadDetailChild(match.params.id);
        }
    }

    componentDidUpdate() {
        const { 
            final_report, ormFinalReportLoadDetail, match, authState, ormFinalFutureMaintainLoadDetailChild,
            ormFinalBudgetLoadDetailChild, ormFinalBudgetPersonnelLoadDetailChild, ormFinalBudgetCapitalEquipmentLoadDetailChild,
            ormFinalBudgetPersonnelOriginalLoadDetailChild, ormFinalBudgetCapitalEquipmentOriginalLoadDetailChild, 
            ormAccomplishmentParcelLoadDetailChild2, ormFinalAttachmentLoadDetailChild, history
        } = this.props;
        if (match.params.id === "edit.php") {
            history.push("/dashboard");
        }
        if ((!final_report || !final_report.visible) && authState && authState.user) {
            ormFinalReportLoadDetail(match.params.id);
            ormFinalFutureMaintainLoadDetailChild(match.params.id);
            ormFinalBudgetLoadDetailChild(match.params.id);
            ormFinalBudgetPersonnelLoadDetailChild(match.params.id);
            ormFinalBudgetCapitalEquipmentLoadDetailChild(match.params.id);
            ormFinalBudgetPersonnelOriginalLoadDetailChild(match.params.id);
            ormFinalBudgetCapitalEquipmentOriginalLoadDetailChild(match.params.id);
            ormAccomplishmentParcelLoadDetailChild2(match.params.id);
            ormFinalAttachmentLoadDetailChild(match.params.id);
        }
    }

    actionMenuOpen = event => {
        this.setState({ anchorEl: event.currentTarget });
    };
    actionMenuClose = () => {
        this.setState({ anchorEl: null });
    };

    checkValues = (a, b) => {
        if (a === undefined || a === null || a === "")
            a = 0
        if (b === undefined || b === null || b === "")
            b = 0
        if (a !== b)
            return true;
        return false;
    }

    finalSubmit = () => {
        const { final_report, parcels, budgets, ormFinalReportUpdate } = this.props;
        var missing = [];
        var invalidParcel = false;
        var existingProtection = false;

        if (final_report.check_0)
            missing.push("You have not entered the written contact with the Conservation Corps of Minnesota into the status update.");
        if (final_report.check_1.length > 0)
            missing.push("These parcels are missing geometry.  Please either upload a shapefile or draw in their geometry under the initial land reporting form: " + final_report.check_1.toString());
        if (final_report.check_2.length > 0)
            missing.push("These parcels are missing their appraised and/or assessed value.  Please fill them out in the initial land reporting form for these parcels: " + final_report.check_2.toString());
        if (final_report.check_3.length > 0)
            missing.push("These parcels have acre values that do not match what is reported in the initial land reporting form: " + final_report.check_3.toString());
        if (final_report.check_4.length > 0)
            missing.push("These parcels do not have a NOFR attached in the initial land reporting form: " + final_report.check_4.toString());
        if (final_report.check_5.length > 0)
            missing.push("These parcels do not have a fully executed conservation easement attached in the initial land reporting form: " + final_report.check_5.toString());
        if (final_report.check_6.length > 0)
            missing.push("These parcels do not have a easement monitoring and enforcement attached in the initial land reporting form: " + final_report.check_6.toString());
        if (final_report.check_7.length > 0)
            missing.push("These parcels do not have a long term stewardship plan attached in the initial land reporting form: " + final_report.check_7.toString());
        if (final_report.check_8.length > 0)
            missing.push("These parcels do not have a analysis of operation and maintenance costs attached in the initial land reporting form: " + final_report.check_8.toString());
        if (final_report.check_9.length > 0)
            missing.push("These parcels do not have a restoration and management plan attached in the initial land reporting form: " + final_report.check_9.toString());
        if (final_report.check_10.length > 0)
            missing.push("These parcels have not answered 'Has there been signage erected at the site?' in the initial land reporting form: " + final_report.check_10.toString());
        if (final_report.check_11.length > 0)
            missing.push("These parcels have not answered 'Is this land permanently protected?' in the initial land reporting form: " + final_report.check_11.toString());

            
        if (final_report.is_correct_region)
            missing.push("You have entered parcels that are not in the region(s) defined on the general tab: " + final_report.is_correct_region);
        // if (final_report.is_correct_county)
        //     missing.push("You have entered parcels that are in a different county (as calculated from the TRDS) than the county you have entered: " + final_report.is_correct_county);
        var activity = false;
        parcels.filter(f => f.status === "Completed").forEach(function(p) {
            if (!p.activity)
                activity = true;
            if (!p.has_geom)
                invalidParcel = true;
            if ((p.activity === "Restore" || p.activity === "Enhance") && p.existing_protection !== "Yes")
                existingProtection = true;
        });
        if (!final_report.disclose_revenue_spent || ! final_report.disclose_total_revenue)
            missing.push("Please disclose all revenues received (Budget Tab)");
        if (existingProtection)
            missing.push("You have submitted a Restore/Enhance parcel that does NOT have existing protection.  As per 97A.056, subd 13 (f), only parcels on land permanently protected by a conservation easement or public ownership or in public waters as defined in section 103G.005, subdivision 15 are eligible for OHF funds? Please consult with staff."); 
        if (activity)
            missing.push("You have undefined parcel activities. Please make sure all parcels in the parcel tab have an activity.");
        if (this.checkValues(final_report.output_acres_resourcetype_total_total_final, final_report.output_acres_ecologicalsection_total_total_final))
            missing.push("The acre totals in Tables 1 and 3 do not match under the Output Tables tab.");
        if (this.checkValues(final_report.output_dollars_resourcetype_total_total_final, final_report.output_dollars_ecologicalsection_total_total_final))
            missing.push("The total request for funding in Tables 2 and 4 do not match under the Output Tables tab.");
        if (this.checkValues(final_report.output_acres_resourcetype_total_total_final, final_report.output_acres_ecologicalsection_total_total_final))
            missing.push("The restore acres in Tables 1 and 3 do not match under the Output Tables tab.");
        if (final_report.output_acres_resourcetype_total_total_final === "" || final_report.output_acres_resourcetype_total_total_final === "0" || final_report.output_acres_resourcetype_total_total_final === null)
            missing.push("You must enter in acres values for the Output Tables.");
        if (this.checkValues(final_report.output_acres_resourcetype_feepilt_total_final, final_report.output_acres_ecologicalsection_feepilt_total_final))
            missing.push("The protect in fee with state PILT liability acres in Tables 1 and 3 do not match under the Output Tables tab.");
        if (this.checkValues(final_report.output_acres_resourcetype_feewopilt_total_final, final_report.output_acres_ecologicalsection_feewopilt_total_final))
            missing.push("The protect in fee w/o state PILT liability acres in Tables 1 and 3 do not match under the Output Tables tab.");
        if (this.checkValues(final_report.output_acres_resourcetype_easement_total_final, final_report.output_acres_ecologicalsection_easement_total_final))
            missing.push("The easement acres in Tables 1 and 3 do not match under the Output Tables tab.");
        if (this.checkValues(final_report.output_acres_resourcetype_enhance_total_final, final_report.output_acres_ecologicalsection_enhance_total_final))
            missing.push("The enhance acres in Tables 1 and 3 do not match under the Output Tables tab.");
        if (this.checkValues(final_report.output_dollars_resourcetype_restore_total_final, final_report.output_dollars_ecologicalsection_restore_total_final))
            missing.push("The restore dollars in Tables 2 and 4 do not match under the Output Tables tab.");
        if (this.checkValues(final_report.output_dollars_resourcetype_feepilt_total_final, final_report.output_dollars_ecologicalsection_feepilt_total_final))
            missing.push("The protect in fee with state PILT liability dollars in Tables 2 and 4 do not match under the Output Tables tab.");
        if (this.checkValues(final_report.output_dollars_resourcetype_feewopilt_total_final, final_report.output_dollars_ecologicalsection_feewopilt_total_final))
            missing.push("The protect in fee w/o state PILT liability dollars in Tables 2 and 4 do not match under the Output Tables tab.");
        if (this.checkValues(final_report.output_dollars_resourcetype_easement_total_final, final_report.output_dollars_ecologicalsection_easement_total_final))
            missing.push("The easement dollars in Tables 2 and 4 do not match under the Output Tables tab.");
        if (this.checkValues(final_report.output_dollars_resourcetype_enhance_total_final, final_report.output_dollars_ecologicalsection_enhance_total_final))
            missing.push("The enhance dollars in Tables 2 and 4 do not match under the Output Tables tab.");

        var GrandTotal = 0;
        var withpilt = false;
        var wopilt = false;
        var easementbud = false;
        var personnelposition = false;
        var personnelleverage = false;
        var capitalposition = false;
        var capitalleverage = false;
        var budgetleverage = false;
        budgets.forEach(function(b) {
            if (b.item === "Grand Total") {
                GrandTotal += b.funding_request_final;
            }
            if (b.item === "Fee Acquisition w/ PILT" && b.funding_request_final > 0 && final_report.output_dollars_resourcetype_feepilt_total_final === 0) {
                withpilt = true;
            }
            if (b.item === "Fee Acquisition w/o PILT" && b.funding_request_final > 0 && final_report.output_dollars_resourcetype_feewopilt_total_final === 0) {
                wopilt = true;
            }
            if ((b.item === "Easement Acquisition" || b.item === "Easement Stewardship") && b.funding_request_final > 0 && final_report.output_dollars_resourcetype_easement_total_final === 0) {
                easementbud = true;
            }
            if (b.item === "Personnel") {
                b._personnel.forEach(function(p) {
                    if (!p.position)
                        personnelposition = true;
                    if (p.anticipated_leverage > 0 && !p.leverage_source)
                        personnelleverage = true;
                })
            }
            else if (b.item === "Capital Equipment") {
                b._capital.forEach(function(p) {
                    if (!p.position)
                        capitalposition = true;
                    if (p.anticipated_leverage > 0 && !p.leverage_source)
                        capitalleverage = true;
                })
            }
            else if (b.item !== "Grand Total" && b.anticipated_leverage_final > 0 && !b.leverage_source)
                budgetleverage = true;
        });   
        if (withpilt) {
            missing.push("You have a dollar amount entered into the budget line item for Protect in Fee with State PILT Liability, but you do not have any entered for that output table line item.");
        }   
        if (wopilt) {
            missing.push("You have a dollar amount entered into the budget line item for Protect in Fee without State PILT Liability, but you do not have any entered for that output table line item.");
        }   
        if (easementbud) {
            missing.push("You have a dollar amount entered into the budget line item for Easements, but you do not have any entered for that output table line item.");
        }   
        if (personnelposition) {
            missing.push("You have budget personnel entries with no positions defined. Please enter their role.");
        }   
        if (personnelleverage) {
            missing.push("You have budget personnel entries with leverage values, but no source defined. Please enter the source.");
        }   
        if (capitalposition) {
            missing.push("You have budget capital equipment entries with no item name defined. Please enter their name.");
        }   
        if (capitalleverage) {
            missing.push("You have budget capital equipment entries with leverage values, but no source defined. Please enter the source.");
        }   
        if (budgetleverage) {
            missing.push("You have budget entries with leverage values, but no source defined. Please enter the source.");
        }  
        if (final_report.output_dollars_resourcetype_total_total_final !== GrandTotal)
            missing.push("The total budget request under the Budget tab does not match the total request funding for tables 2 and 4 under the Output Tables tab.");
        if (parseInt(final_report.funds_recommended.replace(/,/g, "")) < parseInt(GrandTotal)) {
            missing.push("You have spent more funds than appropriated. Please edit your budget.");
        }

        const has_northern_forest = getValue(final_report, "eco_regions").indexOf("Northern Forest") > -1;
        const has_transition = getValue(final_report, "eco_regions").indexOf("Forest / Prairie Transition") > -1;
        const has_prairie = getValue(final_report, "eco_regions").indexOf("Prairie") > -1;
        const has_southeast_forest = getValue(final_report, "eco_regions").indexOf("Southeast Forest") > -1;
        const has_metro = getValue(final_report, "eco_regions").indexOf("Metro / Urban") > -1;
        if ((final_report.output_acres_ecologicalsection_total_northernforest_final || final_report.output_dollars_ecologicalsection_total_northernforest_final) && !has_northern_forest)
            missing.push("You have entered amounts into the Northern Forest columns of the Output Tables, but have not indicated you are working in the region Northern Forest under the General Tab.");
        if ((final_report.output_acres_ecologicalsection_total_forestprairie_final || final_report.output_dollars_ecologicalsection_total_forestprairie_final) && !has_transition)
            missing.push("You have entered amounts into the Forest / Prairie columns of the Output Tables, but have not indicated you are working in the region Forest / Prairie Transition under the General Tab.");
        if ((final_report.output_acres_ecologicalsection_total_prairie_final || final_report.output_dollars_ecologicalsection_total_prairie_final) && !has_prairie)
            missing.push("You have entered amounts into the Prairie columns of the Output Tables, but have not indicated you are working in the region Prairie under the General Tab.");
        if ((final_report.output_acres_ecologicalsection_total_metrourban_final || final_report.output_dollars_ecologicalsection_total_metrourban_final) && !has_metro)
            missing.push("You have entered amounts into the Metro / Urban columns of the Output Tables, but have not indicated you are working in the region Metro / Urban under the General Tab.");
        if ((final_report.output_acres_ecologicalsection_total_seforest_final || final_report.output_dollars_ecologicalsection_total_seforest_final) && !has_southeast_forest)
            missing.push("You have entered amounts into the SE Forest columns of the Output Tables, but have not indicated you are working in the region Southeast Forest under the General Tab.");

        const has_pra_forest = getValue(final_report, "priority_resources_activity").indexOf("Forest") > -1;
        const has_pra_wetlands = getValue(final_report, "priority_resources_activity").indexOf("Wetlands") > -1;
        const has_pra_habitat = getValue(final_report, "priority_resources_activity").indexOf("Habitat") > -1;
        const has_pra_prairie = getValue(final_report, "priority_resources_activity").indexOf("Prairie") > -1;
        if ((final_report.output_acres_resourcetype_total_wetland_final || final_report.output_dollars_resourcetype_total_wetland_final) && !has_pra_wetlands)
            missing.push("You have entered amounts into the Wetlands columns of the Output Tables, but have not indicated you are working with the resource type Wetlands under the General Tab.");
        if ((final_report.output_acres_resourcetype_total_prairie_final || final_report.output_dollars_resourcetype_total_prairie_final) && !has_pra_prairie)
            missing.push("You have entered amounts into the Prairies columns of the Output Tables, but have not indicated you are working with the resource type Prairie under the General Tab.");
        if ((final_report.output_acres_resourcetype_total_forest_final || final_report.output_dollars_resourcetype_total_forest_final) && !has_pra_forest)
            missing.push("You have entered amounts into the Forest columns of the Output Tables, but have not indicated you are working with the resource type Forest under the General Tab.");
        if ((final_report.output_acres_resourcetype_total_habitat_final || final_report.output_dollars_resourcetype_total_habitat_final) && !has_pra_habitat)
            missing.push("You have entered amounts into the Habitats columns of the Output Tables, but have not indicated you are working with the resource type Habitat under the General Tab.");

        const has_enhance = getValue(final_report, "activity_types").indexOf("Enhance") > -1;
        const has_easement = getValue(final_report, "activity_types").indexOf("Protect in Easement") > -1;
        const has_fee = getValue(final_report, "activity_types").indexOf("Protect in Fee") > -1;
        const has_restore = getValue(final_report, "activity_types").indexOf("Restore") > -1;
        if ((final_report.output_acres_resourcetype_restore_total_final || final_report.output_dollars_resourcetype_restore_total_final ||  final_report.output_acres_ecologicalsection_restore_total_final || final_report.output_dollars_ecologicalsection_restore_total_final) && !has_restore)
            missing.push("You have entered amounts into the Restore rows of the Output Tables, but have not indicated you are working with the activity type Restore under the General Tab.");
        if ((final_report.output_acres_resourcetype_feepilt_total_final || final_report.output_dollars_resourcetype_feepilt_total_final ||  final_report.output_acres_ecologicalsection_feepilt_total_final || final_report.output_dollars_ecologicalsection_feepilt_total_final ||
            final_report.output_acres_resourcetype_feewopilt_total_final || final_report.output_dollars_resourcetype_feewopilt_total_final ||  final_report.output_acres_ecologicalsection_feewopilt_total_final || final_report.output_dollars_ecologicalsection_feewopilt_total_final) && !has_fee)
            missing.push("You have entered amounts into the Protect in Fee rows of the Output Tables, but have not indicated you are working with the activity type Protect in Fee under the General Tab.");
        if ((final_report.output_acres_resourcetype_easement_total_final || final_report.output_dollars_resourcetype_easement_total_final ||  final_report.output_acres_ecologicalsection_easement_total_final || final_report.output_dollars_ecologicalsection_easement_total_final) && !has_easement)
            missing.push("You have entered amounts into the Protect in Easement rows of the Output Tables, but have not indicated you are working with the activity type Protect in Easement under the General Tab.");
        if ((final_report.output_acres_resourcetype_enhance_total_final || final_report.output_dollars_resourcetype_enhance_total_final ||  final_report.output_acres_ecologicalsection_enhance_total_final || final_report.output_dollars_ecologicalsection_enhance_total_final) && !has_enhance)
            missing.push("You have entered amounts into the Enhance rows of the Output Tables, but have not indicated you are working with the activity type Enhance under the General Tab.");
    
        if (!final_report.signup_criteria) { 
            if (invalidParcel)
                missing.push("At least one parcel contains an invalid TRDS. If you do not have any parcels please upload your sign-up criteria instead.");
            if (parcels.length === 0)
                missing.push("You do not have parcels defined or a sign-up criteria attached.");
        }

        if (missing.length > 0) {
            this.setState({submissionErrorOpen: true, missing: missing})
        } else if(window.confirm("Your final report has been successully completed. Click OK to submit your report to the LSOHC staff.")){
            ormFinalReportUpdate({
                id: final_report.id,
                status: "Submitted"
            });
            this.setState({ submissionOpen: true });
        }
    }

    ActionMenu = ({ options, actionMenuOpen, actionMenuClose, anchorEl, final_report, classes, authState, ...rest }) => (
        <div style={{float:"right"}}>
            <Button
                variant="contained"
                color="primary"
                onClick={actionMenuOpen} >
                <BuildIcon />&nbsp;&nbsp;&nbsp;Select Action
            </Button>
            <Menu
                className={classes.list}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={actionMenuClose}
            >
                <MenuItem style={{borderBottom: "1px solid #666666",backgroundColor: "#eee",cursor: "default"}}>
                    <ListItemText
                        primary={<Typography variant="h3">Actions</Typography>}
                        classes={{
                            root: classes.topListText,
                            primary: classes.topListText
                        }}
                    />
                </MenuItem>
                <MenuItem onClick={() => {window.open("/reports/finalreport/" + final_report.id + "/pdf");actionMenuClose();}} style={{ borderBottom: "1px solid #666666"}}>
                    <ListItemIcon>
                        <GetApp />
                    </ListItemIcon>
                    <ListItemText primary="Generate Report" />
                </MenuItem>
                {final_report.status === "Draft" && (
                    <MenuItem onClick={() => {this.finalSubmit();actionMenuClose();}} style={{ borderBottom: "1px solid #666666"}}>
                        <ListItemIcon>
                            <MailIcon />
                        </ListItemIcon>
                        <ListItemText primary="Submit Report for Approval" />
                    </MenuItem>
                )}
                {authState && authState.user && authState.user.role === "admin" && (
                    <MenuItem onClick={() => {window.open("/reports/finalreport/" + final_report.id + "/comparison");actionMenuClose();}} style={{ borderBottom: "1px solid #666666"}}>
                        <ListItemIcon>
                            <GetApp />
                        </ListItemIcon>
                        <ListItemText primary="Comparison Report" />
                    </MenuItem>
                )}
                <MenuItem style={{borderBottom: "1px solid #666666",backgroundColor: "#eee",cursor: "default"}}>
                    <ListItemIcon>
                        <QuestionAnswer />
                    </ListItemIcon>
                    <ListItemText primary={"Status: " + final_report.status} />
                </MenuItem>
            </Menu>
        </div>
    );

    render() {
        const { classes, final_report, settings, history, authState } = this.props;
        const { snackbarOpen, missing, submissionErrorOpen, submissionOpen } = this.state;

        if (!final_report || !final_report.visible) {
            return (
                <AppContainer authenticated synced={true} />
            );
        }
        
        return (
            <AppContainer authenticated innerRef={this.appContainer}>
                <Grid container spacing={2}>
                    <PageHeader title={"ML " + final_report.ml_year + " " + final_report.name} />
                    <Grid item xs={12} className={classes.stepperWidth}>
                        <Stepper
                            nonLinear
                            className={classes.stepRoot}
                            alternativeLabel
                            activeStep={final_report.activeStep || 0}>
                            {getSteps().map((label, index) => {
                                return (
                                    <Step key={label} className={final_report.activeStep !== index ? classes.pointer : classes.pointer2}>
                                        <StepButton className={classes.btnroot} focusVisibleClassName={classes.pointerr} onClick={() => this.handleStep(index, true)}>
                                            <StepLabel
                                                classes={{
                                                    labelContainer: classes.lessMargin,
                                                    iconContainer: classes.stepColor
                                                }}>
                                                {label}
                                            </StepLabel>
                                        </StepButton>
                                    </Step>
                                );
                            })}
                        </Stepper>
                        <div style={{ marginRight: 30, marginLeft: 30 }}>
                            {(final_report.activeStep === 0 || !final_report.activeStep) && (
                                <GeneralTab
                                    onRef={el => (this.activeForm = el)}
                                    final_report={final_report}
                                    appContainer={this.appContainer}
                                    handleNext={this.handleNext}
                                    handleUnsavedFields={this.handleUnsavedFields}
                                    settings={settings}
                                    ActionMenu={<this.ActionMenu authState={authState} classes={classes} final_report={final_report} actionMenuOpen={this.actionMenuOpen} anchorEl={this.state.anchorEl} actionMenuClose={this.actionMenuClose} />}
                                />
                            )}
                            {final_report.activeStep === 1 && (
                                <NarrativeTab
                                    onRef={el => (this.activeForm = el)}
                                    final_report={final_report}
                                    appContainer={this.appContainer}
                                    handleNext={this.handleNext}
                                    handleUnsavedFields={this.handleUnsavedFields}
                                    settings={settings}
                                    ActionMenu={<this.ActionMenu authState={authState} classes={classes} final_report={final_report} actionMenuOpen={this.actionMenuOpen} anchorEl={this.state.anchorEl} actionMenuClose={this.actionMenuClose} />}
                                />
                            )}
                            {final_report.activeStep === 2 && (
                                <BudgetTab
                                    onRef={el => (this.activeForm = el)}
                                    final_report={final_report}
                                    appContainer={this.appContainer}
                                    handleNext={this.handleNext}
                                    handleUnsavedFields={this.handleUnsavedFields}
                                    settings={settings}
                                    ActionMenu={<this.ActionMenu authState={authState} classes={classes} final_report={final_report} actionMenuOpen={this.actionMenuOpen} anchorEl={this.state.anchorEl} actionMenuClose={this.actionMenuClose} />}
                                />
                            )}
                            {final_report.activeStep === 3 && (
                                <OutputTab
                                    onRef={el => (this.activeForm = el)}
                                    final_report={final_report}
                                    appContainer={this.appContainer}
                                    handleNext={this.handleNext}
                                    handleUnsavedFields={this.handleUnsavedFields}
                                    settings={settings}
                                    ActionMenu={<this.ActionMenu authState={authState} classes={classes} final_report={final_report} actionMenuOpen={this.actionMenuOpen} anchorEl={this.state.anchorEl} actionMenuClose={this.actionMenuClose} />}
                                />
                            )}
                            {final_report.activeStep === 4 && (
                                <OutcomesTab
                                    onRef={el => (this.activeForm = el)}
                                    final_report={final_report}
                                    appContainer={this.appContainer}
                                    handleNext={this.handleNext}
                                    handleUnsavedFields={this.handleUnsavedFields}
                                    settings={settings}
                                    ActionMenu={<this.ActionMenu authState={authState} classes={classes} final_report={final_report} actionMenuOpen={this.actionMenuOpen} anchorEl={this.state.anchorEl} actionMenuClose={this.actionMenuClose} />}
                                />
                            )}
                            {final_report.activeStep === 5 && (
                                <ParcelTab
                                    onRef={el => (this.activeForm = el)}
                                    final_report={final_report}
                                    appContainer={this.appContainer}
                                    handleNext={this.handleNext}
                                    handleUnsavedFields={this.handleUnsavedFields}
                                    settings={settings}
                                    ActionMenu={<this.ActionMenu authState={authState} classes={classes} final_report={final_report} actionMenuOpen={this.actionMenuOpen} anchorEl={this.state.anchorEl} actionMenuClose={this.actionMenuClose} />}
                                />
                            )}
                            {final_report.activeStep === 6 && (
                                <AttachmentTab
                                    onRef={el => (this.activeForm = el)}
                                    final_report={final_report}
                                    appContainer={this.appContainer}
                                    handleNext={this.finalSubmit}
                                    handleUnsavedFields={this.handleUnsavedFields}
                                    settings={settings}
                                    ActionMenu={<this.ActionMenu authState={authState} classes={classes} final_report={final_report} actionMenuOpen={this.actionMenuOpen} anchorEl={this.state.anchorEl} actionMenuClose={this.actionMenuClose} />}
                                />
                            )}
                        </div>
                    </Grid>
                </Grid>
                <Snackbar
                    handleSnackbarClose={this.handleSnackbarClose}
                    isSuccess
                    snackbarOpen={snackbarOpen}
                />
                <Dialog open={submissionErrorOpen} classes={{ paper: classes.minWidth }}>
                    <DialogTitle className={classes.titleContainer}>
                        <Warning className={classes.supportIcon} />
                        <IconButton aria-label="Close Dialog" onClick={() => this.setState({submissionErrorOpen: false})} className={classes.closeIcon}>
                            <Close />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText style={{marginTop:24}}>
                            <Typography>
                                Please correct the following errors
                                <ul>
                                    {missing && missing.map(m => (
                                        <li>{m}</li>
                                    ))}
                                </ul>
                            </Typography>
                        </DialogContentText>
                        <DialogActions className={classes.centerButtons}>
                            <Button onClick={() => this.setState({submissionErrorOpen: false})} color="primary" variant="contained">
                                Ok
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </Dialog>
                <Dialog open={submissionOpen} classes={{ paper: classes.minWidth }}>
                    <DialogTitle className={classes.titleContainer}>
                        <Done className={classes.supportIcon} />
                        <IconButton aria-label="Close Dialog" onClick={() => history.push("/dashboard/")} className={classes.closeIcon}>
                            <Close />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText style={{marginTop:24}}>
                            <Typography>
                                <div dangerouslySetInnerHTML={{ __html: settings.confirmation_message_final }} />
                            </Typography>
                        </DialogContentText>
                        <DialogActions className={classes.centerButtons}>
                            <Button onClick={() => history.push("/dashboard/")} color="primary" variant="contained">
                                Return to Dashboard
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </Dialog>
            </AppContainer>
        );
    }
}

List = connect(
    (state, ownProps) => ({
        final_report: getFinalReport(state, ownProps),
        settings: getSystemSettings(state),
        parcels: getParcels(state, ownProps),
        budgets: getBudgets(state, ownProps),
        authState: state.auth
    }),
    {
        ...FinalReport.actions,
        ...FinalFutureMaintain.actions,
        ...FinalBudget.actions,
        ...FinalBudgetPersonnel.actions,
        ...FinalBudgetCapitalEquipment.actions,
        ...FinalBudgetPersonnelOriginal.actions,
        ...FinalBudgetCapitalEquipmentOriginal.actions,
        ...AccomplishmentParcel.actions,
        ...FinalAttachment.actions
    }
)(List);

export default withStyles(styles)(withRouter(List));
