import orm, { Model } from "../common/orm";
import { fk } from "redux-orm";

export class StatusUpdate extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "StatusUpdate";
    }
}
orm.register(StatusUpdate);

export class StatusUpdateAttachment extends Model {
    static get fields() {
        return {
            statusupdate: fk("StatusUpdate", "attachment")
        };
    }
    static get modelName() {
        return "StatusUpdateAttachment";
    }
}
orm.register(StatusUpdateAttachment);

export class StatusUpdatePartnership extends Model {
    static get fields() {
        return {
            statusupdate: fk("StatusUpdate", "partnership")
        };
    }
    static get modelName() {
        return "StatusUpdatePartnership";
    }
}
orm.register(StatusUpdatePartnership);

export class StatusUpdateView extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "StatusUpdateView";
    }
    static get isUserData() {
        return false;
    }
}
orm.register(StatusUpdateView);
