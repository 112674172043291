import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import Tooltip from "@material-ui/core/Tooltip";
import TableBody from "@material-ui/core/TableBody";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Toolbar from "@material-ui/core/Toolbar";
import CreateIcon from "@material-ui/icons/Create";
import CircularProgress from "@material-ui/core/CircularProgress";

import { withStyles } from "@material-ui/core";
import CloudUpload from "@material-ui/icons/CloudUpload";
import AddToPhotos from "@material-ui/icons/AddToPhotos";
import Close from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";


import { Proposal, ProposalAttachment } from "../models";

import TextField from "../../common/TextField";
import RadioGroup from "../../common/RadioGroup";
import FileInput from "../../common/FileInput";
import HelpLabel from "../../common/HelpLabel";
import Select from "../../common/Select";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import CustomTableCell from "../../common/TableCell";
import { createSelector } from "../../common/orm";


const getAttachments = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.ProposalAttachment.filter({ proposal: id }).toModelArray();
    }
);

const styles = theme => ({
    rightAlign: {
        textAlign: "right"
    },
    centerAlign: {
        textAlign: "center"
    },
    button: {
        marginRight: theme.spacing(2)
    },
    table: {
        width: "100%",
        "& tbody tr:nth-child(even)": {
            backgroundColor: "#eef7fa"
        }
    },
    nowrap: {
        whiteSpace: "nowrap"
    },
    deleteWidth: {
        minWidth: 32,
        width: 32,
        height: 32,
        marginRight: 8,
    },
    flex: {
        flex: 1
    },
});

const columnData = [
    {
        id: "actions",
        numeric: false,
        label: "Actions",
        allowSort: false,
        width: "20px"
    },
    { id: "name", numeric: false, label: "Document Title", allowSort: false },
    { id: "type", numeric: false, label: "Document Type", allowSort: false },
    { id: "file", numeric: true, label: "Link to File", allowSort: false }
];

class AttachmentTab extends Component {
    state = {
        uploadEdit: false,
        uploadEdit2: false,
        uploadEdit3: false,
        dialogOpen: false,
        key: null,
        attachmentId: null
    };
    componentDidMount() {
        const { handleUnsavedFields } = this.props;
        document.title = "Proposal: Attachments - Lessard Sams Outdoor Heritage Council";
        this.props.onRef(this);
        const _this = this;
        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted
        setTimeout(function() {
            handleUnsavedFields(false);
            _this.props.appContainer.current.scrollTop();
        }, 1);
        this.formKey = window.performance.now();
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    updateProposal(values, fromStepper) {
        const { id } = this.props.proposal;
        const { ormProposalUpdate, history, handleUnsavedFields, handleNext, proposal } = this.props;
        const { uploadEdit, uploadEdit2, uploadEdit3 } = this.state;

        if (Number.isInteger(fromStepper)) {
            values.activeStep = fromStepper;
        }

        if (!uploadEdit && proposal.illustration !== "") {
            values.illustration = proposal.illustration;
        }
        if (!uploadEdit2 && proposal.financial_audit !== "") {
            values.financial_audit = proposal.financial_audit;
        }
        if (!uploadEdit3 && proposal.board_members !== "") {
            values.board_members = proposal.board_members;
        }
        values.federal_funds_confirmation_document = proposal.federal_funds_confirmation_document;
        values.modify_date_label = proposal.modify_date_label;
        values.signup_criteria = proposal.signup_criteria;

        ormProposalUpdate({
            id: id,
            ...values
        });

        handleUnsavedFields(false);

        if (!Number.isInteger(fromStepper)) {
            if (!uploadEdit && !uploadEdit2 && !uploadEdit3) {
                if (this.state.draftClick) {
                    history.push("/dashboard/");
                } else {
                    handleNext();
                }
            } else {
                const f = this;
                setTimeout(function() {
                    // FIXME: Call this function after the update has returned from the server with the uploaded URL
                    f.formKey = window.performance.now();
                    f.setState({ uploadEdit: false, uploadEdit2: false, uploadEdit3: false });
                }, 2000);
            }
        }
    }

    render() {
        const { authState, classes, proposal, handleUnsavedFields, attachments, ormProposalAttachmentCreate, ormProposalAttachmentDelete, ormProposalAttachmentUpdate, settings, ActionMenu } = this.props;
        const { key, dialogOpen, attachmentId } = this.state;
        var is_read_only = true;
        if ((authState && authState.user && authState.user.role === "admin") || proposal.status === "New")
            is_read_only = false;
        if (authState && authState.user && authState.user.role === "readonly")
            is_read_only = true;

        return (
            <>
            <Form
                getApi={el => (this.form = el)}
                key={this.formKey}
                dontValidateOnMount={true}
                validateOnSubmit={true}
                defaultValues={proposal.formData}
                formDidUpdate={() => handleUnsavedFields(true)}
                onSubmit={(values, fromStepper) => this.updateProposal(values, fromStepper)}>
                {formApi => (
                    <form onSubmit={formApi.submitForm}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <HelpLabel
                                    inputLabel="Attachments"
                                    title={true}
                                    showLabel={true}
                                    helpText={settings.attachments_tab_help}
                                />
                                {ActionMenu}
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h3" style={{marginBottom:16}}>Required Attachment</Typography>
                                <HelpLabel
                                    inputLabel="Proposal Illustration"
                                    showLabel={true}
                                    helpText={
                                        <div>
                                            Provide a 1-page (front and back) Illustration.  If there are multiple partner organizations, 
                                            you may choose to provide a 1-page (front and back) illustration for each partner. You may NOT 
                                            provide a general proposal illustration in addition to each partner illustration. 
                                            The illustration should help visually convey the Who, What, Where, When, Why, and How of your proposal. 
                                            A person should have a strong understanding of your proposal after reading this document. 
                                            Items you may want to consider including: photos, graphics, tables, maps, highlights, etc. 
                                            Proposal illustrations must be leter sized documents only. 
                                            <b>Documents exceeding the 1-page per partner limit will not be included in the packet for Council
                                            review.</b>
                                        </div>
                                    }
                                />
                                <div style={{height:15}}></div>
                                <HelpLabel
                                    inputLabel={<b>Accessibility Requirements - IMPORTANT - DO NOT USE Canva</b>}
                                    showLabel={true}
                                    helpText={
                                        <div>
                                            The legislature is moving toward full document accessibility by October 1, 2024. 
                                            Full accessibility means that all members of the public are able to access and understand information provided in legislative
                                            documents. All documents submited through this system must meet basic accessibility requirements.
                                            <br/><br/><b>Accessibility Checklist</b>
                                            <ul>
                                                <li>Use document styles to structure the document (headings, paragraphs, etc.)</li>
                                                <li>Add alt text to images and figures</li>
                                                <li>Ensure that the document is properly tagged</li>
                                                <li>Fill in document properties</li>
                                                <li>Use short titles in headings (fewer than 20 words)</li>
                                                <li>Appropriately name hyperlinks (i.e. use meaningful text that reflects the link’s destination/subject, not simply the words “click here” for example)</li>
                                                <li>When it comes to tables:
                                                    <ul>
                                                        <li>Do not use tables to structure a page – only use tables for information that it truly makes sense to convey via table</li>
                                                        <li>Avoid nested tables, merged or split cells, and blank cells</li>
                                                        <li>Set column and header rows</li>
                                                    </ul>
                                                </li>
                                                <li>Avoid watermarks</li>
                                                <li>Many programs (Word, PowerPoint, etc.) include a built-in accessibility checker – use this tool to check your document and remedy any errors prior to converting to PDF and/or uploading</li>
                                                <li>Use Adobe Acrobat to convert documents to PDF format whenever possible (do not use the built in Microsoft PDF creator unless you do not have another option) </li>
                                            </ul>
                                            <b>Best Programs for Creating Accessible Documents:</b>
                                            <ul>
                                                <li>Microsoft Word</li>
                                                <li>Microsoft PowerPoint</li>
                                                <li>Adobe Indesign (ok)</li>
                                            </ul>
                                            <b>Do NOT Use:</b>
                                            <ul>
                                                <li>Canva (this program is NOT accessible)</li>
                                            </ul>
                                            Do not hesitate to reach out to staff if you have questions. Be sure to reach out EARLY to ensure time to work through any issues prior to submission.
                                        </div>
                                    }
                                />
                                <RadioGroup
                                    field="illustration_check"
                                    disabled={is_read_only}
                                    name="illustration_check"
                                    fullWidth
                                    options={[
                                        { label: "Yes", value: "true" },
                                        { label: "No", value: "false" }
                                    ]}
                                    alignment={true}
                                    label="Have you checked to make sure your proposal illustration meets the accessibility requirements?"
                                />
                                <FileInput
                                    field="illustration"
                                    id="illustration"
                                    showLoading={true}
                                    disabled={is_read_only}
                                    fullWidth
                                    eventHandle={() => {
                                        this.setState({ uploadEdit: true });
                                        formApi.submitForm({});
                                    }}
                                >
                                </FileInput>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h3" style={{marginTop:16, marginBottom:8}}>Required Attachments for Non-Goverment Organizations</Typography>
                            </Grid>
                            <Grid item xs={6} lg={4} xl={3}>
                                <FileInput
                                    field="financial_audit"
                                    id="financial_audit"
                                    disabled={is_read_only}
                                    showLoading={true}
                                    label="Most Recent Financial Audit"
                                    fullWidth
                                    eventHandle={() => {
                                        this.setState({ uploadEdit2: true });
                                        formApi.submitForm({});
                                    }}
                                >
                                    <HelpLabel
                                        inputLabel="Most Recent Financial Audit"
                                        showLabel={true}
                                        helpText="For non-government organizations only."
                                    />
                                </FileInput>
                            </Grid>
                            <Grid item xs={6} lg={4} xl={3}>
                                <FileInput
                                    field="board_members"
                                    id="board_members"
                                    showLoading={true}
                                    disabled={is_read_only}
                                    label="List of Board Members"
                                    fullWidth
                                    eventHandle={() => {
                                        this.setState({ uploadEdit3: true });
                                        formApi.submitForm({});
                                    }}
                                >
                                    <HelpLabel
                                        inputLabel="List of Board Members"
                                        showLabel={true}
                                        helpText="For non-government organizations only."
                                    />
                                </FileInput>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h3" style={{marginTop:16, marginBottom:8}}>Optional Attachments</Typography>
                            </Grid>
                            <Grid item xs={12} lg={8} xl={6}>
                                <Typography gutterBottom>
                                    If a map is included in the proposal illustration, do not upload the same map as an additional attachment. 
                                    Do not duplicate previous uploads - i.e. sign up criteria – attached in the parcel tab. 
                                    Do not password protect any uploaded documents. 
                                    PDF format is the preferred format. 
                                    Excel spreadsheets should be converted to pdf prior to uploading.
                                </Typography>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={is_read_only}
                                    onClick={() =>
                                        this.setState({
                                            dialogOpen: true,
                                            attachmentId: null,
                                            key: window.performance.now()
                                        })
                                    }>
                                    <AddToPhotos />&nbsp;&nbsp;&nbsp;Add New Attachment
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Table className={classes.table}>
                                    <EnhancedTableHead columnData={columnData} />
                                    <TableBody>
                                        {attachments.map(n => {
                                            return (
                                                <TableRow hover key={n.id}>
                                                    <CustomTableCell className={classes.nowrap}>
                                                        <Tooltip title="Edit Attachment">
                                                            <Button
                                                                color="primary"
                                                                disabled={is_read_only}
                                                                onClick={() => this.setState({ dialogOpen: true, attachmentId: n }) }
                                                                className={classes.deleteWidth}>
                                                                <CreateIcon color="primary" />
                                                            </Button>
                                                        </Tooltip>
                                                        <Tooltip title="Delete Attachment">
                                                            <Button disabled={is_read_only} color="primary" className={classes.deleteWidth} onClick={() => ormProposalAttachmentDelete(n.id) }>
                                                                <DeleteIcon color="primary" />
                                                            </Button>
                                                        </Tooltip>
                                                    </CustomTableCell>
                                                    <CustomTableCell>{n.name}</CustomTableCell>
                                                    <CustomTableCell>{n.type}</CustomTableCell>
                                                    <CustomTableCell>
                                                        <a href={n.file} target="_blank" rel="noopener noreferrer">
                                                            Open
                                                        </a>
                                                    </CustomTableCell>
                                                </TableRow>
                                            );
                                        })}
                                        {attachments.length < 1 && (
                                            <TableRow>
                                                <CustomTableCell colSpan={4} className={classes.centerAlign}>
                                                    No Optional Attachments Found
                                                </CustomTableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </Grid>
                            <Grid item xs={12} style={{marginTop:16}} className={classes.rightAlign}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={is_read_only}
                                    onClick={() => this.setState({ draftClick: true, submitClicked: true })}
                                    className={classes.button}>
                                    Save Draft and Return to Dashboard
                                </Button>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={is_read_only}
                                    color="primary"
                                    onClick={() => this.setState({ draftClick: false, submitClicked: true })}>
                                    Save and Submit Proposal to LSOHC&nbsp;&nbsp;&nbsp;<CloudUpload />
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Form>
            <AttachmentDialog
                open={dialogOpen}
                ormProposalAttachmentCreate={ormProposalAttachmentCreate}
                ormProposalAttachmentUpdate={ormProposalAttachmentUpdate}
                attachmentId={attachmentId}
                returnDialog={() => this.setState({ dialogOpen: false })}
                classes={classes}
                proposal={proposal}
                formkey={key}
            />
            </>
        );
    }
};

class AttachmentDialog extends Component {
    state = {
        showLoading: false
    };

    addAttachment(values) {
        const {
            returnDialog,
            ormProposalAttachmentCreate,
            ormProposalAttachmentUpdate,
            attachmentId,
            proposal,
        } = this.props;

        if (attachmentId) {
            ormProposalAttachmentUpdate({
                id: attachmentId.id,
                ...values
            });
            returnDialog();
        } else {
            this.setState({showLoading: true});
            ormProposalAttachmentCreate({
                proposal: proposal.id,
                ...values
            }).then(id => {
                this.setState({showLoading: false});
                returnDialog();
            });
        }
    }

    errorValidator = values => {
        const isRequired = val => {
            return !val ? "Required" : null;
        };
        var valObj = {
            file: isRequired(values.file),
            name: isRequired(values.name),
            type: isRequired(values.type)
        };

        return valObj;
    };

    render() {
        const { classes, open, returnDialog, attachmentId, formkey } = this.props;
        const { showLoading } = this.state;

        return (
            <Dialog open={open}>
                <Toolbar>
                    <Typography variant="h3" className={classes.flex}>
                        {!attachmentId ? "Add New" : "Edit"} Attachment
                    </Typography>
                    <IconButton aria-label="Close Dialog" onClick={() => returnDialog()}>
                        <Close />
                    </IconButton>
                </Toolbar>
                <DialogContent>
                    <DialogContentText>
                        <Form
                            dontValidateOnMount={true}
                            key={attachmentId ? attachmentId.id : formkey}
                            validateOnSubmit={true}
                            validateError={this.errorValidator}
                            defaultValues={attachmentId}
                            onSubmit={values => this.addAttachment(values)}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField field="name" label="Name" fullWidth />
                                            <Select field="type" label="Type" options={[
                                                {label: "Letter of Support", value: "Letter of Support"},
                                                {label: "Map", value: "Map"},
                                                {label: "Financial Audits", value: "Financial Audits"},
                                                {label: "Board Members", value: "Board Members"},
                                                {label: "Government Resolution", value: "Government Resolution"},
                                            ]} fullWidth />
                                            <FileInput field="file" id="file" label="Upload File" fullWidth />
                                        </Grid>
                                        {showLoading && <CircularProgress size={30} style={{marginLeft:"auto", marginRight:"auto"}} />}
                                        {!showLoading && (
                                            <>
                                                <Grid item xs={6}>
                                                    <Button fullWidth onClick={() => returnDialog()}>
                                                        Cancel
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Button fullWidth type="submit" variant="contained" color="primary">
                                                        Save
                                                    </Button>
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}

AttachmentTab = connect(
    (state, ownProps) => ({
        attachments: getAttachments(state, ownProps),
        authState: state.auth
    }),
    {
        ...Proposal.actions,
        ...ProposalAttachment.actions
    }
)(AttachmentTab);

export default withStyles(styles)(withRouter(AttachmentTab));
