import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import Tooltip from "@material-ui/core/Tooltip";
import TableBody from "@material-ui/core/TableBody";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Toolbar from "@material-ui/core/Toolbar";
import CreateIcon from "@material-ui/icons/Create";
import CircularProgress from "@material-ui/core/CircularProgress";

import { withStyles } from "@material-ui/core";
import AddToPhotos from "@material-ui/icons/AddToPhotos";
import Close from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";


import { AccomplishmentPlan, AccomplishmentLog } from "../models";

import TextField from "../../common/TextField";
import DatePicker from "../../common/DatePicker";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import CustomTableCell from "../../common/TableCell";
import { createSelector } from "../../common/orm";


const getLogs = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.AccomplishmentLog.filter({ accomplishment: id }).orderBy("id", "desc").toModelArray();
    }
);

const styles = theme => ({
    centerAlign: {
        textAlign: "center"
    },
    button: {
        marginRight: theme.spacing(2)
    },
    table: {
        width: "100%",
        "& tbody tr:nth-child(even)": {
            backgroundColor: "#eef7fa"
        }
    },
    nowrap: {
        whiteSpace: "nowrap"
    },
    deleteWidth: {
        minWidth: 32,
        width: 32,
        height: 32,
        marginRight: 8,
    },
    flex: {
        flex: 1
    },
});

const columnData = [
    {
        id: "actions",
        numeric: false,
        label: "Actions",
        allowSort: false,
        width: "20px"
    },
    { id: "name", numeric: false, label: "Date", allowSort: false },
    { id: "type", numeric: false, label: "Description/Links", allowSort: false },
];

function formatDate(dateString) {
    if (!dateString) {
        return null;
    }
    const d = new Date(dateString);
    return d.toLocaleDateString();
}

class CouncilTab extends Component {
    state = {
        dialogOpen: false,
        key: null,
        attachmentId: null
    };
    componentDidMount() {
        document.title = "Accomplishment Plan: Council Action - Lessard Sams Outdoor Heritage Council";
        this.props.onRef(this);
        const _this = this;
        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted
        setTimeout(function() {
            _this.props.appContainer.current.scrollTop();
        }, 1);
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    render() {
        const { classes, accomplishment_plan, logs, ormAccomplishmentLogCreate, ormAccomplishmentLogDelete, ormAccomplishmentLogUpdate } = this.props;
        const { key, dialogOpen, attachmentId } = this.state;
      
        return (
            <>
                <Grid container spacing={1}>
                    <Grid item xs={12} lg={8} xl={6}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                                this.setState({
                                    dialogOpen: true,
                                    attachmentId: null,
                                    key: window.performance.now()
                                })
                            }>
                            <AddToPhotos />&nbsp;&nbsp;&nbsp;Add New Entry
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Table className={classes.table}>
                            <EnhancedTableHead columnData={columnData} />
                            <TableBody>
                                {logs.map(n => {
                                    return (
                                        <TableRow hover key={n.id}>
                                            <CustomTableCell className={classes.nowrap}>
                                                <Tooltip title="Edit Entry">
                                                    <Button
                                                        color="primary"
                                                        onClick={() => this.setState({ dialogOpen: true, attachmentId: n }) }
                                                        className={classes.deleteWidth}>
                                                        <CreateIcon color="primary" />
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip title="Delete Entry">
                                                    <Button color="primary" className={classes.deleteWidth} onClick={() => ormAccomplishmentLogDelete(n.id) }>
                                                        <DeleteIcon color="primary" />
                                                    </Button>
                                                </Tooltip>
                                            </CustomTableCell>
                                            <CustomTableCell>{formatDate(n.date)}</CustomTableCell>
                                            <CustomTableCell>{n.description}</CustomTableCell>
                                        </TableRow>
                                    );
                                })}
                                {logs.length < 1 && (
                                    <TableRow>
                                        <CustomTableCell colSpan={3} className={classes.centerAlign}>
                                            No Entries Found
                                        </CustomTableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
            <AttachmentDialog
                open={dialogOpen}
                ormAccomplishmentLogCreate={ormAccomplishmentLogCreate}
                ormAccomplishmentLogUpdate={ormAccomplishmentLogUpdate}
                attachmentId={attachmentId}
                returnDialog={() => this.setState({ dialogOpen: false })}
                classes={classes}
                accomplishment_plan={accomplishment_plan}
                formkey={key}
            />
            </>
        );
    }
};

class AttachmentDialog extends Component {
    state = {
        showLoading: false
    };

    addAttachment(values) {
        const {
            returnDialog,
            ormAccomplishmentLogCreate,
            ormAccomplishmentLogUpdate,
            attachmentId,
            accomplishment_plan,
        } = this.props;

        if (attachmentId) {
            ormAccomplishmentLogUpdate({
                id: attachmentId.id,
                ...values
            });
            returnDialog();
        } else {
            this.setState({showLoading: true});
            ormAccomplishmentLogCreate({
                accomplishment: accomplishment_plan.id,
                ...values
            }).then(id => {
                this.setState({showLoading: false});
                returnDialog();
            });
        }
    }

    errorValidator = values => {
        const isRequired = val => {
            return !val ? "Required" : null;
        };
        var valObj = {
            date: isRequired(values.date),
            description: isRequired(values.description),
        };

        return valObj;
    };

    render() {
        const { classes, open, returnDialog, attachmentId, formkey } = this.props;
        const { showLoading } = this.state;

        return (
            <Dialog open={open}>
                <Toolbar>
                    <Typography variant="h3" className={classes.flex}>
                        {!attachmentId ? "Add New" : "Edit"} Entry
                    </Typography>
                    <IconButton aria-label="Close Dialog" onClick={() => returnDialog()}>
                        <Close />
                    </IconButton>
                </Toolbar>
                <DialogContent>
                    <DialogContentText>
                        <Form
                            dontValidateOnMount={true}
                            key={attachmentId ? attachmentId.id : formkey}
                            validateOnSubmit={true}
                            validateError={this.errorValidator}
                            defaultValues={attachmentId}
                            onSubmit={values => this.addAttachment(values)}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <DatePicker field="date" label="Date" />
                                            <TextField field="description" label="Description/Link" fullWidth />
                                        </Grid>
                                        {showLoading && <CircularProgress size={30} style={{marginLeft:"auto", marginRight:"auto"}} />}
                                        {!showLoading && (
                                            <>
                                                <Grid item xs={6}>
                                                    <Button fullWidth onClick={() => returnDialog()}>
                                                        Cancel
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Button fullWidth type="submit" variant="contained" color="primary">
                                                        Save
                                                    </Button>
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}

CouncilTab = connect(
    (state, ownProps) => ({
        logs: getLogs(state, ownProps),
        authState: state.auth
    }),
    {
        ...AccomplishmentPlan.actions,
        ...AccomplishmentLog.actions
    }
)(CouncilTab);

export default withStyles(styles)(withRouter(CouncilTab));
