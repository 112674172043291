import orm, { Model } from "../common/orm";

import { fk } from "redux-orm";
export class InitialLandReporting extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "InitialLandReporting";
    }
}
orm.register(InitialLandReporting);

export class InitialLandReportingLog extends Model {
    static get fields() {
        return {
            accomplishment: fk("InitialLandReporting", "log")
        };
    }
    static get modelName() {
        return "InitialLandReportingLog";
    }
}
orm.register(InitialLandReportingLog);

export class InitialLandReportingView extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "InitialLandReportingView";
    }
    static get isUserData() {
        return false;
    }
}
orm.register(InitialLandReportingView);
