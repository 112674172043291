import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import CheckboxGroup from "../../common/CheckboxGroup";
import { MAKE_OPTIONS } from "../../../api/constants";

import { withStyles } from "@material-ui/core";
import ChevronRight from "@material-ui/icons/ChevronRight";
import AddCircle from "@material-ui/icons/AddCircle";
import EditIcon from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from "@material-ui/core/IconButton";

import { Proposal, ProposalBudget, ProposalBudgetPersonnel, ProposalBudgetCapitalEquipment } from "../models";

import TextField from "../../common/TextField";
import Checkbox from "../../common/Checkbox";
import RadioGroup from "../../common/RadioGroup";
import Subquestion from "../../common/Subquestion";
import WarningDialog from "../../common/WarningDialog";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import CustomTableCell from "../../common/TableCell";
import HelpLabel from "../../common/HelpLabel";
import { createSelector } from "../../common/orm";
import { getValue } from "../../../api/utils";
import { budget_items } from "../../../api/constants";


const getBudgets = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.ProposalBudget.filter({ proposal: id }).orderBy("order").toModelArray()
        .map(budget => ({
            _personnel: budget.budgetpersonnel.all().toRefArray(),
            _capital: budget.budgetcapital.all().toRefArray(),
            ...budget.ref
        }));;
    }
);


const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
});

const numberRender = (rowData) => {
    return rowData ? formatter.format(rowData) : 0
};

const styles = theme => ({
    rightAlign: {
        textAlign: "right"
    },
    button: {
        marginRight: theme.spacing(2)
    },
    breakoutTable: {
        margin: 16,
        "& thead tr":{
            height: 40
        }
    },
    breakoutButton: {
        marginBottom: 16,
        color: "#fff !important"
    },
    checkbox: {
        display: "inline"
    },
    expansionSummaryRoot: {
        backgroundColor: "#eee",
        minHeight: 64,
        borderBottom: "1px dotted"
    },
    summaryRoot: {
        width: "100%",
        margin: "16px 0"
    },
    editIcon: {
        marginLeft: 8,
        marginBottom: -4
    },
    minWidth: {
        minWidth: 600
    },
    dialogMin: {
        minWidth: "400px",
        paddingTop: 0
    },
    flex: {
        flex: 1
    },
    deleteIcon: {
        float: "right"
    },
    table: {
        width: "100%",
        "& tbody tr:nth-child(even)": {
            backgroundColor: "#ffeff2"
        },
        marginBottom:16
    },
    deleteWidth: {
        minWidth: 32,
        width: 32,
        height: 32,
        marginRight: 8,
    },
    centerAlign: {
        textAlign: "center"
    },
    nowrap: {
        whiteSpace: "nowrap"
    },
    totalRow: {
        backgroundColor: "rgb(221, 221, 221) !important",
        borderTop: "3px solid #000",
        height:36
    },
    totalRow2: {
        backgroundColor: "rgb(221, 221, 221) !important",
        height:36
    }
});

class BudgetTab extends Component {
    state = {
        partnershipActive: false,
        showRename: false,
        renameOpen: false,
        deleteOpen: false,
        uploadEdit2: false,
        renaming: null
    };

    componentDidMount() {
        const { handleUnsavedFields, proposal } = this.props;
        this.props.onRef(this);
        document.title = "Proposal: Budget - Lessard Sams Outdoor Heritage Council";
        const _this = this;
        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted
        setTimeout(function() {
            handleUnsavedFields(false);
            _this.props.appContainer.current.scrollTop();
        }, 1);

        if (getValue(proposal, "is_partnership")) {
            this.setState({partnershipActive: true});
        }
        this.formKey = window.performance.now();
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    updateProposal(values, fromStepper) {
        const { id } = this.props.proposal;
        const { ormProposalUpdate, history, handleUnsavedFields, handleNext, proposal, budgets } = this.props;
        const { uploadEdit2 } = this.state;

        if (Number.isInteger(fromStepper)) {
            values.activeStep = fromStepper;
        }

        values.federal_funds_confirmation_document = proposal.federal_funds_confirmation_document;
        values.modify_date_label = proposal.modify_date_label;
        values.signup_criteria = proposal.signup_criteria;
        values.illustration = proposal.illustration;
        values.board_members = proposal.board_members;

        var funds_requested = 0;
        budgets.forEach(function(b) {
            if (b.item === "Grand Total")
                funds_requested += b.funding_request;
        })
        values.funds_requested = funds_requested.toLocaleString();

        ormProposalUpdate({
            id: id,
            ...values
        });

        handleUnsavedFields(false);

        if (!Number.isInteger(fromStepper)) {
            if (!uploadEdit2) {
                if (this.state.draftClick) {
                    history.push("/dashboard/");
                } else {
                    handleNext();
                }
            } else {
                const f = this;
                setTimeout(function() {
                    // FIXME: Call this function after the update has returned from the server with the uploaded URL
                    f.formKey = window.performance.now();
                    f.setState({ uploadEdit2: false });
                }, 2000);
            }
        }
    };

    groupBy = (list, keyGetter) => {
        const map = new Map();
        list.forEach((item) => {
             const key = keyGetter(item);
             const collection = map.get(key);
             if (!collection) {
                 map.set(key, [item]);
             } else {
                 collection.push(item);
             }
        });
        return map;
    };

    returnDialog = () => {
        this.setState({ renameOpen: false });
    };

    togglePartnership = (checked) => {
        const { budgets, ormProposalBudgetUpdate } = this.props;
        this.setState({partnershipActive: checked});
        if (checked) {
            budgets.forEach(function(b) {
                ormProposalBudgetUpdate({
                    id: b.id,
                    partnership: "New Partner 1"
                });
            });
        } else {
            budgets.forEach(function(b) {
                ormProposalBudgetUpdate({
                    id: b.id,
                    partnership: null
                });
            });
        }
    };

    addPartnership = () => {
        const { ormProposalBudgetCreate, proposal, budgets } = this.props;
        const grouped = this.groupBy(budgets, b => b.partnership);
        
        budget_items.forEach(function(item, i) {
            var readonly = false;
            if (item === "Personnel" || item === "Capital Equipment" || item === "Grand Total")
                readonly = true;
            ormProposalBudgetCreate({
                proposal: proposal.id,
                order: i,
                item: item,
                readonly: readonly,
                partnership: "New Partner " + (grouped.size + 1)
            })
        });
    };

    editPartnership = (name) => {
        this.setState({ renameOpen: true, renaming: name });
    };

    deletePartnership = (name) => {
        this.setState({ deleteOpen: true, renaming: name });
    };

    doDeletePartnership = (name) => {
        const { budgets, ormProposalBudgetDelete } = this.props;
        const grouped = this.groupBy(budgets, b => b.partnership);
        const partnershipsToDelete = grouped.get(name);
        partnershipsToDelete.forEach(function(p) {
            ormProposalBudgetDelete(p.id);
        });
        this.setState({ deleteOpen: false });
    };
    
    /*shouldComponentUpdate(nextProps, nextState) {
        console.log(nextState);
        console.log(this.state);
        console.log((nextState !== this.state));
        return (
            nextProps.budgets !== this.props.budgets || 
            //nextState.partnershipActive !== this.state.partnershipActive ||
            //nextState.renameOpen !== this.state.renameOpen ||
            //nextState !== this.state ||
            nextProps.proposal.budget_scale !== this.props.proposal.budget_scale ||
            nextProps.proposal.personnel_past_requested !== this.props.proposal.personnel_past_requested
        );
    };*/

    render() {
        const { 
            classes,
            proposal,
            handleUnsavedFields,
            budgets,
            ormProposalBudgetUpdate,
            ormProposalBudgetUpdateLocalOnly,
            ormProposalBudgetPersonnelCreate,
            ormProposalBudgetPersonnelUpdate,
            ormProposalBudgetPersonnelUpdateLocalOnly,
            ormProposalBudgetPersonnelDelete,
            ormProposalBudgetCapitalEquipmentCreate,
            ormProposalBudgetCapitalEquipmentUpdateLocalOnly,
            ormProposalBudgetCapitalEquipmentUpdate,
            ormProposalBudgetCapitalEquipmentDelete,
            ormProposalUpdateLocalOnly,
            settings, authState
            //ActionMenu
        } = this.props;
        const { partnershipActive, renameOpen, deleteOpen, renaming } = this.state;
        var is_read_only = true;
        if ((authState && authState.user && authState.user.role === "admin") || proposal.status === "New")
            is_read_only = false;
        if (authState && authState.user && authState.user.role === "readonly")
            is_read_only = true;

        const grouped = this.groupBy(budgets, b => b.partnership);
        const groupedItem = this.groupBy(budgets, b => b.item);
        var pships = [];
        if (partnershipActive) {
            budgets.forEach(function(b) {
                if (!pships.includes(b.partnership)) {
                    pships.push(b.partnership);
                }
            });
        }

        return (
            <>
            <Form
                getApi={el => (this.form = el)}
                dontValidateOnMount={true}
                key={this.formKey}
                validateOnSubmit={true}
                defaultValues={proposal.formData}
                formDidUpdate={() => handleUnsavedFields(true)}
                onSubmit={(values, fromStepper) => this.updateProposal(values, fromStepper)}>
                {formApi => (
                    <form onSubmit={formApi.submitForm}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <HelpLabel
                                    inputLabel="Budget"
                                    title={true}
                                    showLabel={true}
                                    helpText={settings.budget_tab_help}
                                />
                                {/*ActionMenu*/}
                            </Grid>
                            <Grid item xs={12}>
                                {partnershipActive && grouped.size > 1 && (
                                    <Tooltip title="Delete all but one partner to remove this partnership">
                                        <span>
                                            <Checkbox
                                                field="is_partnership"
                                                align={classes.checkbox}
                                                label="Partnership"
                                                disabled={true}
                                            >
                                                <HelpLabel
                                                    inputLabel="Partnership"
                                                    helpText="A partnership budget should be included when different organizations are responsible for different activities AND those organizations will have a separate contract and submit separate reimbursements with the DNR that are not through the main manager of the program.  Please call to discuss if you think this applies to you."
                                                />
                                            </Checkbox>
                                        </span>
                                    </Tooltip>
                                )}
                                {(!partnershipActive || grouped.size <= 1) && (
                                    <Checkbox
                                        field="is_partnership"
                                        eventHandle={this.togglePartnership}
                                        disabled={is_read_only} 
                                        align={classes.checkbox}
                                        label="Partnership"
                                    >
                                        <HelpLabel
                                            inputLabel="Partnership"
                                            helpText="A partnership budget should be included when different organizations are responsible for different activities AND those organizations will have a separate contract and submit separate reimbursements with the DNR that are not through the main manager of the program.  Please call to discuss if you think this applies to you."
                                        />
                                    </Checkbox>
                                )}
                                {partnershipActive && (
                                    <Button
                                        variant="contained"
                                        type="button"
                                        color="primary"
                                        disabled={is_read_only} 
                                        onClick={this.addPartnership}>
                                        <AddCircle />&nbsp;&nbsp;&nbsp;Add Additional Partner
                                    </Button>
                                )}
                                {partnershipActive && pships.map(p => {
                                    const groupedBudgetItems = grouped.get(p);
                                    return (
                                        <ExpansionPanel
                                            square
                                            defaultExpanded={false}
                                            classes={{root: classes.summaryRoot}}
                                        >
                                            <ExpansionPanelSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                classes={{
                                                    root: classes.expansionSummaryRoot
                                                }}>
                                                <Typography variant="h3" style={{width:"100%"}}>
                                                    {p}
                                                    <Tooltip title="Rename Partnership" placement="top">
                                                        <EditIcon disabled={is_read_only} onClick={(e) => {e.stopPropagation();this.editPartnership(p)}} className={classes.editIcon}/>
                                                    </Tooltip>
                                                    {grouped.size > 1 && (
                                                        <Tooltip title="Delete Partnership" placement="top">
                                                            <DeleteIcon disabled={is_read_only} onClick={(e) => {e.stopPropagation();this.deletePartnership(p)}} className={classes.deleteIcon}/>
                                                        </Tooltip>
                                                    )}
                                                </Typography>
                                            </ExpansionPanelSummary>
                                            <ExpansionPanelDetails>
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <Typography variant="h3" gutterBottom>
                                                            Personnel - Breakout Details
                                                            <HelpLabel
                                                                inputLabel="Directions for FTE / Salary calculations"
                                                                special={true}
                                                                helpText={
                                                                    <div>
                                                                        In order to consistently calculate the FTE and salary lines, please use the following guideline:<br/><br/>
                                                                        Annual salary for that position <b>divided by</b> percent of time position will spend on OHF work per calendar year times number 
                                                                        of years this position will work on the program = Amount anticipated to be reimbursed by OHF for this position in this program.<br/><br/>
                                                                        Example:   $80,000 (annual salary) / .33 (percent amount of time) = 24,242.42 x 4 (years) = $96,968
                                                                    </div>
                                                                }
                                                            />
                                                            <Button disabled={is_read_only} style={{marginLeft:16}} variant="contained" color="primary" onClick={() => ormProposalBudgetPersonnelCreate({budget: groupedBudgetItems.find(b => b.item === "Personnel").id })}>
                                                                <AddCircle />
                                                                &nbsp;&nbsp;&nbsp;Add Item
                                                            </Button>
                                                        </Typography>
                                                        <PersonnelTable 
                                                            classes={classes} 
                                                            budgets={groupedBudgetItems}
                                                            personnel_row={groupedBudgetItems.find(b => b.item === "Personnel")}
                                                            ormProposalBudgetPersonnelUpdate={ormProposalBudgetPersonnelUpdate}
                                                            ormProposalBudgetPersonnelUpdateLocalOnly={ormProposalBudgetPersonnelUpdateLocalOnly}
                                                            ormProposalBudgetPersonnelDelete={ormProposalBudgetPersonnelDelete}
                                                            ormProposalBudgetUpdate={ormProposalBudgetUpdate}
                                                            is_read_only={is_read_only}
                                                            ormProposalBudgetUpdateLocalOnly={ormProposalBudgetUpdateLocalOnly}
                                                        />
                                                        <Typography variant="h3" gutterBottom>
                                                            Capital Equipment - Breakout Details (for items over $10,000)
                                                            <Button disabled={is_read_only} style={{marginLeft:16}} variant="contained" color="primary" onClick={() => ormProposalBudgetCapitalEquipmentCreate({budget: groupedBudgetItems.find(b => b.item === "Capital Equipment").id })}>
                                                                <AddCircle />
                                                                &nbsp;&nbsp;&nbsp;Add Item
                                                            </Button>
                                                        </Typography>
                                                        <CapitalTable 
                                                            classes={classes} 
                                                            budgets={groupedBudgetItems}
                                                            capital_row={groupedBudgetItems.find(b => b.item === "Capital Equipment")}
                                                            ormProposalBudgetCapitalEquipmentUpdate={ormProposalBudgetCapitalEquipmentUpdate}
                                                            ormProposalBudgetCapitalEquipmentUpdateLocalOnly={ormProposalBudgetCapitalEquipmentUpdateLocalOnly}
                                                            ormProposalBudgetCapitalEquipmentDelete={ormProposalBudgetCapitalEquipmentDelete}
                                                            ormProposalBudgetUpdate={ormProposalBudgetUpdate}
                                                            is_read_only={is_read_only}
                                                            ormProposalBudgetUpdateLocalOnly={ormProposalBudgetUpdateLocalOnly}
                                                        />
                                                        <Typography variant="h3" gutterBottom>
                                                            Main Budget Table
                                                        </Typography>
                                                        <BudgetTable
                                                            ormProposalBudgetUpdate={ormProposalBudgetUpdate}
                                                            ormProposalBudgetUpdateLocalOnly={ormProposalBudgetUpdateLocalOnly}
                                                            rows={groupedBudgetItems}
                                                            is_read_only={is_read_only}
                                                            classes={classes}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                    )
                                })}
                                {!partnershipActive && (
                                    <>
                                    <Typography variant="h3" gutterBottom>
                                        Personnel - Breakout Details
                                        <HelpLabel
                                            inputLabel="Directions for FTE / Salary calculations"
                                            special={true}
                                            helpText={
                                                <div>
                                                    In order to consistently calculate the FTE and salary lines, please use the following guideline:<br/><br/>
                                                    Annual salary for that position <b>divided by</b> percent of time position will spend on OHF work per calendar year times number 
                                                    of years this position will work on the program = Amount anticipated to be reimbursed by OHF for this position in this program.<br/><br/>
                                                    Example:   $80,000 (annual salary) / .33 (percent amount of time) = 24,242.42 x 4 (years) = $96,968
                                                </div>
                                            }
                                        />
                                        <Button disabled={is_read_only} style={{marginLeft:16}} variant="contained" color="primary" onClick={() => ormProposalBudgetPersonnelCreate({budget: budgets.find(b => b.item === "Personnel").id })}>
                                            <AddCircle />
                                            &nbsp;&nbsp;&nbsp;Add Item
                                        </Button>
                                    </Typography>
                                    <PersonnelTable 
                                        classes={classes} 
                                        budgets={budgets}
                                        personnel_row={budgets.find(b => b.item === "Personnel")}
                                        ormProposalBudgetPersonnelUpdate={ormProposalBudgetPersonnelUpdate}
                                        ormProposalBudgetPersonnelUpdateLocalOnly={ormProposalBudgetPersonnelUpdateLocalOnly}
                                        ormProposalBudgetPersonnelDelete={ormProposalBudgetPersonnelDelete}
                                        ormProposalBudgetUpdate={ormProposalBudgetUpdate}
                                        is_read_only={is_read_only}
                                        ormProposalBudgetUpdateLocalOnly={ormProposalBudgetUpdateLocalOnly}
                                    />
                                    <Typography variant="h3" gutterBottom>
                                        Capital Equipment - Breakout Details (for items over $10,000)
                                        <Button disabled={is_read_only} style={{marginLeft:16}} variant="contained" color="primary" onClick={() => ormProposalBudgetCapitalEquipmentCreate({budget: budgets.find(b => b.item === "Capital Equipment").id })}>
                                            <AddCircle />
                                            &nbsp;&nbsp;&nbsp;Add Item
                                        </Button>
                                    </Typography>
                                    <CapitalTable 
                                        classes={classes} 
                                        budgets={budgets}
                                        capital_row={budgets.find(b => b.item === "Capital Equipment")}
                                        ormProposalBudgetCapitalEquipmentUpdate={ormProposalBudgetCapitalEquipmentUpdate}
                                        ormProposalBudgetCapitalEquipmentUpdateLocalOnly={ormProposalBudgetCapitalEquipmentUpdateLocalOnly}
                                        ormProposalBudgetCapitalEquipmentDelete={ormProposalBudgetCapitalEquipmentDelete}
                                        ormProposalBudgetUpdate={ormProposalBudgetUpdate}
                                        is_read_only={is_read_only}
                                        ormProposalBudgetUpdateLocalOnly={ormProposalBudgetUpdateLocalOnly}
                                    />
                                    <Typography variant="h3" gutterBottom>
                                        Main Budget Table
                                    </Typography>
                                    <BudgetTable
                                        ormProposalBudgetUpdate={ormProposalBudgetUpdate}
                                        ormProposalBudgetUpdateLocalOnly={ormProposalBudgetUpdateLocalOnly}
                                        rows={budgets}
                                        is_read_only={is_read_only}
                                        classes={classes}
                                    />
                                    </>
                                )}
                                {partnershipActive && grouped.size > 1 && (
                                    <ExpansionPanel
                                        square
                                        defaultExpanded={true}
                                        classes={{root: classes.summaryRoot}}
                                    >
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            classes={{
                                                root: classes.expansionSummaryRoot
                                            }}>
                                            <Typography variant="h3" style={{width:"100%"}}>Grand Totals Across All Partners</Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <BudgetTableTotal rows={budgets} classes={classes} />
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                )}
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <OtherFields classes={classes} is_read_only={is_read_only} groupedItem={groupedItem} proposal={proposal} ormProposalUpdateLocalOnly={ormProposalUpdateLocalOnly} />
                            <Grid item xs={12} className={classes.rightAlign}>
                                <Button
                                    variant="contained"
                                    disabled={is_read_only}
                                    type="submit"
                                    onClick={() => this.setState({ draftClick: true, submitClicked: true })}
                                    className={classes.button}>
                                    Save Draft and Return to Dashboard
                                </Button>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={is_read_only}
                                    color="primary"
                                    onClick={() => this.setState({ draftClick: false, submitClicked: true })}>
                                    Save and Proceed to Federal Funds <ChevronRight />
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Form>
            <RenamePartnership 
                ormProposalBudgetUpdate={ormProposalBudgetUpdate} 
                budgets={grouped.get(renaming)} 
                classes={classes} 
                returnDialog={this.returnDialog} 
                open={renameOpen} 
                name={renaming} 
            />
            <WarningDialog
                confirmAction={() => this.doDeletePartnership(renaming)}
                cancelAction={() => this.setState({ deleteOpen: false })}
                open={deleteOpen}
                title="Delete Partner"
                confirmText="Delete Partner"
                text={"Are you sure you wish to delete " + renaming + "? Any budget values entered for this partner will be lost."}
            />
            </>
        );
    }
}

class PersonnelTable extends Component {
    columnData = [
        { id: "position", label: "Position", allowSort: false },
        { id: "fte", label: "Annual FTE Equivalent", allowSort: false },
        { id: "years_working", label: "Years Working on Program", allowSort: false },
        { id: "funding_request", label: "Funding Request", allowSort: false },
        { id: "anticipated_leverage", label: "Leverage", allowSort: false },
        { id: "leverage_source", label: "Leverage Source", allowSort: false },
        { id: "total", label: "Total", allowSort: false },
        { id: "actions", numeric: false, label: "Actions", allowSort: false, width: "20px" }
    ];
    typingTimer = [];
    typingTimer2 = [];
    objectsToUpdate = [];
    formkeys = {};

    updateRecord = (value, element, id, record) => {
        const { ormProposalBudgetPersonnelUpdate, ormProposalBudgetPersonnelUpdateLocalOnly, personnel_row, ormProposalBudgetUpdateLocalOnly, ormProposalBudgetUpdate, budgets } = this.props;
        const comp = this;

        // Save each individual value locally
        clearTimeout(this.typingTimer2[id + element]);
        this.typingTimer2[id + element] = setTimeout(function() {

            if (element === "funding_request" || element === "anticipated_leverage")
                value = (Math.round(parseInt(value) / 100) * 100) || 0;
            else if (!value && (element === "fte" || element === "years_working"))
                value = 0;

            var vals = {
                id: id,
                [element]: value
            }
            if (element === "funding_request") {
                if (comp.objectsToUpdate[id] && comp.objectsToUpdate[id]["anticipated_leverage"])
                    vals["total"] = value + comp.objectsToUpdate[id]["anticipated_leverage"];
                else
                    vals["total"] = value + record.anticipated_leverage;
            }
            else if (element === "anticipated_leverage") {
                if (comp.objectsToUpdate[id] && comp.objectsToUpdate[id]["funding_request"])
                    vals["total"] = value + comp.objectsToUpdate[id]["funding_request"];
                else
                    vals["total"] = value + record.funding_request;
            } else {
                vals["total"] = record.funding_request + record.anticipated_leverage;
            }
            ormProposalBudgetPersonnelUpdateLocalOnly(vals);
            comp.objectsToUpdate[id] = { ...comp.objectsToUpdate[id], ...vals };

            if (element !== "funding_request" && element !== "anticipated_leverage" && element !== "leverage_source")
                return;

            var totals = {
                id: personnel_row.id,
                funding_request: 0,
                anticipated_leverage: 0,
                total: 0,
                leverage_source: null
            };
            var leverage_array = [];
            personnel_row._personnel.forEach(function(p) {
                if (p.id === id) {
                    if (element === "funding_request") {
                        totals["funding_request"] += value;
                        if (comp.objectsToUpdate[id] && comp.objectsToUpdate[id]["anticipated_leverage"])
                            totals["anticipated_leverage"] += comp.objectsToUpdate[id]["anticipated_leverage"];
                        else
                            totals["anticipated_leverage"] += (parseInt(p.anticipated_leverage) || 0);
                    }
                    else if (element === "anticipated_leverage") {
                        if (comp.objectsToUpdate[id] && comp.objectsToUpdate[id]["funding_request"])
                            totals["funding_request"] += comp.objectsToUpdate[id]["funding_request"];
                        else
                            totals["funding_request"] += (parseInt(p.funding_request) || 0);
                        totals["anticipated_leverage"] += value;
                    } else {
                        if (comp.objectsToUpdate[id] && comp.objectsToUpdate[id]["funding_request"])
                            totals["funding_request"] += comp.objectsToUpdate[id]["funding_request"];
                        else
                            totals["funding_request"] += (parseInt(p.funding_request) || 0);
                        if (comp.objectsToUpdate[id] && comp.objectsToUpdate[id]["anticipated_leverage"])
                            totals["anticipated_leverage"] += comp.objectsToUpdate[id]["anticipated_leverage"];
                        else
                            totals["anticipated_leverage"] += (parseInt(p.anticipated_leverage) || 0);
                    }
                    totals["total"] += vals["total"];
                    if (comp.objectsToUpdate[id] && comp.objectsToUpdate[id]["leverage_source"])
                        leverage_array.push(comp.objectsToUpdate[id]["leverage_source"]);
                    else
                        leverage_array.push(p.leverage_source);
                } else {
                    totals["funding_request"] += (parseInt(p.funding_request) || 0);
                    totals["anticipated_leverage"] += (parseInt(p.anticipated_leverage) || 0);
                    totals["total"] += (parseInt(p.total) || 0);
                    leverage_array.push(p.leverage_source);
                }
            }); 
            var uniq = [...new Set(leverage_array)];
            totals["leverage_source"] = uniq.filter(n => n).join(", ");              
            
            var grand_totals = {
                id: budgets.find(b => b.item === "Grand Total").id,
                funding_request: totals["funding_request"],
                anticipated_leverage: totals["anticipated_leverage"],
                total: totals["total"]
            };
            budgets.forEach(function(b) {
                if (b.item !== "Personnel" && b.item !== "Grand Total") {
                    grand_totals["funding_request"] += b.funding_request;
                    grand_totals["anticipated_leverage"] += b.anticipated_leverage;
                    grand_totals["total"] += b.total;
                }
            });

            ormProposalBudgetUpdateLocalOnly(totals);
            ormProposalBudgetUpdateLocalOnly(grand_totals);

            // Later properties overwrite earlier properties with the same name.
            comp.objectsToUpdate["parent"] = { ...comp.objectsToUpdate["parent"], ...totals };
            comp.objectsToUpdate["grand_parent"] = { ...comp.objectsToUpdate["grand_parent"], ...grand_totals };
        }, 50);

        // Save the row once finished editing
        clearTimeout(this.typingTimer[id]);
        this.typingTimer[id] = setTimeout(function() {
            ormProposalBudgetPersonnelUpdate(comp.objectsToUpdate[id]);
        }, 2500);

        // Parent Total
        clearTimeout(this.typingTimer["parent"]);
        this.typingTimer["parent"] = setTimeout(function() {
            if (comp.objectsToUpdate["parent"]) {
                ormProposalBudgetUpdate(comp.objectsToUpdate["parent"]);
                ormProposalBudgetUpdate(comp.objectsToUpdate["grand_parent"]);
            }
        }, 2500);
    };

    recalculateTotal = (deleteId) => {
        const { ormProposalBudgetUpdate, budgets, personnel_row, ormProposalBudgetPersonnelDelete } = this.props;

        var totals = {
            id: personnel_row.id,
            funding_request: 0,
            anticipated_leverage: 0,
            total: 0,
            leverage_source: null
        };
        var leverage_array = [];

        personnel_row._personnel.forEach(function(p) {
            if (p.id !== deleteId) {
                totals["funding_request"] += (parseInt(p.funding_request) || 0);
                totals["anticipated_leverage"] += (parseInt(p.anticipated_leverage) || 0);
                totals["total"] += (parseInt(p.total) || 0);
                leverage_array.push(p.leverage_source);
            }
        });            
        var uniq = [...new Set(leverage_array)];
        totals["leverage_source"] = uniq.filter(n => n).join(", ");
        
        var grand_totals = {
            id: budgets.find(b => b.item === "Grand Total").id,
            funding_request: totals["funding_request"],
            anticipated_leverage: totals["anticipated_leverage"],
            total: totals["total"]
        };
        budgets.forEach(function(b) {
            if (b.item !== "Personnel" && b.item !== "Grand Total") {
                grand_totals["funding_request"] += b.funding_request;
                grand_totals["anticipated_leverage"] += b.anticipated_leverage;
                grand_totals["total"] += b.total;
            }
        });

        ormProposalBudgetPersonnelDelete(deleteId);
        ormProposalBudgetUpdate(totals);
        ormProposalBudgetUpdate(grand_totals);
    }

    render() {
        const { classes, personnel_row, is_read_only } = this.props;

        return (
            <Table className={classes.table}>
                <EnhancedTableHead columnData={this.columnData} />
                <TableBody>
                    {personnel_row && personnel_row._personnel.map(n => {
                        return (
                            <TableRow hover key={n.id}>
                                <CustomTableCell>
                                    <Form key={n.id + "position"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={is_read_only} tableFormat eventHandleProps1={n.id} eventHandleProps2={n} eventHandle={this.updateRecord} field="position" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form key={n.id + "fte"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={is_read_only} tableFormat useAcres eventHandleProps1={n.id} eventHandleProps2={n} eventHandle={this.updateRecord} field="fte" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form key={n.id + "years_working"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={is_read_only} tableFormat useAcres eventHandleProps1={n.id} eventHandleProps2={n} eventHandle={this.updateRecord} field="years_working" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form key={this.formkeys[n.id + "funding_request"] ? this.formkeys[n.id + "funding_request"]  : n.id + "funding_request"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={is_read_only} onBlur={this.updateForm}
                                                    eventHandleProps1={n.id} eventHandleProps2={n}
                                                    tableFormat useTextFormat eventHandle={this.updateRecord} field="funding_request" label="" fullWidth 
                                                />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form key={this.formkeys[n.id + "anticipated_leverage"] ? this.formkeys[n.id + "anticipated_leverage"]  : n.id + "anticipated_leverage"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={is_read_only} onBlur={this.updateForm}
                                                    eventHandleProps1={n.id} eventHandleProps2={n}
                                                    tableFormat useTextFormat eventHandle={this.updateRecord} field="anticipated_leverage" label="" fullWidth 
                                                />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form key={n.id + "leverage_source"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={is_read_only} tableFormat eventHandleProps1={n.id} eventHandle={this.updateRecord} eventHandleProps2={n} field="leverage_source" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    {numberRender(n.total)}
                                </CustomTableCell>
                                <CustomTableCell className={classes.nowrap}>
                                    <Tooltip title="Delete Record">
                                        <Button
                                            color="primary"
                                            disabled={is_read_only}
                                            className={classes.deleteWidth}
                                            onClick={() => this.recalculateTotal(n.id)}>
                                            <DeleteIcon color="primary" />
                                        </Button>
                                    </Tooltip>
                                </CustomTableCell>
                            </TableRow>
                        );
                    })}
                    {(!personnel_row || personnel_row._personnel < 1) && (
                        <TableRow className={classes.totalRow2}>
                            <CustomTableCell colSpan={this.columnData.length} className={classes.centerAlign}>
                                No Records Found
                            </CustomTableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        );
    }
}

class CapitalTable extends Component {
    columnData = [
        { id: "position", label: "Item", allowSort: false },
        { id: "funding_request", label: "Funding Request", allowSort: false },
        { id: "anticipated_leverage", label: "Leverage", allowSort: false },
        { id: "leverage_source", label: "Leverage Source", allowSort: false },
        { id: "total", label: "Total", allowSort: false },
        { id: "actions", numeric: false, label: "Actions", allowSort: false, width: "20px" }
    ];
    typingTimer = [];
    typingTimer2 = [];
    objectsToUpdate = [];
    formkeys = {};

    updateRecord = (value, element, id, record) => {
        const { ormProposalBudgetCapitalEquipmentUpdate, ormProposalBudgetCapitalEquipmentUpdateLocalOnly, capital_row, ormProposalBudgetUpdateLocalOnly, ormProposalBudgetUpdate, budgets } = this.props;
        const comp = this;

        // Save each individual value locally
        clearTimeout(this.typingTimer2[id + element]);
        this.typingTimer2[id + element] = setTimeout(function() {

            if (element === "funding_request" || element === "anticipated_leverage")
                value = (Math.round(parseInt(value) / 100) * 100) || 0;

            var vals = {
                id: id,
                [element]: value
            }
            if (element === "funding_request") {
                if (comp.objectsToUpdate[id] && comp.objectsToUpdate[id]["anticipated_leverage"])
                    vals["total"] = value + comp.objectsToUpdate[id]["anticipated_leverage"];
                else
                    vals["total"] = value + record.anticipated_leverage;
            }
            else if (element === "anticipated_leverage") {
                if (comp.objectsToUpdate[id] && comp.objectsToUpdate[id]["funding_request"])
                    vals["total"] = value + comp.objectsToUpdate[id]["funding_request"];
                else
                    vals["total"] = value + record.funding_request;
            } else {
                vals["total"] = record.funding_request + record.anticipated_leverage;
            }
            ormProposalBudgetCapitalEquipmentUpdateLocalOnly(vals);
            comp.objectsToUpdate[id] = { ...comp.objectsToUpdate[id], ...vals };

            if (element !== "funding_request" && element !== "anticipated_leverage" && element !== "leverage_source")
                return;

            var totals = {
                id: capital_row.id,
                funding_request: 0,
                anticipated_leverage: 0,
                total: 0,
                leverage_source: null
            };
            var leverage_array = [];
            capital_row._capital.forEach(function(p) {
                if (p.id === id) {
                    if (element === "funding_request") {
                        totals["funding_request"] += value;
                        if (comp.objectsToUpdate[id] && comp.objectsToUpdate[id]["anticipated_leverage"])
                            totals["anticipated_leverage"] += comp.objectsToUpdate[id]["anticipated_leverage"];
                        else
                            totals["anticipated_leverage"] += (parseInt(p.anticipated_leverage) || 0);
                    }
                    else if (element === "anticipated_leverage") {
                        if (comp.objectsToUpdate[id] && comp.objectsToUpdate[id]["funding_request"])
                            totals["funding_request"] += comp.objectsToUpdate[id]["funding_request"];
                        else
                            totals["funding_request"] += (parseInt(p.funding_request) || 0);
                        totals["anticipated_leverage"] += value;
                    } else {
                        if (comp.objectsToUpdate[id] && comp.objectsToUpdate[id]["funding_request"])
                            totals["funding_request"] += comp.objectsToUpdate[id]["funding_request"];
                        else
                            totals["funding_request"] += (parseInt(p.funding_request) || 0);
                        if (comp.objectsToUpdate[id] && comp.objectsToUpdate[id]["anticipated_leverage"])
                            totals["anticipated_leverage"] += comp.objectsToUpdate[id]["anticipated_leverage"];
                        else
                            totals["anticipated_leverage"] += (parseInt(p.anticipated_leverage) || 0);
                    }
                    totals["total"] += vals["total"];                    
                    if (comp.objectsToUpdate[id] && comp.objectsToUpdate[id]["leverage_source"])
                        leverage_array.push(comp.objectsToUpdate[id]["leverage_source"]);
                    else
                        leverage_array.push(p.leverage_source);
                } else {
                    totals["funding_request"] += (parseInt(p.funding_request) || 0);
                    totals["anticipated_leverage"] += (parseInt(p.anticipated_leverage) || 0);
                    totals["total"] += (parseInt(p.total) || 0);
                    leverage_array.push(p.leverage_source);
                }
            }); 
            var uniq = [...new Set(leverage_array)];
            totals["leverage_source"] = uniq.filter(n => n).join(", ");  
            
            var grand_totals = {
                id: budgets.find(b => b.item === "Grand Total").id,
                funding_request: totals["funding_request"],
                anticipated_leverage: totals["anticipated_leverage"],
                total: totals["total"]
            };
            budgets.forEach(function(b) {
                if (b.item !== "Capital Equipment" && b.item !== "Grand Total") {
                    grand_totals["funding_request"] += b.funding_request;
                    grand_totals["anticipated_leverage"] += b.anticipated_leverage;
                    grand_totals["total"] += b.total;
                }
            });

            ormProposalBudgetUpdateLocalOnly(totals);
            ormProposalBudgetUpdateLocalOnly(grand_totals);

            // Later properties overwrite earlier properties with the same name.
            comp.objectsToUpdate["parent"] = { ...comp.objectsToUpdate["parent"], ...totals };
            comp.objectsToUpdate["grand_parent"] = { ...comp.objectsToUpdate["grand_parent"], ...grand_totals };
        }, 50);

        // Save the row once finished editing
        clearTimeout(this.typingTimer[id]);
        this.typingTimer[id] = setTimeout(function() {
            ormProposalBudgetCapitalEquipmentUpdate(comp.objectsToUpdate[id]);
        }, 2500);

        // Parent Total
        clearTimeout(this.typingTimer["parent"]);
        this.typingTimer["parent"] = setTimeout(function() {
            if (comp.objectsToUpdate["parent"]) {
                ormProposalBudgetUpdate(comp.objectsToUpdate["parent"]);
                ormProposalBudgetUpdate(comp.objectsToUpdate["grand_parent"]);
            }
        }, 2500);
    };

    recalculateTotal = (deleteId) => {
        const { ormProposalBudgetUpdate, budgets, capital_row, ormProposalBudgetCapitalEquipmentDelete } = this.props;

        var totals = {
            id: capital_row.id,
            funding_request: 0,
            anticipated_leverage: 0,
            total: 0,
            leverage_source: null
        };
        var leverage_array = [];
        capital_row._capital.forEach(function(p) {
            if (p.id !== deleteId) {
                totals["funding_request"] += (parseInt(p.funding_request) || 0);
                totals["anticipated_leverage"] += (parseInt(p.anticipated_leverage) || 0);
                totals["total"] += (parseInt(p.total) || 0);
                leverage_array.push(p.leverage_source);
            }
        });       
        var uniq = [...new Set(leverage_array)];
        totals["leverage_source"] = uniq.filter(n => n).join(", ");  
        
        var grand_totals = {
            id: budgets.find(b => b.item === "Grand Total").id,
            funding_request: totals["funding_request"],
            anticipated_leverage: totals["anticipated_leverage"],
            total: totals["total"]
        };
        budgets.forEach(function(b) {
            if (b.item !== "Capital Equipment" && b.item !== "Grand Total") {
                grand_totals["funding_request"] += b.funding_request;
                grand_totals["anticipated_leverage"] += b.anticipated_leverage;
                grand_totals["total"] += b.total;
            }
        });

        ormProposalBudgetCapitalEquipmentDelete(deleteId);
        ormProposalBudgetUpdate(totals);
        ormProposalBudgetUpdate(grand_totals);
    };

    render() {
        const { classes, capital_row, is_read_only } = this.props;

        return (
            <Table className={classes.table}>
                <EnhancedTableHead columnData={this.columnData} />
                <TableBody>
                    {capital_row && capital_row._capital.map(n => {
                        return (
                            <TableRow hover key={n.id}>
                                <CustomTableCell>
                                    <Form key={n.id + "position"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={is_read_only} tableFormat eventHandleProps1={n.id} eventHandleProps2={n} eventHandle={this.updateRecord} field="position" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form key={this.formkeys[n.id + "funding_request"] ? this.formkeys[n.id + "funding_request"]  : n.id + "funding_request"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={is_read_only} onBlur={this.updateForm}
                                                    eventHandleProps1={n.id} eventHandleProps2={n}
                                                    tableFormat useTextFormat eventHandle={this.updateRecord} field="funding_request" label="" fullWidth 
                                                />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form key={this.formkeys[n.id + "anticipated_leverage"] ? this.formkeys[n.id + "anticipated_leverage"]  : n.id + "anticipated_leverage"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={is_read_only} onBlur={this.updateForm}
                                                    eventHandleProps1={n.id} eventHandleProps2={n}
                                                    tableFormat useTextFormat eventHandle={this.updateRecord} field="anticipated_leverage" label="" fullWidth 
                                                />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form key={n.id + "leverage_source"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={is_read_only} tableFormat eventHandleProps1={n.id} eventHandle={this.updateRecord} eventHandleProps2={n} field="leverage_source" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    {numberRender(n.total)}
                                </CustomTableCell>
                                <CustomTableCell className={classes.nowrap}>
                                    <Tooltip title="Delete Record">
                                        <Button
                                            color="primary"
                                            disabled={is_read_only}
                                            className={classes.deleteWidth}
                                            onClick={() => this.recalculateTotal(n.id)}>
                                            <DeleteIcon color="primary" />
                                        </Button>
                                    </Tooltip>
                                </CustomTableCell>
                            </TableRow>
                        );
                    })}
                    {(!capital_row || capital_row._capital < 1) && (
                        <TableRow className={classes.totalRow2}>
                            <CustomTableCell colSpan={this.columnData.length} className={classes.centerAlign}>
                                No Records Found
                            </CustomTableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        );
    }
}

class BudgetTable extends Component {
    columnData = [
        { id: "item", label: "Item", allowSort: false },
        { id: "funding_request", label: "Funding Request", allowSort: false },
        { id: "anticipated_leverage", label: "Leverage", allowSort: false },
        { id: "leverage_source", label: "Leverage Source", allowSort: false },
        { id: "total", label: "Total", type: "numeric", allowSort: false },
    ];
    typingTimer = [];
    typingTimer2 = [];
    objectsToUpdate = [];
    formkeys = {};

    updateRecord = (value, element, id, record) => {
        const { ormProposalBudgetUpdate, ormProposalBudgetUpdateLocalOnly, rows } = this.props;
        const comp = this;
        var row = rows.find(r => r.item === "Grand Total");

        // Save each individual value locally
        clearTimeout(this.typingTimer2[id + element]);
        this.typingTimer2[id + element] = setTimeout(function() {

            if (element === "funding_request" || element === "anticipated_leverage")
                value = (Math.round(parseInt(value) / 100) * 100) || 0;

            var vals = {
                id: id,
                [element]: value
            }
            if (element === "funding_request") {
                if (comp.objectsToUpdate[id] && comp.objectsToUpdate[id]["anticipated_leverage"])
                    vals["total"] = value + comp.objectsToUpdate[id]["anticipated_leverage"];
                else
                    vals["total"] = value + record.anticipated_leverage;
            }
            else if (element === "anticipated_leverage") {
                if (comp.objectsToUpdate[id] && comp.objectsToUpdate[id]["funding_request"])
                    vals["total"] = value + comp.objectsToUpdate[id]["funding_request"];
                else
                    vals["total"] = value + record.funding_request;
            }
            ormProposalBudgetUpdateLocalOnly(vals);
            comp.objectsToUpdate[id] = { ...comp.objectsToUpdate[id], ...vals };

            if (element === "leverage_source")
                return;

            var totals = {
                id: row.id,
                funding_request: 0,
                anticipated_leverage: 0,
                total: 0
            };
            rows.forEach(function(p) {
                if (p.id !== row.id) {
                    if (p.id === id) {
                        if (element === "funding_request") {
                            totals["funding_request"] += value;
                            if (comp.objectsToUpdate[id] && comp.objectsToUpdate[id]["anticipated_leverage"])
                                totals["anticipated_leverage"] += comp.objectsToUpdate[id]["anticipated_leverage"];
                            else
                                totals["anticipated_leverage"] += (parseInt(p.anticipated_leverage) || 0);
                        }
                        else if (element === "anticipated_leverage") {
                            if (comp.objectsToUpdate[id] && comp.objectsToUpdate[id]["funding_request"])
                                totals["funding_request"] += comp.objectsToUpdate[id]["funding_request"];
                            else
                                totals["funding_request"] += (parseInt(p.funding_request) || 0);
                            totals["anticipated_leverage"] += value;
                        }
                        totals["total"] += vals["total"];
                    } else {
                        totals["funding_request"] += (parseInt(p.funding_request) || 0);
                        totals["anticipated_leverage"] += (parseInt(p.anticipated_leverage) || 0);
                        totals["total"] += (parseInt(p.total) || 0);
                    }
                }
            });

            ormProposalBudgetUpdateLocalOnly(totals);
            // Later properties overwrite earlier properties with the same name.
            comp.objectsToUpdate[row.id] = { ...comp.objectsToUpdate[row.id], ...totals };
        }, 50);

        // Save the row once finished editing
        clearTimeout(this.typingTimer[id]);
        this.typingTimer[id] = setTimeout(function() {
            ormProposalBudgetUpdate(comp.objectsToUpdate[id]);
        }, 2500);

        // Grand Total
        clearTimeout(this.typingTimer[row.id]);
        this.typingTimer[row.id] = setTimeout(function() {
            if (comp.objectsToUpdate[row.id])
                ormProposalBudgetUpdate(comp.objectsToUpdate[row.id]);
        }, 2500);
    };

    render() {
        const { rows, classes, is_read_only } = this.props;
        
        return (
            <Table className={classes.table}>
                <EnhancedTableHead columnData={this.columnData} />
                <TableBody>
                    {rows.map(n => {
                        if (n.item === "Grand Total") {
                            return (
                                <TableRow className={classes.totalRow}>
                                    <CustomTableCell><b>Grand Total</b></CustomTableCell>
                                    <CustomTableCell><b>{numberRender(n.funding_request)}</b></CustomTableCell>
                                    <CustomTableCell><b>{numberRender(n.anticipated_leverage)}</b></CustomTableCell>
                                    <CustomTableCell></CustomTableCell>
                                    <CustomTableCell><b>{numberRender(n.total)}</b></CustomTableCell>
                                </TableRow>
                            )
                        }
                        if (n.item === "Personnel" || n.item === "Capital Equipment") {
                            return (
                                <TableRow className={classes.totalRow2}>
                                    <CustomTableCell>{n.item}</CustomTableCell>
                                    <CustomTableCell>{numberRender(n.funding_request)}</CustomTableCell>
                                    <CustomTableCell>{numberRender(n.anticipated_leverage)}</CustomTableCell>
                                    <CustomTableCell>{n.leverage_source}</CustomTableCell>
                                    <CustomTableCell>{numberRender(n.total)}</CustomTableCell>
                                </TableRow>
                            )
                        }
                        return (
                            <TableRow hover key={n.id}>
                                <CustomTableCell>
                                    {n.item}
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form key={this.formkeys[n.id + "funding_request"] ? this.formkeys[n.id + "funding_request"]  : n.id + "funding_request"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={is_read_only} onBlur={this.updateForm}
                                                    eventHandleProps1={n.id} eventHandleProps2={n}
                                                    tableFormat useTextFormat eventHandle={this.updateRecord} field="funding_request" label="" fullWidth 
                                                />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form key={this.formkeys[n.id + "anticipated_leverage"] ? this.formkeys[n.id + "anticipated_leverage"]  : n.id + "anticipated_leverage"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={is_read_only} onBlur={this.updateForm}
                                                    eventHandleProps1={n.id} eventHandleProps2={n}
                                                    tableFormat useTextFormat eventHandle={this.updateRecord} field="anticipated_leverage" label="" fullWidth 
                                                />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form key={n.id + "leverage_source"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={is_read_only} tableFormat eventHandleProps1={n.id} eventHandle={this.updateRecord} field="leverage_source" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    {numberRender(n.total)}
                                </CustomTableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        );
    }
}

class BudgetTableTotal extends Component {
    columnData = [
        { id: "item", label: "Item", allowSort: false },
        { id: "funding_request", label: "Funding Request", allowSort: false },
        { id: "anticipated_leverage", label: "Leverage", allowSort: false },
        { id: "leverage_source", label: "Leverage Source", allowSort: false },
        { id: "total", label: "Total", allowSort: false },
    ];
    
    shouldComponentUpdate(nextProps) {
        return nextProps.rows !== this.props.rows;
    };

    render() {
        const { classes, rows } = this.props;

        var groupedRows = [];
        budget_items.forEach(function(b) {
            groupedRows.push({item: b, funding_request: 0, anticipated_leverage: 0, leverage_source: null, total: 0});
        });
        rows.forEach(function(r, i) {
            var total = groupedRows.find(gr => gr.item === r.item); 
            total["funding_request"] += r["funding_request"]; 
            total["anticipated_leverage"] += r["anticipated_leverage"]; 
            total["total"] += r["total"];
        });

        return (
            <Table className={classes.table}>
                <EnhancedTableHead columnData={this.columnData} />
                <TableBody>
                    {groupedRows.map(n => {
                        return (
                            <TableRow className={classes.totalRow2}>
                                <CustomTableCell> {n.item} </CustomTableCell>
                                <CustomTableCell> {numberRender(n.funding_request)} </CustomTableCell>
                                <CustomTableCell> {numberRender(n.anticipated_leverage)} </CustomTableCell>
                                <CustomTableCell></CustomTableCell>
                                <CustomTableCell> {numberRender(n.total)} </CustomTableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        );
    }
}

class RenamePartnership extends Component {
    state = {
        loading: false
    };
    rename = (values) => {
        const { budgets, ormProposalBudgetUpdate, returnDialog } = this.props;
        this.setState({loading: true}, function() {
            budgets.forEach(function(b) {
                ormProposalBudgetUpdate({
                    id: b.id,
                    partnership: values.name
                });
            });
            returnDialog();
            this.setState({loading: false});
        });
    }

    errorValidator = values => {
        const isRequired = val => {
            return !val ? "Required" : null;
        };
        var valObj = {
            name: isRequired(values.name)
        };

        return valObj;
    };

    render() {
        const { classes, open, returnDialog, name } = this.props;
        const { loading } = this.state;
        return (
            <Dialog open={open} classes={{ paper: classes.minWidth }}>
                <Toolbar>
                    <Typography variant="h3" className={classes.flex}>
                        Rename Partner
                    </Typography>
                    <IconButton aria-label="Close Dialog" onClick={() => returnDialog()}>
                        <Close />
                    </IconButton>
                </Toolbar>
                <DialogContent className={classes.dialogMin}>
                    <DialogContentText>
                        <Form
                            dontValidateOnMount={true}
                            validateOnSubmit={true}
                            validateError={this.errorValidator}
                            defaultValues={{name: name}}
                            onSubmit={values => this.rename(values)}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={0}>
                                        <Grid item xs={12}>
                                            <TextField
                                                field="name"
                                                label="Partner Name"
                                                autoFocus
                                                fullWidth
                                            />
                                            <br/><br/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {loading && (
                                                <CircularProgress size={24} />
                                            )}
                                            {!loading && (
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary">
                                                    Save
                                                </Button>
                                            )}
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}

class OtherFields extends Component {
    columnData = [
        { id: "leverage", label: "Leverage", allowSort: false },
        { id: "amount_confirmed", label: "Amount Confirmed", allowSort: false },
        { id: "percent_1", label: "% of Total Leverage", allowSort: false },
        { id: "amount_anticipated", label: "Amount Anticipated", allowSort: false },
        { id: "percent_2", label: "% of Total Leverage", allowSort: false },
    ];

    applyOther = (value, element) => {
        const { ormProposalUpdateLocalOnly } = this.props;
        const { id } = this.props.proposal;
        ormProposalUpdateLocalOnly({
            id: id,
            [element]: value
        });
        this.setState({ foo: "bar" });
    };

    render() {
        const { proposal, groupedItem, is_read_only, classes } = this.props;

        const year = parseInt(proposal.ml_year);
        const budget_scale_NO = getValue(proposal, "budget_scale") === "false" || getValue(proposal, "budget_scale") === false;
        const budget_scale_YES = getValue(proposal, "budget_scale") === "true" || getValue(proposal, "budget_scale") === true;
        const professional_services_YES = getValue(proposal, "professional_services").indexOf("Other") > -1;
        const personnel_past_requested_YES = getValue(proposal, "personnel_past_requested") === "true" || getValue(proposal, "personnel_past_requested") === true;

        var has_contracts = false;
        groupedItem.get("Contracts").forEach(function(gi) {
            if (gi.funding_request > 0)
                has_contracts = true;
        });        
        var has_professional = false;
        groupedItem.get("Professional Services").forEach(function(gi) {
            if (gi.funding_request > 0)
                has_professional = true;
        });
        var has_fee = false;
        groupedItem.get("Fee Acquisition w/ PILT").forEach(function(gi) {
            if (gi.funding_request > 0)
                has_fee = true;
        });
        groupedItem.get("Fee Acquisition w/o PILT").forEach(function(gi) {
            if (gi.funding_request > 0)
                has_fee = true;
        });
        var has_easement_stewardship = false;
        groupedItem.get("Easement Stewardship").forEach(function(gi) {
            if (gi.funding_request > 0)
                has_easement_stewardship = true;
        });
        var has_travel = false;
        groupedItem.get("Travel").forEach(function(gi) {
            if (gi.funding_request > 0)
                has_travel = true;
        });
        var has_direct_support_services = false;
        groupedItem.get("Direct Support Services").forEach(function(gi) {
            if (gi.funding_request > 0)
                has_direct_support_services = true;
        });
        var has_equipment = false;
        groupedItem.get("Other Equipment/Tools").forEach(function(gi) {
            if (gi.funding_request > 0)
                has_equipment = true;
        });
        var has_personnel = false;
        groupedItem.get("Personnel").forEach(function(gi) {
            if (gi.funding_request > 0)
                has_personnel = true;
        });
        var total_leverage = 0;
        groupedItem.get("Grand Total").forEach(function(gi) {
            if (gi.anticipated_leverage > 0)
                total_leverage += gi.anticipated_leverage;
        });
        var has_leverage = false;
        groupedItem.forEach(function(gi) {
            gi.forEach(function(gi2) { 
                if (gi2.anticipated_leverage > 0) {
                    has_leverage = true;
                }
            });
        });
        var amount_confirmed, percent_1, amount_anticipated, percent_2; 
        if (total_leverage > 0) {
            amount_confirmed = getValue(proposal, "amount_confirmed");
            percent_1 = Math.round((parseInt(amount_confirmed) / total_leverage) * 10000) / 100 || 0;
            amount_anticipated = total_leverage - parseInt(amount_confirmed);
            percent_2 = Math.round((parseInt(amount_anticipated) / total_leverage) * 10000) / 100 || 0;
        }

        return (
            <Grid item xs={12} lg={10} xl={7}>
                {year >= 2024 && has_leverage && (
                    <Table className={classes.table}>
                        <EnhancedTableHead columnData={this.columnData} />
                        <TableBody>
                            <TableRow>
                                <CustomTableCell>
                                    {numberRender(total_leverage)}
                                </CustomTableCell>
                                <CustomTableCell>
                                    <TextField 
                                        disabled={is_read_only} useTextFormat style={{marginTop:0}}
                                        eventHandle={this.applyOther} field="amount_confirmed" label="Of the leverage in the budget table, how much is currently confirmed?" fullWidth 
                                    />
                                </CustomTableCell>
                                <CustomTableCell>
                                    {percent_1}%
                                </CustomTableCell>
                                <CustomTableCell>
                                    {numberRender(amount_anticipated)}
                                </CustomTableCell>
                                <CustomTableCell>
                                    {percent_2}%
                                </CustomTableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                )}
                {year >= 2017 && has_leverage && (
                    <TextField
                        field="explain_leverage"
                        label={
                            year >= 2024 ? "Detail leverage sources and confirmation of funds:" :
                            "Describe and explain leverage source and confirmation of funds."
                        }
                        maxWords={!is_read_only && "50"}
                        multiline 
                        disabled={is_read_only}
                        rows={4}
                        rowsMax={20}
                        fullWidth
                    />
                )}
                {year >= 2017 && (
                    <RadioGroup
                        field="budget_scale"
                        name="budget_scale"
                        disabled={is_read_only}
                        eventHandle={this.applyOther}
                        fullWidth
                        options={[
                            { label: "Yes (Explain)", value: "true" },
                            { label: "No (Explain)", value: "false" }
                        ]}
                        alignment={true}
                        label="Does this proposal have the ability to be scalable?"
                    />
                )}
                {year >= 2017 && year <= 2021 && budget_scale_YES && (
                    <Subquestion component={
                        <TextField
                            field="budget_scale_yes"
                            label="Tell us how this project would be scaled and how administrative costs are affected, describe the “economy of scale” and how outputs would change with reduced funding, if applicable."
                            maxWords={!is_read_only && "50"}
                            disabled={is_read_only}
                            multiline
                            rows={4}
                            rowsMax={20}
                            fullWidth
                        />  
                    } />                                               
                )}
                {year >= 2022 && budget_scale_YES && (
                    <Typography variant="h3">
                        {year >= 2024 ? (
                            "If the project received 50% of the requested funding"
                        ) : (
                            "If the project received 70% of the requested funding"
                        )}
                    </Typography>                                               
                )}
                {year >= 2022 && budget_scale_YES && (
                    <Subquestion component={
                        <TextField
                            field="budget_scale_yes_acres"
                            label="Describe how the scaling would affect acres/activities and if not proportionately reduced, why?"
                            maxWords={!is_read_only && "50"}
                            disabled={is_read_only}
                            multiline
                            rows={4}
                            rowsMax={20}
                            fullWidth
                        />  
                    } />                                               
                )}
                {year >= 2022 && budget_scale_YES && (
                    <Subquestion component={
                        <TextField
                            field="budget_scale_yes_personnel"
                            label="Describe how personnel and DSS expenses would be adjusted and if not proportionately reduced, why?"
                            maxWords={!is_read_only && "50"}
                            disabled={is_read_only}
                            multiline
                            rows={4}
                            rowsMax={20}
                            fullWidth
                        />  
                    } />                                               
                )}
                {year >= 2022 && budget_scale_YES && (
                    <Typography variant="h3">
                        {year >= 2024 ? (
                            "If the project received 30% of the requested funding"
                        ) : (
                            "If the project received 50% of the requested funding"
                        )}
                    </Typography>                                               
                )}
                {year >= 2022 && budget_scale_YES && (
                    <Subquestion component={
                        <TextField
                            field="budget_scale_yes_acres_50"
                            label="Describe how the scaling would affect acres/activities and if not proportionately reduced, why?"
                            maxWords={!is_read_only && "50"}
                            disabled={is_read_only}
                            multiline
                            rows={4}
                            rowsMax={20}
                            fullWidth
                        />  
                    } />                                               
                )}
                {year >= 2022 && budget_scale_YES && (
                    <Subquestion component={
                        <TextField
                            field="budget_scale_yes_personnel_50"
                            label="Describe how personnel and DSS expenses would be adjusted and if not proportionately reduced, why?"
                            maxWords={!is_read_only && "50"}
                            disabled={is_read_only}
                            multiline
                            rows={4}
                            rowsMax={20}
                            fullWidth
                        />  
                    } />                                               
                )}
                {year >= 2017 && budget_scale_NO && (
                    <Subquestion component={
                        <TextField
                            field="budget_scale_no"
                            label="Please explain why this project can NOT be scaled."
                            maxWords={!is_read_only && "50"}
                            disabled={is_read_only}
                            multiline
                            rows={4}
                            rowsMax={20}
                            fullWidth
                        />  
                    } />   
                )}
                {year >= 2021 && has_personnel && (
                    <>
                    <Typography variant="h3">
                        Personnel
                    </Typography>
                    <Subquestion component={
                        <>
                        <RadioGroup
                            field="personnel_past_requested"
                            name="personnel_past_requested"
                            eventHandle={this.applyOther}
                            disabled={is_read_only}
                            fullWidth
                            options={[
                                { label: "Yes (Explain)", value: "true" },
                                { label: "No", value: "false" }
                            ]}
                            alignment={true}
                            label="Has funding for these positions been requested in the past?"
                        />
                        {personnel_past_requested_YES && (
                            <Subquestion component={
                                <TextField
                                    field="personnel_overlap"
                                    disabled={is_read_only}
                                    label="Please explain the overlap of past and future staffing and position levels previously received and how that is coordinated over multiple years?"
                                    multiline
                                    rows={4}
                                    rowsMax={20}
                                    fullWidth
                                />
                            } />   
                        )}
                        </>
                    } />    
                    </>
                )}
                {year >= 2017 && has_contracts && (
                    <>
                    <Typography variant="h3">
                        Contracts
                    </Typography>
                    <Subquestion component={
                        <TextField
                            field="contracts_included"
                            disabled={is_read_only}
                            label="What is included in the contracts line?"
                            maxWords={!is_read_only && "50"}
                            multiline
                            rows={4}
                            rowsMax={20}
                            fullWidth
                        />
                    } />    
                    </>
                )}
                {year >= 2024 && has_professional && (
                    <>
                    <Typography variant="h3">
                        Professional Services
                    </Typography>
                    <Subquestion component={
                        <>
                        <CheckboxGroup
                            field="professional_services"
                            label="What is included in the Professional Services line?"
                            row={true}
                            width={205}
                            disabled={is_read_only}
                            eventHandle={this.applyOther}
                            options={MAKE_OPTIONS([
                                "Appraisals",
                                "Surveys",
                                "Design/Engineering",
                                "Title Insurance and Legal Fees",
                                "Other"
                            ])}
                        />
                        {professional_services_YES && (
                            <TextField
                                field="professional_services_other"
                                disabled={is_read_only}
                                label="What is your other profressional service?"
                                fullWidth
                            />
                        )}
                        </>
                    } />    
                    </>
                )}
                {year >= 2021 && has_fee && (
                    <>
                    <Typography variant="h3">
                        Fee Acquisition
                    </Typography>
                    <Subquestion component={
                        <TextField
                            field="anticipated_fee_transactions"
                            disabled={is_read_only}
                            label="What is the anticipated number of fee title acquisition transactions?"
                            multiline
                            rows={4}
                            rowsMax={20}
                            fullWidth
                        />
                    } />    
                    </>
                )}
                {year >= 2017 && has_easement_stewardship && (
                    <>
                    <Typography variant="h3">
                        Easement Stewardship
                    </Typography>
                    <Subquestion component={
                        <TextField
                            field="cost_per_easement"
                            disabled={is_read_only}
                            label="What is the number of easements anticipated, cost per easement for stewardship, and explain how that amount is calculated?"
                            multiline
                            rows={4}
                            rowsMax={20}
                            fullWidth
                        />
                    } />    
                    </>
                )}
                {year >= 2017 && has_travel && (
                    <>
                    <Typography variant="h3">
                       Travel
                    </Typography>
                    <Subquestion component={
                        <>
                        <RadioGroup
                            field="travel_equipment"
                            disabled={is_read_only}
                            name="travel_equipment"
                            fullWidth
                            options={[
                                { label: "Yes", value: "true" },
                                { label: "No", value: "false" }
                            ]}
                            alignment={true}
                            label="Does the amount in the travel line include equipment/vehicle rental?"
                        />
                        <TextField
                            field="travel_outside_traditional"
                            disabled={is_read_only}
                            label="Explain the amount in the travel line outside of traditional travel costs of mileage, food, and lodging."
                            multiline
                            rows={4}
                            rowsMax={20}
                            fullWidth
                        />
                        {year >= 2021 && (
                            <Checkbox
                                field="attest_travel"
                                disabled={is_read_only}
                                label="I understand and agree that lodging, meals, and mileage must comply with the current MMB Commissioner Plan."
                            />
                        )}
                        </>
                    } />    
                    </>
                )}
                {has_direct_support_services && (
                    <>
                    <Typography variant="h3">
                        Direct Support Services
                    </Typography>
                    <Subquestion component={
                        <TextField
                            field="direct_services_portion"
                            disabled={is_read_only}
                            label="How did you determine which portions of the Direct Support Services of your shared support services is direct to this program?"
                            fullWidth
                            multiline
                            rows={4}
                            rowsMax={20}
                        />
                    } />    
                    </>
                )}
                {year >= 2021 && has_equipment && (
                    <>
                    <Typography variant="h3">
                        Other Equipment/Tools
                    </Typography>
                    <Subquestion component={
                        <TextField
                            field="equipment_examples"
                            disabled={is_read_only}
                            label="Give examples of the types of Equipment and Tools that will be purchased?"
                            fullWidth
                            multiline
                            rows={4}
                            rowsMax={20}
                        />
                    } />    
                    </>
                )}
            </Grid>
        )
    }
}

BudgetTab = connect(
    (state, ownProps) => ({
        budgets: getBudgets(state, ownProps),
        authState: state.auth
    }),
    {
        ...Proposal.actions,
        ...ProposalBudget.actions,
        ...ProposalBudgetPersonnel.actions,
        ...ProposalBudgetCapitalEquipment.actions
    }
)(BudgetTab);

export default withStyles(styles)(withRouter(BudgetTab));
