import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepButton from "@material-ui/core/StepButton";
import Menu from '@material-ui/core/Menu';
import Button from "@material-ui/core/Button";
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import BuildIcon from '@material-ui/icons/Build';
import GetApp from '@material-ui/icons/GetApp';
import { withStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import Warning from "@material-ui/icons/Warning";
import Close from "@material-ui/icons/Close";
import Update from "@material-ui/icons/Update";
import MailIcon from '@material-ui/icons/Mail';
import Done from "@material-ui/icons/Done";
import Create from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";

import DialogActions from "@material-ui/core/DialogActions";

import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import QuestionAnswer from '@material-ui/icons/QuestionAnswer';

import { getValue } from "../../api/utils";
import RadioGroup from "../common/RadioGroup";
import AppContainer from "../common/AppContainer";
import TextField from "../common/TextField";
import PageHeader from "../common/PageHeader";
import Snackbar from "../common/Snackbar";
import { createSelector } from "../common/orm";
import GeneralTab from "./tabs/General";
import NarrativeTab from "./tabs/Narrative";
import ActivityTab from "./tabs/Activity";
import TimelineTab from "./tabs/Timeline";
import BudgetTab from "./tabs/Budget";
import FederalTab from "./tabs/Federal";
import OutputTab from "./tabs/Output";
import OutcomesTab from "./tabs/Outcomes";
import ParcelTab from "./tabs/Parcel";
import AttachmentTab from "./tabs/Attachment";
import AmendmentsTab from "./tabs/Amendments";
import CouncilTab from "./tabs/Council";
import { 
    AccomplishmentPlan, AccomplishmentAmountNonOHF, AccomplishmentFutureMaintain, 
    AccomplishmentTimeline, AccomplishmentBudget, AccomplishmentBudgetPersonnel,
    AccomplishmentBudgetCapitalEquipment, AccomplishmentParcel, AccomplishmentAttachment, AmendmentLog, AccomplishmentLog
} from "./models";
import { 
    StatusUpdate
} from "../status_update/models";
import { SystemSetting } from "../proposal/models";

const getAccomplishmentPlan = AccomplishmentPlan.selectByUrlId(ap => ({
    formData: ap.ref,
    ...ap.ref
}));
const getSystemSettings = SystemSetting.selectMessage();

const getStatusUpdates = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.StatusUpdate.filter({accomplishment_id: id}).toModelArray();
    }
    );


const getParcels = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.AccomplishmentParcel.filter({ accomplishment: id }).orderBy("name", "asc").toModelArray();
    }
);

const getBudgets = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.AccomplishmentBudget.filter({ accomplishment: id }).orderBy("order").toModelArray()
        .map(budget => ({
            _personnel: budget.budgetpersonnel.all().toRefArray(),
            _capital: budget.budgetcapital.all().toRefArray(),
            ...budget.ref
        }));;
    }
);

const styles = theme => ({
    titleContainer: {
        backgroundColor: theme.palette.primary.main,
        textAlign: "center"
    },
    minWidth: {
        minWidth: 400
    },
    closeIcon: {
        float: "right",
        color: "#fff",
        marginTop: -16,
        marginRight: -16
    },
    supportIcon: {
        width: "3em",
        height: "3em",
        color: "#fff",
        marginLeft: 32
    },
    stepRoot: {
        backgroundColor: "#eeeeee",
        marginBottom: theme.spacing(2),
        padding: theme.spacing(1),
        paddingLeft: 0,
        paddingRight: 0
    },
    pointer: {
        cursor: "pointer",
        "& > span:first-child": {
            cursor: "pointer"
        },
        borderBottom: "8px solid transparent",
        marginBottom: -8,
        paddingRight: "8px !important",
        paddingLeft: "8px !important",
        "&:hover": {
            borderBottom: "8px solid lightgray"
        }
    },
    pointerr: {
        borderBottom: "8px solid lightgray",
        marginBottom: "-8px !important"
    },
    pointer2: {
        cursor: "pointer",
        "& > span:first-child": {
            cursor: "pointer"
        },
        marginBottom: -8,
        paddingRight: "8px !important",
        paddingLeft: "8px !important",
        borderBottom: "8px solid " + theme.palette.primary.main
    },
    btnroot: {
        padding: 0,
        margin: 0,
    },
    stepColor: {
        "& svg path": {
            color: theme.palette.secondary.main // + " !important",
        }
    },
    lessMargin: {
        "& span": {
            marginTop: "8px !important",
            color: "#000"
        }
    },
    stepperWidth: {
        marginLeft: -20,
        marginRight: -20,
        maxWidth: "calc(100% + 40px)",
        flexBasis: "unset",
        width: "calc(100% + 40px)",
        padding: "0 !important"
    },
    list: {
        "& ul": {
            padding: 0
        }
    },
    topListText: {
        textAlign: "center",
        padding: 0
    },
    centerButtons: {
        justifyContent: "center",
        marginTop: 24
    },
});

function getSteps(authState) {
    if (authState && authState.user && authState.user.role === "admin")
        return [
            "General",
            "Narrative",
            "Activity Details",
            "Timeline",
            "Budget",
            "Federal Funds",
            "Output Tables",
            "Outcomes",
            "Parcels",
            "Attachments",
            "Amendments",
            "Council Action"
        ];
    return [
        "General",
        "Narrative",
        "Activity Details",
        "Timeline",
        "Budget",
        "Federal Funds",
        "Output Tables",
        "Outcomes",
        "Parcels",
        "Attachments",
        "Amendments"
    ];
}

class List extends Component {
    constructor(props) {
        super(props);
        this.appContainer = React.createRef();
    }
    state = {
        unsavedFields: false,
        snackbarOpen: false,
        anchorEl: null,
        setAnchorEl: null,
        submissionErrorOpen: false,
        submissionOpen: false,
        message: [],
        loadingAmendment: false,
        statusUpdateOpen: false,
        statusUpdateOpen2: false,
        statusUpdateOpen3: false,
        statusUpdateOpen4: false,
        statusUpdateOpen5: false,
        statusUpdateOpenNew: false,
        q1: null,
        q2: null,
        q3: null,
        q4: null
    };

    handleUnsavedFields = unsavedFields => {
        this.setState({ unsavedFields });
    };

    isLastStep(step) {
        return step === getSteps().length - 1;
    }

    handleNext = () => {
        let { activeStep } = this.props.accomplishment_plan;
        const { history } = this.props;
        if (this.isLastStep(activeStep)) {
            activeStep = 0;
            history.push("/dashboard");
        } else {
            activeStep = (activeStep || 0) + 1;
        }
        this.handleStep(activeStep);
    };

    handleStep = (step, fromStepper) => {
        const { authState, accomplishment_plan } = this.props;
        const { id } = this.props.accomplishment_plan;

        this.handleUnsavedFields(false);

        // Hacky method to save form when using stepper
        if (fromStepper && (accomplishment_plan.status === "First Draft" || accomplishment_plan.status === "Approved First Draft" || accomplishment_plan.status === "Started Request for Amendment Change")) {
            // Apparently just having a single "activeForm" works because only one stepper detail can be active at once
            if (this.activeForm) {
                // Pass true to the submit function
                // Seems to come out as the second argument in onSubmit
                // Don't do the handleNext's if integer (normally an event) - set activeStep to this number
                this.activeForm.setState({ submitClicked: true });
                const f = this;
                setTimeout(function() {
                    // The state is not set right away...
                    f.activeForm.form.submitForm(step);
                }, 50);
            }
        } else {
            this.props.ormAccomplishmentPlanUpdateLocalOnly({
                id: id,
                activeStep: step
            });
        }
        if (authState && authState.user && authState.user.role === "readonly")  {

        } else if (accomplishment_plan.status === "First Draft" || accomplishment_plan.status === "Approved First Draft" || accomplishment_plan.status === "Started Request for Amendment Change") {
            this.setState({ snackbarOpen: true });
        }
    };

    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    };

    componentDidMount() {
        const { 
            accomplishment_plan, ormAccomplishmentPlanLoadDetail, match, authState, ormAccomplishmentAmountNonOHFLoadDetailChild,
            ormAccomplishmentFutureMaintainLoadDetailChild, ormAccomplishmentTimelineLoadDetailChild, ormAccomplishmentBudgetLoadDetailChild,
            ormAccomplishmentBudgetPersonnelLoadDetailChild, ormAccomplishmentBudgetCapitalEquipmentLoadDetailChild, history,
            ormAccomplishmentParcelLoadDetailChild, ormAccomplishmentAttachmentLoadDetailChild, ormAmendmentLogLoadDetailChild,
            ormAccomplishmentLogLoadDetailChild
        } = this.props;
        if (match.params.id === "edit.php") {
            history.push("/dashboard");
        }
        if (authState && authState.user && (authState.user.role === "admin" || authState.user.role === "readonly" || (!accomplishment_plan || !accomplishment_plan.create_date))) {
            ormAccomplishmentPlanLoadDetail(match.params.id);
            ormAccomplishmentAmountNonOHFLoadDetailChild(match.params.id);
            ormAccomplishmentFutureMaintainLoadDetailChild(match.params.id);
            ormAccomplishmentTimelineLoadDetailChild(match.params.id);
            ormAccomplishmentBudgetLoadDetailChild(match.params.id);
            ormAccomplishmentBudgetPersonnelLoadDetailChild(match.params.id);
            ormAccomplishmentBudgetCapitalEquipmentLoadDetailChild(match.params.id);
            ormAccomplishmentParcelLoadDetailChild(match.params.id);
            ormAccomplishmentAttachmentLoadDetailChild(match.params.id);
            ormAccomplishmentLogLoadDetailChild(match.params.id);
            ormAmendmentLogLoadDetailChild(match.params.id);
        }
    }

    componentDidUpdate() {
        const { 
            accomplishment_plan, ormAccomplishmentPlanLoadDetail, match, authState, ormAccomplishmentAmountNonOHFLoadDetailChild,
            ormAccomplishmentFutureMaintainLoadDetailChild, ormAccomplishmentTimelineLoadDetailChild, ormAccomplishmentBudgetLoadDetailChild,
            ormAccomplishmentBudgetPersonnelLoadDetailChild, ormAccomplishmentBudgetCapitalEquipmentLoadDetailChild, history,
            ormAccomplishmentParcelLoadDetailChild, ormAccomplishmentAttachmentLoadDetailChild, ormAmendmentLogLoadDetailChild,
            ormAccomplishmentLogLoadDetailChild
        } = this.props;
        if (match.params.id === "edit.php") {
            history.push("/dashboard");
        }
        if ((!accomplishment_plan || !accomplishment_plan.create_date) && authState && authState.user) {
            ormAccomplishmentPlanLoadDetail(match.params.id);
            ormAccomplishmentAmountNonOHFLoadDetailChild(match.params.id);
            ormAccomplishmentFutureMaintainLoadDetailChild(match.params.id);
            ormAccomplishmentTimelineLoadDetailChild(match.params.id);
            ormAccomplishmentBudgetLoadDetailChild(match.params.id);
            ormAccomplishmentBudgetPersonnelLoadDetailChild(match.params.id);
            ormAccomplishmentBudgetCapitalEquipmentLoadDetailChild(match.params.id);
            ormAccomplishmentParcelLoadDetailChild(match.params.id);
            ormAccomplishmentAttachmentLoadDetailChild(match.params.id);
            ormAccomplishmentLogLoadDetailChild(match.params.id);
            ormAmendmentLogLoadDetailChild(match.params.id);
        }
    }

    actionMenuOpen = event => {
        this.setState({ anchorEl: event.currentTarget });
    };
    actionMenuClose = () => {
        this.setState({ anchorEl: null });
    };

    requestAmendment = () => {
        const { accomplishment_plan, authState, /*ormAccomplishmentPlanUpdate*/ } = this.props;
        this.setState({ loadingAmendment: true });
        fetch("amendment/start/" + accomplishment_plan.id, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Token " + authState.user.auth_token
            }
        }).then(e => {
            window.location.reload();
        });
        /*const { 
            ormAccomplishmentPlanLoadDetail, match, ormAccomplishmentAmountNonOHFLoadDetailChild,
            ormAccomplishmentFutureMaintainLoadDetailChild, ormAccomplishmentTimelineLoadDetailChild, ormAccomplishmentBudgetLoadDetailChild,
            ormAccomplishmentBudgetPersonnelLoadDetailChild, ormAccomplishmentBudgetCapitalEquipmentLoadDetailChild, 
            ormAccomplishmentParcelLoadDetailChild, ormAccomplishmentAttachmentLoadDetailChild, ormAmendmentLogLoadDetailChild
        } = this.props;
        ormAccomplishmentPlanUpdate({
            id: accomplishment_plan.id,
            status: "Started Request for Amendment Change"
        }, function(e) {
            if (this.activeForm) {
                this.activeForm.form.setValue("status", "Started Request for Amendment Change");
            }
            ormAccomplishmentPlanLoadDetail(match.params.id);
            ormAccomplishmentAmountNonOHFLoadDetailChild(match.params.id);
            ormAccomplishmentFutureMaintainLoadDetailChild(match.params.id);
            ormAccomplishmentTimelineLoadDetailChild(match.params.id);
            ormAccomplishmentBudgetLoadDetailChild(match.params.id);
            ormAccomplishmentBudgetPersonnelLoadDetailChild(match.params.id);
            ormAccomplishmentBudgetCapitalEquipmentLoadDetailChild(match.params.id);
            ormAccomplishmentParcelLoadDetailChild(match.params.id);
            ormAccomplishmentAttachmentLoadDetailChild(match.params.id);
            ormAmendmentLogLoadDetailChild(match.params.id);
           
           //window.location.reload();
        }) */
    };

    revertAmendment = () => {
        const { accomplishment_plan, authState, /*ormAccomplishmentPlanUpdate*/ } = this.props;
        if (window.confirm("Are you sure you want to revert this record back to the latest approved version?  You will permanently loose all information entered for this amendment.")) {
            this.setState({ loadingAmendment: true });
            fetch("amendment/stop/" + accomplishment_plan.id, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Token " + authState.user.auth_token
                }
            }).then(e => {
                window.location.reload();
            });
        }
    };

    checkValues = (a, b) => {
        if (a === undefined || a === null || a === "")
            a = 0
        if (b === undefined || b === null || b === "")
            b = 0
        if (a !== b)
            return true;
        return false;
    }

    finalSubmit = () => {
        const { accomplishment_plan, parcels, budgets, ormAccomplishmentPlanUpdate } = this.props;
        const year = parseInt(accomplishment_plan.ml_year);
        var missing = [];
        var invalidParcel = false;
        var existingProtection = false;

        if (accomplishment_plan.is_correct_region)
            missing.push("You have entered parcels that are not in the region(s) defined on the general tab: " + accomplishment_plan.is_correct_region);
        // if (accomplishment_plan.is_correct_county)
        //     missing.push("You have entered parcels that are in a different county (as calculated from the TRDS) than the county you have entered: " + accomplishment_plan.is_correct_county);
        if (!accomplishment_plan.direct_appropriation && year >= 2019)
            missing.push("Any state agency or organization requesting a direct appropriation question is required. (Narrative Tab)");
        var activity = false;
        parcels.forEach(function(p) {
            if (!p.activity)
                activity = true;
            if (!p.has_geom)
                invalidParcel = true;
            if ((p.activity === "Restore" || p.activity === "Enhance") && p.existing_protection !== "Yes")
                existingProtection = true;
        });
        if (!accomplishment_plan.final_report_submission)
            missing.push("You are missing a date of final report submission. (Timeline Tab)");
        if (existingProtection)
            missing.push("You have submitted a Restore/Enhance parcel that does NOT have existing protection.  As per 97A.056, subd 13 (f), only parcels on land permanently protected by a conservation easement or public ownership or in public waters as defined in section 103G.005, subdivision 15 are eligible for OHF funds? Please consult with staff."); 
        if (activity)
            missing.push("You have undefined parcel activities. Please make sure all parcels in the parcel tab have an activity.");
        if (accomplishment_plan.status === "Started Request for Amendment Change" || accomplishment_plan.status === "Submitted Request for Amendment Change") {
            if (this.checkValues(accomplishment_plan.output_acres_resourcetype_total_total_amend, accomplishment_plan.output_acres_ecologicalsection_total_total_amend))
                missing.push("The acre totals in Tables 1 and 3 do not match under the Output Tables tab.");
            if (this.checkValues(accomplishment_plan.output_dollars_resourcetype_total_total_amend, accomplishment_plan.output_dollars_ecologicalsection_total_total_amend))
                missing.push("The total request for funding in Tables 2 and 4 do not match under the Output Tables tab.");
            if (this.checkValues(accomplishment_plan.output_acres_resourcetype_restore_total_amend, accomplishment_plan.output_acres_ecologicalsection_restore_total_amend))
                missing.push("The restore acres in Tables 1 and 3 do not match under the Output Tables tab.");
            if (this.checkValues(accomplishment_plan.output_acres_resourcetype_feepilt_total_amend, accomplishment_plan.output_acres_ecologicalsection_feepilt_total_amend))
                missing.push("The protect in fee with state PILT liability acres in Tables 1 and 3 do not match under the Output Tables tab.");
            if (this.checkValues(accomplishment_plan.output_acres_resourcetype_feewopilt_total_amend, accomplishment_plan.output_acres_ecologicalsection_feewopilt_total_amend))
                missing.push("The protect in fee w/o state PILT liability acres in Tables 1 and 3 do not match under the Output Tables tab.");
            if (this.checkValues(accomplishment_plan.output_acres_resourcetype_easement_total_amend, accomplishment_plan.output_acres_ecologicalsection_easement_total_amend))
                missing.push("The easement acres in Tables 1 and 3 do not match under the Output Tables tab.");
            if (this.checkValues(accomplishment_plan.output_acres_resourcetype_enhance_total_amend, accomplishment_plan.output_acres_ecologicalsection_enhance_total_amend))
                missing.push("The enhance acres in Tables 1 and 3 do not match under the Output Tables tab.");
            if (this.checkValues(accomplishment_plan.output_dollars_resourcetype_restore_total_amend, accomplishment_plan.output_dollars_ecologicalsection_restore_total_amend))
                missing.push("The restore dollars in Tables 2 and 4 do not match under the Output Tables tab.");
            if (this.checkValues(accomplishment_plan.output_dollars_resourcetype_feepilt_total_amend, accomplishment_plan.output_dollars_ecologicalsection_feepilt_total_amend))
                missing.push("The protect in fee with state PILT liability dollars in Tables 2 and 4 do not match under the Output Tables tab.");
            if (this.checkValues(accomplishment_plan.output_dollars_resourcetype_feewopilt_total_amend, accomplishment_plan.output_dollars_ecologicalsection_feewopilt_total_amend))
                missing.push("The protect in fee w/o state PILT liability dollars in Tables 2 and 4 do not match under the Output Tables tab.");
            if (this.checkValues(accomplishment_plan.output_dollars_resourcetype_easement_total_amend, accomplishment_plan.output_dollars_ecologicalsection_easement_total_amend))
                missing.push("The easement dollars in Tables 2 and 4 do not match under the Output Tables tab.");
            if (this.checkValues(accomplishment_plan.output_dollars_resourcetype_enhance_total_amend, accomplishment_plan.output_dollars_ecologicalsection_enhance_total_amend))
                missing.push("The enhance dollars in Tables 2 and 4 do not match under the Output Tables tab.");
        } else {
            if (this.checkValues(accomplishment_plan.output_acres_resourcetype_total_total, accomplishment_plan.output_acres_ecologicalsection_total_total))
                missing.push("The acre totals in Tables 1 and 3 do not match under the Output Tables tab.");
            if (this.checkValues(accomplishment_plan.output_dollars_resourcetype_total_total, accomplishment_plan.output_dollars_ecologicalsection_total_total))
                missing.push("The total request for funding in Tables 2 and 4 do not match under the Output Tables tab.");
            if (this.checkValues(accomplishment_plan.output_acres_resourcetype_restore_total, accomplishment_plan.output_acres_ecologicalsection_restore_total))
                missing.push("The restore acres in Tables 1 and 3 do not match under the Output Tables tab.");
            if (this.checkValues(accomplishment_plan.output_acres_resourcetype_feepilt_total, accomplishment_plan.output_acres_ecologicalsection_feepilt_total))
                missing.push("The protect in fee with state PILT liability acres in Tables 1 and 3 do not match under the Output Tables tab.");
            if (this.checkValues(accomplishment_plan.output_acres_resourcetype_feewopilt_total, accomplishment_plan.output_acres_ecologicalsection_feewopilt_total))
                missing.push("The protect in fee w/o state PILT liability acres in Tables 1 and 3 do not match under the Output Tables tab.");
            if (this.checkValues(accomplishment_plan.output_acres_resourcetype_easement_total, accomplishment_plan.output_acres_ecologicalsection_easement_total))
                missing.push("The easement acres in Tables 1 and 3 do not match under the Output Tables tab.");
            if (this.checkValues(accomplishment_plan.output_acres_resourcetype_enhance_total, accomplishment_plan.output_acres_ecologicalsection_enhance_total))
                missing.push("The enhance acres in Tables 1 and 3 do not match under the Output Tables tab.");
            if (this.checkValues(accomplishment_plan.output_dollars_resourcetype_restore_total, accomplishment_plan.output_dollars_ecologicalsection_restore_total))
                missing.push("The restore dollars in Tables 2 and 4 do not match under the Output Tables tab.");
            if (this.checkValues(accomplishment_plan.output_dollars_resourcetype_feepilt_total, accomplishment_plan.output_dollars_ecologicalsection_feepilt_total))
                missing.push("The protect in fee with state PILT liability dollars in Tables 2 and 4 do not match under the Output Tables tab.");
            if (this.checkValues(accomplishment_plan.output_dollars_resourcetype_feewopilt_total, accomplishment_plan.output_dollars_ecologicalsection_feewopilt_total))
                missing.push("The protect in fee w/o state PILT liability dollars in Tables 2 and 4 do not match under the Output Tables tab.");
            if (this.checkValues(accomplishment_plan.output_dollars_resourcetype_easement_total, accomplishment_plan.output_dollars_ecologicalsection_easement_total))
                missing.push("The easement dollars in Tables 2 and 4 do not match under the Output Tables tab.");
            if (this.checkValues(accomplishment_plan.output_dollars_resourcetype_enhance_total, accomplishment_plan.output_dollars_ecologicalsection_enhance_total))
                missing.push("The enhance dollars in Tables 2 and 4 do not match under the Output Tables tab.");
        }

        var GrandTotal = 0;
        var withpilt = false;
        var wopilt = false;
        var easementbud = false;
        var personnelposition = false;
        var personnelleverage = false;
        var capitalposition = false;
        var capitalleverage = false;
        var budgetleverage = false;
        if (accomplishment_plan.status === "Started Request for Amendment Change" || accomplishment_plan.status === "Submitted Request for Amendment Change") {
            budgets.forEach(function(b) {
                if (b.item === "Grand Total") {
                    GrandTotal += b.funding_request_amend;
                }
                if (b.item === "Fee Acquisition w/ PILT" && b.funding_request_amend > 0 && accomplishment_plan.output_dollars_resourcetype_feepilt_total_amend === 0) {
                    withpilt = true;
                }
                if (b.item === "Fee Acquisition w/o PILT" && b.funding_request_amend > 0 && accomplishment_plan.output_dollars_resourcetype_feewopilt_total_amend === 0) {
                    wopilt = true;
                }
                if ((b.item === "Easement Acquisition" || b.item === "Easement Stewardship") && b.funding_request_amend > 0 && accomplishment_plan.output_dollars_resourcetype_easement_total_amend === 0) {
                    easementbud = true;
                }
                if (b.item === "Personnel") {
                    b._personnel.forEach(function(p) {
                        if (!p.position)
                            personnelposition = true;
                        if (p.anticipated_leverage > 0 && !p.leverage_source)
                            personnelleverage = true;
                    })
                }
                else if (b.item === "Capital Equipment") {
                    b._capital.forEach(function(p) {
                        if (!p.position)
                            capitalposition = true;
                        if (p.anticipated_leverage > 0 && !p.leverage_source)
                            capitalleverage = true;
                    })
                }
                else if (b.item !== "Grand Total" && b.anticipated_leverage_amend > 0 && !b.leverage_source)
                    budgetleverage = true;
            });  
        } else {
            budgets.forEach(function(b) {
                if (b.item === "Grand Total") {
                    GrandTotal += b.funding_request;
                }
                if (b.item === "Fee Acquisition w/ PILT" && b.funding_request > 0 && accomplishment_plan.output_dollars_resourcetype_feepilt_total === 0) {
                    withpilt = true;
                }
                if (b.item === "Fee Acquisition w/o PILT" && b.funding_request > 0 && accomplishment_plan.output_dollars_resourcetype_feewopilt_total === 0) {
                    wopilt = true;
                }
                if ((b.item === "Easement Acquisition" || b.item === "Easement Stewardship") && b.funding_request > 0 && accomplishment_plan.output_dollars_resourcetype_easement_total === 0) {
                    easementbud = true;
                }
                if (b.item === "Personnel") {
                    b._personnel.forEach(function(p) {
                        if (!p.position)
                            personnelposition = true;
                        if (p.anticipated_leverage > 0 && !p.leverage_source)
                            personnelleverage = true;
                    })
                }
                else if (b.item === "Capital Equipment") {
                    b._capital.forEach(function(p) {
                        if (!p.position)
                            capitalposition = true;
                        if (p.anticipated_leverage > 0 && !p.leverage_source)
                            capitalleverage = true;
                    })
                }
                else if (b.item !== "Grand Total" && b.anticipated_leverage > 0 && !b.leverage_source)
                    budgetleverage = true;
            });   
        }  
        if (withpilt) {
            missing.push("You have a dollar amount entered into the budget line item for Protect in Fee with State PILT Liability, but you do not have any entered for that output table line item.");
        }   
        if (wopilt) {
            missing.push("You have a dollar amount entered into the budget line item for Protect in Fee without State PILT Liability, but you do not have any entered for that output table line item.");
        }   
        if (easementbud) {
            missing.push("You have a dollar amount entered into the budget line item for Easements, but you do not have any entered for that output table line item.");
        }   
        if (personnelposition) {
            missing.push("You have budget personnel entries with no positions defined. Please enter their role.");
        }   
        if (personnelleverage) {
            missing.push("You have budget personnel entries with leverage values, but no source defined. Please enter the source.");
        }   
        if (capitalposition) {
            missing.push("You have budget capital equipment entries with no item name defined. Please enter their name.");
        }   
        if (capitalleverage) {
            missing.push("You have budget capital equipment entries with leverage values, but no source defined. Please enter the source.");
        }   
        if (budgetleverage) {
            missing.push("You have budget entries with leverage values, but no source defined. Please enter the source.");
        }   

        if (accomplishment_plan.status === "Started Request for Amendment Change" || accomplishment_plan.status === "Submitted Request for Amendment Change") {
            if (accomplishment_plan.output_dollars_resourcetype_total_total_amend !== GrandTotal)
                missing.push("The total budget request under the Budget tab does not match the total request funding for tables 2 and 4 under the Output Tables tab.");
        } else {
            if (accomplishment_plan.output_dollars_resourcetype_total_total !== GrandTotal)
                missing.push("The total budget request under the Budget tab does not match the total request funding for tables 2 and 4 under the Output Tables tab.");
        }
        if (parseInt(accomplishment_plan.funds_recommended.replace(/,/g, "")) !== parseInt(GrandTotal)) {
            missing.push("The total budget value does not match the appropriated funds.");
        }
        if (
            accomplishment_plan.proposal_acknowledgement_general === false || accomplishment_plan.proposal_acknowledgement_narrative === false || 
            accomplishment_plan.proposal_acknowledgement_activity === false || accomplishment_plan.proposal_acknowledgement_timeline === false || 
            accomplishment_plan.proposal_acknowledgement_federal === false || accomplishment_plan.proposal_acknowledgement_outcomes === false || 
            accomplishment_plan.proposal_acknowledgement_parcels === false
        ) {
            missing.push("You have not confirmed that the information was brought over from your proposal for every tab.");
        }

        const has_northern_forest = getValue(accomplishment_plan, "eco_regions").indexOf("Northern Forest") > -1;
        const has_transition = getValue(accomplishment_plan, "eco_regions").indexOf("Forest / Prairie Transition") > -1;
        const has_prairie = getValue(accomplishment_plan, "eco_regions").indexOf("Prairie") > -1;
        const has_southeast_forest = getValue(accomplishment_plan, "eco_regions").indexOf("Southeast Forest") > -1;
        const has_metro = getValue(accomplishment_plan, "eco_regions").indexOf("Metro / Urban") > -1;
        if ((accomplishment_plan.output_acres_ecologicalsection_total_northernforest || accomplishment_plan.output_dollars_ecologicalsection_total_northernforest || accomplishment_plan.output_acres_ecologicalsection_total_northernforest_amend || accomplishment_plan.output_dollars_ecologicalsection_total_northernforest_amend) && !has_northern_forest)
            missing.push("You have entered amounts into the Northern Forest columns of the Output Tables, but have not indicated you are working in the region Northern Forest under the General Tab.");
        if ((accomplishment_plan.output_acres_ecologicalsection_total_forestprairie || accomplishment_plan.output_dollars_ecologicalsection_total_forestprairie || accomplishment_plan.output_acres_ecologicalsection_total_forestprairie_amend || accomplishment_plan.output_dollars_ecologicalsection_total_forestprairie_amend) && !has_transition)
            missing.push("You have entered amounts into the Forest / Prairie columns of the Output Tables, but have not indicated you are working in the region Forest / Prairie Transition under the General Tab.");
        if ((accomplishment_plan.output_acres_ecologicalsection_total_prairie || accomplishment_plan.output_dollars_ecologicalsection_total_prairie || accomplishment_plan.output_acres_ecologicalsection_total_prairie_amend || accomplishment_plan.output_dollars_ecologicalsection_total_prairie_amend) && !has_prairie)
            missing.push("You have entered amounts into the Prairie columns of the Output Tables, but have not indicated you are working in the region Prairie under the General Tab.");
        if ((accomplishment_plan.output_acres_ecologicalsection_total_metrourban || accomplishment_plan.output_dollars_ecologicalsection_total_metrourban || accomplishment_plan.output_acres_ecologicalsection_total_metrourban_amend || accomplishment_plan.output_dollars_ecologicalsection_total_metrourban_amend) && !has_metro)
            missing.push("You have entered amounts into the Metro / Urban columns of the Output Tables, but have not indicated you are working in the region Metro / Urban under the General Tab.");
        if ((accomplishment_plan.output_acres_ecologicalsection_total_seforest || accomplishment_plan.output_dollars_ecologicalsection_total_seforest || accomplishment_plan.output_acres_ecologicalsection_total_seforest_amend || accomplishment_plan.output_dollars_ecologicalsection_total_seforest_amend) && !has_southeast_forest)
            missing.push("You have entered amounts into the SE Forest columns of the Output Tables, but have not indicated you are working in the region Southeast Forest under the General Tab.");

        const has_pra_forest = getValue(accomplishment_plan, "priority_resources_activity").indexOf("Forest") > -1;
        const has_pra_wetlands = getValue(accomplishment_plan, "priority_resources_activity").indexOf("Wetlands") > -1;
        const has_pra_habitat = getValue(accomplishment_plan, "priority_resources_activity").indexOf("Habitat") > -1;
        const has_pra_prairie = getValue(accomplishment_plan, "priority_resources_activity").indexOf("Prairie") > -1;
        if ((accomplishment_plan.output_acres_resourcetype_total_wetland || accomplishment_plan.output_dollars_resourcetype_total_wetland || accomplishment_plan.output_acres_resourcetype_total_wetland_amend || accomplishment_plan.output_dollars_resourcetype_total_wetland_amend) && !has_pra_wetlands)
            missing.push("You have entered amounts into the Wetlands columns of the Output Tables, but have not indicated you are working with the resource type Wetlands under the General Tab.");
        if ((accomplishment_plan.output_acres_resourcetype_total_prairie || accomplishment_plan.output_dollars_resourcetype_total_prairie || accomplishment_plan.output_acres_resourcetype_total_prairie_amend || accomplishment_plan.output_dollars_resourcetype_total_prairie_amend) && !has_pra_prairie)
            missing.push("You have entered amounts into the Prairies columns of the Output Tables, but have not indicated you are working with the resource type Prairie under the General Tab.");
        if ((accomplishment_plan.output_acres_resourcetype_total_forest || accomplishment_plan.output_dollars_resourcetype_total_forest || accomplishment_plan.output_acres_resourcetype_total_forest_amend || accomplishment_plan.output_dollars_resourcetype_total_forest_amend) && !has_pra_forest)
            missing.push("You have entered amounts into the Forest columns of the Output Tables, but have not indicated you are working with the resource type Forest under the General Tab.");
        if ((accomplishment_plan.output_acres_resourcetype_total_habitat || accomplishment_plan.output_dollars_resourcetype_total_habitat || accomplishment_plan.output_acres_resourcetype_total_habitat_amend || accomplishment_plan.output_dollars_resourcetype_total_habitat_amend) && !has_pra_habitat)
            missing.push("You have entered amounts into the Habitats columns of the Output Tables, but have not indicated you are working with the resource type Habitat under the General Tab.");

        const has_enhance = getValue(accomplishment_plan, "activity_types").indexOf("Enhance") > -1;
        const has_easement = getValue(accomplishment_plan, "activity_types").indexOf("Protect in Easement") > -1;
        const has_fee = getValue(accomplishment_plan, "activity_types").indexOf("Protect in Fee") > -1;
        const has_restore = getValue(accomplishment_plan, "activity_types").indexOf("Restore") > -1;
        if ((accomplishment_plan.output_acres_resourcetype_restore_total || accomplishment_plan.output_dollars_resourcetype_restore_total ||  accomplishment_plan.output_acres_ecologicalsection_restore_total || accomplishment_plan.output_dollars_ecologicalsection_restore_total ||
            accomplishment_plan.output_acres_resourcetype_restore_total_amend || accomplishment_plan.output_dollars_resourcetype_restore_total_amend ||  accomplishment_plan.output_acres_ecologicalsection_restore_total_amend || accomplishment_plan.output_dollars_ecologicalsection_restore_total_amend) && !has_restore)
            missing.push("You have entered amounts into the Restore rows of the Output Tables, but have not indicated you are working with the activity type Restore under the General Tab.");
        if ((accomplishment_plan.output_acres_resourcetype_feepilt_total || accomplishment_plan.output_dollars_resourcetype_feepilt_total ||  accomplishment_plan.output_acres_ecologicalsection_feepilt_total || accomplishment_plan.output_dollars_ecologicalsection_feepilt_total ||
            accomplishment_plan.output_acres_resourcetype_feewopilt_total || accomplishment_plan.output_dollars_resourcetype_feewopilt_total ||  accomplishment_plan.output_acres_ecologicalsection_feewopilt_total || accomplishment_plan.output_dollars_ecologicalsection_feewopilt_total ||
            accomplishment_plan.output_acres_resourcetype_feepilt_total_amend || accomplishment_plan.output_dollars_resourcetype_feepilt_total_amend ||  accomplishment_plan.output_acres_ecologicalsection_feepilt_total_amend || accomplishment_plan.output_dollars_ecologicalsection_feepilt_total_amend ||
            accomplishment_plan.output_acres_resourcetype_feewopilt_total_amend || accomplishment_plan.output_dollars_resourcetype_feewopilt_total_amend ||  accomplishment_plan.output_acres_ecologicalsection_feewopilt_total_amend || accomplishment_plan.output_dollars_ecologicalsection_feewopilt_total_amend) && !has_fee)
            missing.push("You have entered amounts into the Protect in Fee rows of the Output Tables, but have not indicated you are working with the activity type Protect in Fee under the General Tab.");
        if ((accomplishment_plan.output_acres_resourcetype_easement_total || accomplishment_plan.output_dollars_resourcetype_easement_total ||  accomplishment_plan.output_acres_ecologicalsection_easement_total || accomplishment_plan.output_dollars_ecologicalsection_easement_total ||
            accomplishment_plan.output_acres_resourcetype_easement_total_amend || accomplishment_plan.output_dollars_resourcetype_easement_total_amend ||  accomplishment_plan.output_acres_ecologicalsection_easement_total_amend || accomplishment_plan.output_dollars_ecologicalsection_easement_total_amend) && !has_easement)
            missing.push("You have entered amounts into the Protect in Easement rows of the Output Tables, but have not indicated you are working with the activity type Protect in Easement under the General Tab.");
        if ((accomplishment_plan.output_acres_resourcetype_enhance_total || accomplishment_plan.output_dollars_resourcetype_enhance_total ||  accomplishment_plan.output_acres_ecologicalsection_enhance_total || accomplishment_plan.output_dollars_ecologicalsection_enhance_total ||
            accomplishment_plan.output_acres_resourcetype_enhance_total_amend || accomplishment_plan.output_dollars_resourcetype_enhance_total_amend ||  accomplishment_plan.output_acres_ecologicalsection_enhance_total_amend || accomplishment_plan.output_dollars_ecologicalsection_enhance_total_amend) && !has_enhance)
            missing.push("You have entered amounts into the Enhance rows of the Output Tables, but have not indicated you are working with the activity type Enhance under the General Tab.");
    
        if (!accomplishment_plan.signup_criteria) { 
            if (invalidParcel)
                missing.push("At least one parcel contains an invalid TRDS. If you do not have any parcels please upload your sign-up criteria instead.");
            if (parcels.length === 0)
                missing.push("You do not have parcels defined or a sign-up criteria attached.");
        }

        if (missing.length > 0) {
            this.setState({submissionErrorOpen: true, missing: missing})
        } else if(window.confirm("Your accomplishment plan has been successully completed. Click OK to submit your plan/amendment to the LSOHC staff.")){
            if (accomplishment_plan.status === "First Draft") {
                ormAccomplishmentPlanUpdate({
                    id: accomplishment_plan.id,
                    status: "Submitted First Draft"
                });
            } else if (accomplishment_plan.status === "Approved First Draft") {
                ormAccomplishmentPlanUpdate({
                    id: accomplishment_plan.id,
                    status: "Submitted Final Draft"
                });
            } else {
                ormAccomplishmentPlanUpdate({
                    id: accomplishment_plan.id,
                    status: "Submitted Request for Amendment Change"
                });
            }
            this.setState({ submissionOpen: true });
        }
    }

    startStatusUpdate = () => {
        const { status_updates } = this.props;
        var recent = false;
        var today = new Date();
        var priorDate = new Date(new Date().setDate(today.getDate()-30));
        status_updates.forEach(function(su) {
            var sdate = new Date(su.statusdate);
            if (sdate > priorDate)
                recent = true;
        });
        if (recent) {
            alert("You have recently started a status update on this project. To edit this status update open it from the dashboard.")
            return;
        }
        this.setState({statusUpdateOpen: true});
    };

    createStatusUpdate = () => {
        const { accomplishment_plan, ormStatusUpdateCreate, history } = this.props;
        const { q4, q4_parcels } = this.state;

        if (q4 === "I attest that there is nothing new to report since the last status update; there has been no additional budget expenditures or output changes since the last Status Update and I would like the AP moved forward to the final report dashboard for initial completion. This program is ONLY waiting to close out the grant due to final spending of IDP monies.") {
            ormStatusUpdateCreate({
                accomplishment_id: accomplishment_plan.id,
                status: "Submitted",
                progress_todate: "ONLY IDP MONIES LEFT – I attest that there is nothing new to report since the last status update, no amendments are needed, there has been no additional budget expenditures or output changes since the last Status Update and I would like the AP moved forward to the final report dashboard for initial completion.  This program is ONLY waiting to close out the grant due to final spending of IDP monies.",
                idp_money: q4_parcels
            }).then(id => {
                history.push("/dashboard");
            });
        } else {
            ormStatusUpdateCreate({
                accomplishment_id: accomplishment_plan.id
            }).then(id => {
                history.push("/status_update/" + id);
            });
        }
    };    
    
    typingTimerUpdate = (value) => {
        const _this = this;
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(function() {
            _this.setState({q4_parcels: value})
        }, 1000);
    };

    ActionMenu = ({ options, actionMenuOpen, actionMenuClose, anchorEl, accomplishment_plan, classes, authState, ...rest }) => (
        <div style={{float:"right"}}>
            <Button
                variant="contained"
                color="primary"
                onClick={actionMenuOpen} >
                <BuildIcon />&nbsp;&nbsp;&nbsp;Select Action
            </Button>
            <Menu
                className={classes.list}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={actionMenuClose}
            >
                <MenuItem style={{borderBottom: "1px solid #666666",backgroundColor: "#eee",cursor: "default"}}>
                    <ListItemText
                        primary={<Typography variant="h3">Actions</Typography>}
                        classes={{
                            root: classes.topListText,
                            primary: classes.topListText
                        }}
                    />
                </MenuItem>
                <MenuItem onClick={() => {window.open("/reports/accomplishmentplan/" + accomplishment_plan.id + "/pdf");actionMenuClose();}} style={{ borderBottom: "1px solid #666666"}}>
                    <ListItemIcon>
                        <GetApp />
                    </ListItemIcon>
                    <ListItemText primary="Generate Report" />
                </MenuItem>
                {accomplishment_plan.status === "Started Request for Amendment Change" && (
                    <MenuItem onClick={() => {this.finalSubmit();actionMenuClose();}} style={{ borderBottom: "1px solid #666666"}}>
                        <ListItemIcon>
                            <MailIcon />
                        </ListItemIcon>
                        <ListItemText primary="Submit Amendment for Approval" />
                    </MenuItem>
                )}
                {(accomplishment_plan.status === "First Draft" || accomplishment_plan.status === "Approved First Draft") && (
                    <MenuItem onClick={() => {this.finalSubmit();actionMenuClose();}} style={{ borderBottom: "1px solid #666666"}}>
                        <ListItemIcon>
                            <MailIcon />
                        </ListItemIcon>
                        <ListItemText primary="Submit Plan for Approval" />
                    </MenuItem>
                )}
                {accomplishment_plan.status === "Approved Final Draft" && (
                    <MenuItem onClick={() => {this.requestAmendment();actionMenuClose();}} style={{ borderBottom: "1px solid #666666"}}>
                        <ListItemIcon>
                            <Create />
                        </ListItemIcon>
                        <ListItemText primary="Request Amendment Change" />
                    </MenuItem>
                )}
                {accomplishment_plan.status === "Started Request for Amendment Change" && authState && authState.user && authState.user.role === "admin" && (
                    <MenuItem onClick={() => {this.revertAmendment();actionMenuClose();}} style={{ borderBottom: "1px solid #666666"}}>
                        <ListItemIcon>
                            <DeleteIcon />
                        </ListItemIcon>
                        <ListItemText primary="Revert Amendment" />
                    </MenuItem>
                )}
                {(accomplishment_plan.status === "Approved Final Draft" /*|| accomplishment_plan.status === "Started Request for Amendment Change" || accomplishment_plan.status === "Submitted Request for Amendment Change"*/) && (
                    <MenuItem onClick={this.startStatusUpdate} style={{ borderBottom: "1px solid #666666"}}>
                        <ListItemIcon>
                            <Update />
                        </ListItemIcon>
                        <ListItemText primary="Status Update" />
                    </MenuItem>
                )}
                {authState && authState.user && authState.user.role === "admin" && (
                    <MenuItem onClick={() => {window.open("/reports/accomplishmentplan/" + accomplishment_plan.id + "/comparison");actionMenuClose();}} style={{ borderBottom: "1px solid #666666"}}>
                        <ListItemIcon>
                            <GetApp />
                        </ListItemIcon>
                        <ListItemText primary="Comparison Report" />
                    </MenuItem>
                )}
                <MenuItem style={{borderBottom: "1px solid #666666",backgroundColor: "#eee",cursor: "default"}}>
                    <ListItemIcon>
                        <QuestionAnswer />
                    </ListItemIcon>
                    <ListItemText primary={"Status: " + accomplishment_plan.status} />
                </MenuItem>
            </Menu>
        </div>
    );

    render() {
        const { classes, accomplishment_plan, settings, history, authState, budgets } = this.props;
        const { snackbarOpen, missing, submissionErrorOpen, submissionOpen, loadingAmendment, statusUpdateOpen, statusUpdateOpen2, statusUpdateOpen3, statusUpdateOpen4, statusUpdateOpen5, statusUpdateOpenNew, q1, q2, q3, q4 } = this.state;

        if (!accomplishment_plan || !accomplishment_plan.create_date || loadingAmendment) {
            return (
                <AppContainer authenticated synced={true} />
            );
        }
        
        return (
            <AppContainer authenticated innerRef={this.appContainer}>
                <Grid container spacing={2}>
                    <PageHeader title={"ML " + accomplishment_plan.ml_year + " " + accomplishment_plan.name} />
                    <Grid item xs={12} className={classes.stepperWidth}>
                        <Stepper
                            nonLinear
                            className={classes.stepRoot}
                            alternativeLabel
                            activeStep={accomplishment_plan.activeStep || 0}>
                            {getSteps(authState).map((label, index) => {
                                return (
                                    <Step key={label} className={accomplishment_plan.activeStep !== index ? classes.pointer : classes.pointer2}>
                                        <StepButton className={classes.btnroot} focusVisibleClassName={classes.pointerr} onClick={() => this.handleStep(index, true)}>
                                            <StepLabel
                                                classes={{
                                                    labelContainer: classes.lessMargin,
                                                    iconContainer: classes.stepColor
                                                }}>
                                                {label}
                                            </StepLabel>
                                        </StepButton>
                                    </Step>
                                );
                            })}
                        </Stepper>
                        <div style={{ marginRight: 30, marginLeft: 30 }}>
                            {(accomplishment_plan.activeStep === 0 || !accomplishment_plan.activeStep) && (
                                <GeneralTab
                                    onRef={el => (this.activeForm = el)}
                                    accomplishment_plan={accomplishment_plan}
                                    appContainer={this.appContainer}
                                    handleNext={this.handleNext}
                                    handleUnsavedFields={this.handleUnsavedFields}
                                    settings={settings}
                                    ActionMenu={<this.ActionMenu authState={authState} classes={classes} accomplishment_plan={accomplishment_plan} actionMenuOpen={this.actionMenuOpen} anchorEl={this.state.anchorEl} actionMenuClose={this.actionMenuClose} />}
                                />
                            )}
                            {accomplishment_plan.activeStep === 1 && (
                                <NarrativeTab
                                    onRef={el => (this.activeForm = el)}
                                    accomplishment_plan={accomplishment_plan}
                                    appContainer={this.appContainer}
                                    handleNext={this.handleNext}
                                    handleUnsavedFields={this.handleUnsavedFields}
                                    settings={settings}
                                    ActionMenu={<this.ActionMenu authState={authState} classes={classes} accomplishment_plan={accomplishment_plan} actionMenuOpen={this.actionMenuOpen} anchorEl={this.state.anchorEl} actionMenuClose={this.actionMenuClose} />}
                                />
                            )}
                            {accomplishment_plan.activeStep === 2 && (
                                <ActivityTab
                                    onRef={el => (this.activeForm = el)}
                                    accomplishment_plan={accomplishment_plan}
                                    appContainer={this.appContainer}
                                    handleNext={this.handleNext}
                                    handleUnsavedFields={this.handleUnsavedFields}
                                    settings={settings}
                                    ActionMenu={<this.ActionMenu authState={authState} classes={classes} accomplishment_plan={accomplishment_plan} actionMenuOpen={this.actionMenuOpen} anchorEl={this.state.anchorEl} actionMenuClose={this.actionMenuClose} />}
                                />
                            )}
                            {accomplishment_plan.activeStep === 3 && (
                                <TimelineTab
                                    onRef={el => (this.activeForm = el)}
                                    accomplishment_plan={accomplishment_plan}
                                    appContainer={this.appContainer}
                                    handleNext={this.handleNext}
                                    handleUnsavedFields={this.handleUnsavedFields}
                                    settings={settings}
                                    ActionMenu={<this.ActionMenu authState={authState} classes={classes} accomplishment_plan={accomplishment_plan} actionMenuOpen={this.actionMenuOpen} anchorEl={this.state.anchorEl} actionMenuClose={this.actionMenuClose} />}
                                />
                            )}
                            {accomplishment_plan.activeStep === 4 && (
                                <BudgetTab
                                    onRef={el => (this.activeForm = el)}
                                    accomplishment_plan={accomplishment_plan}
                                    appContainer={this.appContainer}
                                    handleNext={this.handleNext}
                                    handleUnsavedFields={this.handleUnsavedFields}
                                    settings={settings}
                                    ActionMenu={<this.ActionMenu authState={authState} classes={classes} accomplishment_plan={accomplishment_plan} actionMenuOpen={this.actionMenuOpen} anchorEl={this.state.anchorEl} actionMenuClose={this.actionMenuClose} />}
                                />
                            )}
                            {accomplishment_plan.activeStep === 5 && (
                                <FederalTab
                                    onRef={el => (this.activeForm = el)}
                                    accomplishment_plan={accomplishment_plan}
                                    appContainer={this.appContainer}
                                    handleNext={this.handleNext}
                                    handleUnsavedFields={this.handleUnsavedFields}
                                    settings={settings}
                                    ActionMenu={<this.ActionMenu authState={authState} classes={classes} accomplishment_plan={accomplishment_plan} actionMenuOpen={this.actionMenuOpen} anchorEl={this.state.anchorEl} actionMenuClose={this.actionMenuClose} />}
                                />
                            )}
                            {accomplishment_plan.activeStep === 6 && (
                                <OutputTab
                                    onRef={el => (this.activeForm = el)}
                                    accomplishment_plan={accomplishment_plan}
                                    appContainer={this.appContainer}
                                    handleNext={this.handleNext}
                                    handleUnsavedFields={this.handleUnsavedFields}
                                    settings={settings}
                                    ActionMenu={<this.ActionMenu authState={authState} classes={classes} accomplishment_plan={accomplishment_plan} actionMenuOpen={this.actionMenuOpen} anchorEl={this.state.anchorEl} actionMenuClose={this.actionMenuClose} />}
                                />
                            )}
                            {accomplishment_plan.activeStep === 7 && (
                                <OutcomesTab
                                    onRef={el => (this.activeForm = el)}
                                    accomplishment_plan={accomplishment_plan}
                                    appContainer={this.appContainer}
                                    handleNext={this.handleNext}
                                    handleUnsavedFields={this.handleUnsavedFields}
                                    settings={settings}
                                    ActionMenu={<this.ActionMenu authState={authState} classes={classes} accomplishment_plan={accomplishment_plan} actionMenuOpen={this.actionMenuOpen} anchorEl={this.state.anchorEl} actionMenuClose={this.actionMenuClose} />}
                                />
                            )}
                            {accomplishment_plan.activeStep === 8 && (
                                <ParcelTab
                                    onRef={el => (this.activeForm = el)}
                                    accomplishment_plan={accomplishment_plan}
                                    appContainer={this.appContainer}
                                    handleNext={this.handleNext}
                                    handleUnsavedFields={this.handleUnsavedFields}
                                    settings={settings}
                                    ActionMenu={<this.ActionMenu authState={authState} classes={classes} accomplishment_plan={accomplishment_plan} actionMenuOpen={this.actionMenuOpen} anchorEl={this.state.anchorEl} actionMenuClose={this.actionMenuClose} />}
                                />
                            )}
                            {accomplishment_plan.activeStep === 9 && (
                                <AttachmentTab
                                    onRef={el => (this.activeForm = el)}
                                    accomplishment_plan={accomplishment_plan}
                                    appContainer={this.appContainer}
                                    handleNext={this.handleNext}
                                    handleUnsavedFields={this.handleUnsavedFields}
                                    settings={settings}
                                    ActionMenu={<this.ActionMenu authState={authState} classes={classes} accomplishment_plan={accomplishment_plan} actionMenuOpen={this.actionMenuOpen} anchorEl={this.state.anchorEl} actionMenuClose={this.actionMenuClose} />}
                                />
                            )}
                            {accomplishment_plan.activeStep === 10 && (
                                <AmendmentsTab
                                    onRef={el => (this.activeForm = el)}
                                    accomplishment_plan={accomplishment_plan}
                                    appContainer={this.appContainer}
                                    handleNext={this.finalSubmit}
                                    handleUnsavedFields={this.handleUnsavedFields}
                                    settings={settings}
                                    ActionMenu={<this.ActionMenu authState={authState} classes={classes} accomplishment_plan={accomplishment_plan} actionMenuOpen={this.actionMenuOpen} anchorEl={this.state.anchorEl} actionMenuClose={this.actionMenuClose} />}
                                />
                            )}
                            {accomplishment_plan.activeStep === 11 && (
                                <CouncilTab
                                    onRef={el => (this.activeForm = el)}
                                    accomplishment_plan={accomplishment_plan}
                                    appContainer={this.appContainer}
                                    handleUnsavedFields={this.handleUnsavedFields}
                                    settings={settings}
                                    ActionMenu={<this.ActionMenu authState={authState} classes={classes} accomplishment_plan={accomplishment_plan} actionMenuOpen={this.actionMenuOpen} anchorEl={this.state.anchorEl} actionMenuClose={this.actionMenuClose} />}
                                />
                            )}
                        </div>
                    </Grid>
                </Grid>
                <Snackbar
                    handleSnackbarClose={this.handleSnackbarClose}
                    isSuccess
                    snackbarOpen={snackbarOpen}
                />
                <Dialog open={submissionErrorOpen} classes={{ paper: classes.minWidth }}>
                    <DialogTitle className={classes.titleContainer}>
                        <Warning className={classes.supportIcon} />
                        <IconButton aria-label="Close Dialog" onClick={() => this.setState({submissionErrorOpen: false})} className={classes.closeIcon}>
                            <Close />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText style={{marginTop:24}}>
                            <Typography>
                                Please correct the following errors
                                <ul>
                                    {missing && missing.map(m => (
                                        <li>{m}</li>
                                    ))}
                                </ul>
                            </Typography>
                        </DialogContentText>
                        <DialogActions className={classes.centerButtons}>
                            <Button onClick={() => this.setState({submissionErrorOpen: false})} color="primary" variant="contained">
                                Ok
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </Dialog>
                <Dialog open={submissionOpen} classes={{ paper: classes.minWidth }}>
                    <DialogTitle className={classes.titleContainer}>
                        <Done className={classes.supportIcon} />
                        <IconButton aria-label="Close Dialog" onClick={() => history.push("/dashboard/")} className={classes.closeIcon}>
                            <Close />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText style={{marginTop:24}}>
                            <Typography>
                                <div dangerouslySetInnerHTML={{ __html: settings.confirmation_message_ap }} />
                            </Typography>
                        </DialogContentText>
                        <DialogActions className={classes.centerButtons}>
                            <Button onClick={() => history.push("/dashboard/")} color="primary" variant="contained">
                                Return to Dashboard
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </Dialog>
                <Dialog open={statusUpdateOpen} classes={{ paper: classes.minWidth }}>
                    <DialogTitle className={classes.titleContainer} style={{color:"white"}}>
                        Status Update Wizard
                        <IconButton aria-label="Close Dialog" onClick={() => this.setState({statusUpdateOpen: false})} className={classes.closeIcon}>
                            <Close />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Typography>
                                This is a pre-status update checklist.
                                Please review your accomplishment plan when answering these questions.
                                Press next to continue.
                            </Typography>
                        </DialogContentText>
                        <DialogActions className={classes.centerButtons}>
                            <Button onClick={() => {
                                this.setState({statusUpdateOpen: false, statusUpdateOpen2:true});
                                this.props.ormAccomplishmentPlanUpdateLocalOnly({
                                    id: accomplishment_plan.id,
                                    activeStep: 6
                                });
                                this.actionMenuClose();
                            }} color="primary" variant="contained">
                                Next
                            </Button>
                            <Button onClick={() => this.setState({statusUpdateOpen: false})} variant="contained">
                                Cancel
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </Dialog>
                <Dialog open={statusUpdateOpen2} classes={{ paper: classes.minWidth }}>
                    <DialogTitle className={classes.titleContainer} style={{color:"white"}}>
                        Status Update Wizard
                        <IconButton aria-label="Close Dialog" onClick={() => this.setState({statusUpdateOpen2: false})} className={classes.closeIcon}>
                            <Close />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Typography>
                                <Form dontValidateOnMount={true} validateOnSubmit={true}>
                                    {formApi => (
                                        <form onSubmit={formApi.submitForm}>
                                            <RadioGroup
                                                field="q1"
                                                name="q1"
                                                fullWidth
                                                eventHandle={(val) => this.setState({q1: val})}
                                                options={[
                                                    { label: "Yes", value: "Yes" },
                                                    { label: "No", value: "No" }
                                                ]}
                                                alignment={true}
                                                label="1) Have there been any significant changes to outputs anticipated to achieve (acres or dollars in regions or eco types) since your last accomplishment plan amendment?"
                                            />
                                        </form>
                                    )}
                                </Form>
                            </Typography>
                        </DialogContentText>
                        <DialogActions className={classes.centerButtons}>
                            <Button onClick={() => {
                                if (q1) {
                                    this.setState({statusUpdateOpen2: false,  statusUpdateOpen3:true});
                                    this.props.ormAccomplishmentPlanUpdateLocalOnly({
                                        id: accomplishment_plan.id,
                                        activeStep: 4
                                    });
                                    this.actionMenuClose();
                                }
                            }} color="primary" variant="contained">
                                Next
                            </Button>
                            <Button onClick={() => this.setState({statusUpdateOpen2: false})} variant="contained">
                                Cancel
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </Dialog>
                <Dialog open={statusUpdateOpen3} classes={{ paper: classes.minWidth }}>
                    <DialogTitle className={classes.titleContainer} style={{color:"white"}}>
                        Status Update Wizard
                        <IconButton aria-label="Close Dialog" onClick={() => this.setState({statusUpdateOpen3: false})} className={classes.closeIcon}>
                            <Close />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Typography>
                                <Form dontValidateOnMount={true} validateOnSubmit={true}>
                                    {formApi => (
                                        <form onSubmit={formApi.submitForm}>
                                            <RadioGroup
                                                field="q2"
                                                name="q2"
                                                fullWidth
                                                eventHandle={(val) => this.setState({q2: val})}
                                                options={[
                                                    { label: "Yes", value: "Yes" },
                                                    { label: "No", value: "No" }
                                                ]}
                                                alignment={true}
                                                label="2) Have there been any budgetary shifts (between line items) since your last accomplishment plan amendment?"
                                            />
                                        </form>
                                    )}
                                </Form>
                            </Typography>
                        </DialogContentText>
                        <DialogActions className={classes.centerButtons}>
                            <Button onClick={() => {
                                if (q2) {
                                    this.setState({statusUpdateOpen3: false,  statusUpdateOpen4:true});
                                    this.props.ormAccomplishmentPlanUpdateLocalOnly({
                                        id: accomplishment_plan.id,
                                        activeStep: 8
                                    });
                                    this.actionMenuClose();
                                }
                            }} color="primary" variant="contained">
                                Next
                            </Button>
                            <Button onClick={() => this.setState({statusUpdateOpen3: false})} variant="contained">
                                Cancel
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </Dialog>
                <Dialog open={statusUpdateOpen4} classes={{ paper: classes.minWidth }}>
                    <DialogTitle className={classes.titleContainer} style={{color:"white"}}>
                        Status Update Wizard
                        <IconButton aria-label="Close Dialog" onClick={() => this.setState({statusUpdateOpen4: false})} className={classes.closeIcon}>
                            <Close />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Typography>
                                <Form dontValidateOnMount={true} validateOnSubmit={true}>
                                    {formApi => (
                                        <form onSubmit={formApi.submitForm}>
                                            <RadioGroup
                                                field="q3"
                                                name="q3"
                                                fullWidth
                                                eventHandle={(val) => this.setState({q3: val})}
                                                options={[
                                                    { label: "Yes", value: "Yes" },
                                                    { label: "No", value: "No" }
                                                ]}
                                                alignment={true}
                                                label="3) Have there been additions to the parcel list since your last accomplishment plan amendment? "
                                            />
                                        </form>
                                    )}
                                </Form>
                            </Typography>
                        </DialogContentText>
                        <DialogActions className={classes.centerButtons}>
                            <Button onClick={() => {
                                if (q3) {

                                    var _t1 = budgets.filter(b => b.item === "DNR IDP");
                                    var _funding_request = 0;
                                    _t1.forEach(function(t) {
                                        _funding_request += t.funding_request
                                    });
                                    if (_funding_request > 0) {
                                        this.setState({statusUpdateOpen4: false,  statusUpdateOpenNew:true});
                                    } else {
                                        this.setState({statusUpdateOpen4: false,  statusUpdateOpen5:true});
                                    }
                                    this.actionMenuClose();
                                }
                            }} color="primary" variant="contained">
                                Next
                            </Button>
                            <Button onClick={() => this.setState({statusUpdateOpen4: false})} variant="contained">
                                Cancel
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </Dialog>
                <Dialog open={statusUpdateOpenNew} classes={{ paper: classes.minWidth }}>
                    <DialogTitle className={classes.titleContainer} style={{color:"white"}}>
                        Status Update Wizard
                        <IconButton aria-label="Close Dialog" onClick={() => this.setState({statusUpdateOpenNew: false})} className={classes.closeIcon}>
                            <Close />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Typography gutterBottom>
                                4) This is NEW!! You are receiving this message because you have money budgeted in the IDP budget line item.  
                            </Typography>
                            <Typography gutterBottom>
                                If you are <b>ONLY</b> waiting to close out the grant due to final spending of IDP monies transferred to DNR and there has been no additional work accomplished and no budget expenditures since your last status update, check the box below.  
                            </Typography>
                            <Typography gutterBottom> 
                                In checking the statement below, you are allowed to skip the full completion of the status update and this will serve as a status update until the final report submission.   
                            </Typography>
                            <Typography>
                                <Form dontValidateOnMount={true} validateOnSubmit={true}>
                                    {formApi => (
                                        <form onSubmit={formApi.submitForm}>
                                            <RadioGroup
                                                field="q4"
                                                name="q4"
                                                fullWidth
                                                eventHandle={(val) => this.setState({q4: val})}
                                                options={[
                                                    { label: "I attest that there is nothing new to report since the last status update; there has been no additional budget expenditures or output changes since the last Status Update and I would like the AP moved forward to the final report dashboard for initial completion. This program is ONLY waiting to close out the grant due to final spending of IDP monies.", value: "I attest that there is nothing new to report since the last status update; there has been no additional budget expenditures or output changes since the last Status Update and I would like the AP moved forward to the final report dashboard for initial completion. This program is ONLY waiting to close out the grant due to final spending of IDP monies." },
                                                    { label: "I would like to complete a full status update report for continued spending and reimbursements on program activities.", value: "I would like to complete a full status update report for continued spending and reimbursements on program activities." }
                                                ]}
                                            />
                                            {q4 === "I attest that there is nothing new to report since the last status update; there has been no additional budget expenditures or output changes since the last Status Update and I would like the AP moved forward to the final report dashboard for initial completion. This program is ONLY waiting to close out the grant due to final spending of IDP monies." && (
                                                <TextField
                                                    field="q4_parcels" 
                                                    multiline
                                                    eventHandle={(val) => this.typingTimerUpdate(val)}
                                                    rows={4}
                                                    label="Please briefly list the IDP affected parcels waiting completion: parcel name, amount of IDP funds, and status on the spending of those IDP monies, anticipated date of completion."
                                                />   
                                            )}
                                        </form>
                                    )}
                                </Form>
                            </Typography>
                        </DialogContentText>
                        <DialogActions className={classes.centerButtons}>
                            <Button onClick={() => {
                                if (q4) {
                                    this.setState({statusUpdateOpenNew: false,  statusUpdateOpen5:true});
                                    this.actionMenuClose();
                                }
                            }} color="primary" variant="contained">
                                Next
                            </Button>
                            <Button onClick={() => this.setState({statusUpdateOpenNew: false})} variant="contained">
                                Cancel
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </Dialog>
                <Dialog open={statusUpdateOpen5} classes={{ paper: classes.minWidth }}>
                    <DialogTitle className={classes.titleContainer} style={{color:"white"}}>
                        Status Update Wizard
                        <IconButton aria-label="Close Dialog" onClick={() => this.setState({statusUpdateOpen5: false})} className={classes.closeIcon}>
                            <Close />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {(q1 === "Yes" || q2 === "Yes" || q3 === "Yes") && (
                                <Typography>
                                    You have indicated that there are changes to the:
                                    {q1 === "Yes" && (
                                        <>
                                            <br/>- Outcome tables
                                        </>
                                    )}
                                    {q2 === "Yes" && (
                                        <>
                                            <br/>- Budget tables
                                        </>
                                    )}
                                    {q3 === "Yes" && (
                                        <>
                                            <br/>- Parcel tables
                                        </>
                                    )}
                                    <br/><br/>Press continue to fill out an amendment change for these items.
                                </Typography>
                            )}
                            {(q1 !== "Yes" && q2 !== "Yes" && q3 !== "Yes") && q4 !== "I attest that there is nothing new to report since the last status update; there has been no additional budget expenditures or output changes since the last Status Update and I would like the AP moved forward to the final report dashboard for initial completion. This program is ONLY waiting to close out the grant due to final spending of IDP monies." && (
                                <Typography>
                                    Because you have indicated that you have no changes since you last accomplishment plan approval, 
                                    you are approved to complete the bi-annual status update. Press continue to advance.
                                </Typography>
                            )}
                            {(q1 !== "Yes" && q2 !== "Yes" && q3 !== "Yes") && q4 === "I attest that there is nothing new to report since the last status update; there has been no additional budget expenditures or output changes since the last Status Update and I would like the AP moved forward to the final report dashboard for initial completion. This program is ONLY waiting to close out the grant due to final spending of IDP monies." && (
                                <Typography>
                                    Because you have indicated that only IDP monies are left a status update has been generated for you. Press continue to submit your status update.
                                </Typography>
                            )}
                        </DialogContentText>
                        <DialogActions className={classes.centerButtons}>
                            <Button onClick={() => {
                                if (q1 === "Yes" || q2 === "Yes" || q3 === "Yes") {
                                    this.requestAmendment();
                                } else {
                                    this.createStatusUpdate();
                                }
                            }} color="primary" variant="contained">
                                Continue
                            </Button>
                            <Button onClick={() => this.setState({statusUpdateOpen5: false})} variant="contained">
                                Cancel
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </Dialog>
            </AppContainer>
        );
    }
}

List = connect(
    (state, ownProps) => ({
        accomplishment_plan: getAccomplishmentPlan(state, ownProps),
        settings: getSystemSettings(state),
        parcels: getParcels(state, ownProps),
        budgets: getBudgets(state, ownProps),
        status_updates: getStatusUpdates(state, ownProps),
        authState: state.auth
    }),
    {
        ...AccomplishmentPlan.actions,
        ...AccomplishmentAmountNonOHF.actions,
        ...AccomplishmentFutureMaintain.actions,
        ...AccomplishmentTimeline.actions,
        ...AccomplishmentBudget.actions,
        ...AccomplishmentBudgetPersonnel.actions,
        ...AccomplishmentBudgetCapitalEquipment.actions,
        ...AccomplishmentParcel.actions,
        ...AccomplishmentAttachment.actions,
        ...AccomplishmentLog.actions,
        ...AmendmentLog.actions,
        ...StatusUpdate.actions
    }
)(List);

export default withStyles(styles)(withRouter(List));
