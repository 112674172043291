import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import Tooltip from "@material-ui/core/Tooltip";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";


import { withStyles } from "@material-ui/core";
import ChevronRight from "@material-ui/icons/ChevronRight";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleIcon from "@material-ui/icons/AddCircle";

import { FinalReport, FinalFutureMaintain } from "../models";

import TextField from "../../common/TextField";
import CheckboxGroup from "../../common/CheckboxGroup";
import Subquestion from "../../common/Subquestion";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import CustomTableCell from "../../common/TableCell";
import HelpLabel from "../../common/HelpLabel";
import { createSelector } from "../../common/orm";
import { MAKE_OPTIONS } from "../../../api/constants";
import { getValue } from "../../../api/utils";


const getFutureMaintains = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.FinalFutureMaintain.filter({ final: id }).orderBy("id").toModelArray();
    }
);

const getBudgets = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.FinalBudget.filter({ final: id }).orderBy("id").toModelArray()
        .map(budget => ({
            _personnel: budget.budgetpersonnel.all().toRefArray(),
            _capital: budget.budgetcapital.all().toRefArray(),
            ...budget.ref
        }));
    }
);

const styles = theme => ({
    rightAlign: {
        textAlign: "right"
    },
    button: {
        marginRight: theme.spacing(2)
    },
    table: {
        width: "100%",
        "& tbody tr:nth-child(even)": {
            backgroundColor: "#ffeff2"
        },
        marginBottom:16
    },
    deleteWidth: {
        minWidth: 32,
        width: 32,
        height: 32,
        marginRight: 8,
    },
    centerAlign: {
        textAlign: "center"
    },
    nowrap: {
        whiteSpace: "nowrap"
    },
});

class NarrativeTab extends Component {
    componentDidMount() {
        const { handleUnsavedFields } = this.props;
        document.title = "Final Report: Narrative - Lessard Sams Outdoor Heritage Council";
        this.props.onRef(this);
        const _this = this;
        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted
        setTimeout(function() {
            handleUnsavedFields(false);
            _this.props.appContainer.current.scrollTop();
        }, 100);
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    updateAP(values, fromStepper) {
        const { ormFinalReportUpdate, history, handleUnsavedFields, handleNext, final_report } = this.props;
        const { id } = final_report;

        if (Number.isInteger(fromStepper)) {
            values.activeStep = fromStepper;
        }
        values.modify_date_label = final_report.modify_date_label;
        values.signup_criteria = final_report.signup_criteria;

        ormFinalReportUpdate({
            id: id,
            ...values
        });

        handleUnsavedFields(false);

        if (!Number.isInteger(fromStepper)) {
            if (this.state.draftClick) {
                history.push("/dashboard/");
            } else {
                handleNext();
            }
        }
    }

    applyOther = (value, element) => {
        const { ormFinalReportUpdateLocalOnly, final_report} = this.props;
        const { id } = final_report;
        ormFinalReportUpdateLocalOnly({
            id: id,
            [element]: value
        });
        this.setState({ foo: "bar" });
    };

    groupBy = (list, keyGetter) => {
        const map = new Map();
        list.forEach((item) => {
             const key = keyGetter(item);
             const collection = map.get(key);
             if (!collection) {
                 map.set(key, [item]);
             } else {
                 collection.push(item);
             }
        });
        return map;
    };

    render() {
        const { 
            classes,
            final_report,
            budgets,
            handleUnsavedFields,
            futureMaintains,
            ormFinalFutureMaintainCreate,
            ormFinalFutureMaintainDelete,
            ormFinalFutureMaintainUpdate,
            settings,
            ActionMenu, authState
        } = this.props;
        var is_read_only = true;
        if ((authState && authState.user && authState.user.role === "admin") || final_report.status === "Draft")
            is_read_only = false;
        if (authState && authState.user && authState.user.role === "readonly")
            is_read_only = true;

        var anticipated_leverage = 0;
        var funding_request = 0;
        var budget_percent = "-";
        const groupedItem = this.groupBy(budgets, b => b.item);
        groupedItem.get("Grand Total").forEach(function(gi) {
            anticipated_leverage += gi.anticipated_leverage_final;
            funding_request += gi.funding_request_final;
        });
        if (funding_request > 0) {
            budget_percent = (Math.round(((anticipated_leverage/funding_request) * 100) * 100) / 100).toLocaleString('en') + "%";
        }

        const year = parseInt(final_report.ml_year);
        const showOther2 = getValue(final_report, "relationship_other_funds").indexOf("Other") > -1;

        return (
            <Form
                getApi={el => (this.form = el)}
                key={final_report.id}
                dontValidateOnMount={true}
                validateOnSubmit={true}
                defaultValues={final_report.formData}
                formDidUpdate={() => handleUnsavedFields(true)}
                onSubmit={(values, fromStepper) => this.updateAP(values, fromStepper)}>
                {formApi => (
                    <form onSubmit={formApi.submitForm}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <HelpLabel
                                    inputLabel="Narrative"
                                    title={true}
                                    showLabel={true}
                                    helpText={settings.narrative_tab_help}
                                />
                                {ActionMenu}
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    field="summary"
                                    label = "Summary of Accomplishments"
                                    maxWords={!is_read_only && year >= 2014 && "100"}
                                    multiline
                                    disabled={is_read_only}
                                    rows={4}
                                    rowsMax={20}
                                    fullWidth
                                />
                                <TextField
                                    field="process_methods"
                                    label="Process & Methods"
                                    maxWords={!is_read_only && (year >= 2014 && year <= 2016) ? "1000" : year >= 2017 ? "500" : null}
                                    disabled={is_read_only}
                                    multiline
                                    rows={8}
                                    rowsMax={20}
                                    fullWidth
                                />
                                {year >= 2015 && (
                                    <TextField
                                        field="habitats_significant_value"
                                        maxWords={!is_read_only && "350"}
                                        multiline
                                        disabled={is_read_only}
                                        rows={4}
                                        rowsMax={20}
                                        label="How did the program address habitats of significant value for wildlife species of greatest conservation need, threatened or endangered species, and/or list targeted species?"
                                        fullWidth
                                    />
                                )}
                                {year >= 2015 && (
                                    <TextField
                                        field="science_based_targeting"
                                        maxWords={!is_read_only && "350"}
                                        multiline
                                        disabled={is_read_only}
                                        rows={4}
                                        rowsMax={20}
                                        fullWidth
                                        label="How did the program use science-based targeting that leveraged or expanded corridors and complexes, reduced fragmentation, or protected areas in the MN County Biological Survey."
                                    />
                                )}
                                <TextField
                                    field="explain_partners_supporters"
                                    maxWords={!is_read_only && "150"}
                                    multiline
                                    disabled={is_read_only}
                                    rows={4}
                                    rowsMax={20}
                                    fullWidth
                                    label="Explain Partners, Supporters, & Opposition."
                                />
                                <TextField
                                    field="exceptional_challenges"
                                    maxWords={!is_read_only && "150"}
                                    multiline
                                    disabled={is_read_only}
                                    rows={4}
                                    rowsMax={20}
                                    fullWidth
                                    label="Exceptional challenges, expectations, failures, opportunities, or unique aspects of program."
                                />
                                <CheckboxGroup
                                    field="relationship_other_funds"
                                    row={true}
                                    disabled={is_read_only}
                                    eventHandle={this.applyOther}
                                    options={MAKE_OPTIONS([
                                        'Arts and Cultural Heritage Fund',
                                        'Clean Water Fund',
                                        'Environment and Natural Resource Trust Fund',
                                        'Parks and Trails Fund',
                                        year <= 2020 && 'Other'
                                    ])}
                                >
                                    <HelpLabel
                                        inputLabel="What other dedicated funds may collaborate with or contribute to this program?"
                                        showLabel={true}
                                        helpText={
                                            <div>
                                                Detail other funds which you have requested, received, or anticipate requesting for this program.<br/><br/>
                                                Check all that apply.
                                            </div>
                                        }
                                    />
                                </CheckboxGroup>
                                {showOther2 && year <= 2020 && (
                                    <Subquestion component={
                                        <TextField
                                            field="relationship_other_funds_other"
                                            disabled={is_read_only}
                                            label="What is your other relationship?"
                                            fullWidth
                                        />
                                    } />
                                )}
                                {final_report.relationship_other_funds.length > 0 && (
                                    <Subquestion component={
                                        <TextField
                                            field="relationship_other_funds_other_desc"
                                            disabled={is_read_only}
                                            multiline
                                            rows={4}
                                            rowsMax={20}
                                            maxWords={!is_read_only && year >= 2014 && "350"}
                                            label="How were the funds used to advance the program?"
                                            fullWidth
                                        />
                                    } />
                                )}
                                {year >= 2015 && (
                                    <>
                                    <HelpLabel inputLabel="Budget Values" special={true} helpText="These figures are auto populated from the budget page" />
                                    <Typography>
                                        Amount of Request: <b>${funding_request.toLocaleString('en')}</b>
                                    </Typography>
                                    <Typography>
                                        Amount of Leverage:	<b>${anticipated_leverage.toLocaleString('en')}</b>
                                    </Typography>
                                    <Typography gutterBottom>
                                        Leverage as a percent of the Request: <b>{budget_percent}</b>
                                    </Typography>
                                    </>
                                )}
                                <TextField
                                    field="sustain_after_expend"
                                    maxWords={!is_read_only && year >= 2014 && "250"}
                                    disabled={is_read_only}
                                    multiline
                                    rows={4}
                                    rowsMax={20}
                                    fullWidth
                                    label="What is the plan to sustain and/or maintain this work after the Outdoor Heritage Funds are expended?"
                                />
                                {year >= 2014 && (
                                    <>
                                    <Typography gutterBottom>
                                        Complete the table explaining the actions you will take in the future to maintain project outcomes, list the year as approximate.
                                        <Button disabled={is_read_only} style={{marginLeft:16}} variant="contained" color="primary" onClick={() => ormFinalFutureMaintainCreate({final: final_report.id})}>
                                            <AddCircleIcon />
                                            &nbsp;&nbsp;&nbsp;Add Row
                                        </Button>
                                    </Typography>
                                    <FutureMaintainTable
                                        ormFinalFutureMaintainUpdate={ormFinalFutureMaintainUpdate}
                                        ormFinalFutureMaintainDelete={ormFinalFutureMaintainDelete}
                                        rows={futureMaintains}
                                        is_read_only={is_read_only}
                                        classes={classes}
                                    />
                                    </>
                                )}
                                {year >= 2022 && (
                                    <TextField
                                        field="program_involve_bipoc"
                                        maxWords={!is_read_only && "350"}
                                        multiline
                                        disabled={is_read_only}
                                        rows={4}
                                        rowsMax={20}
                                        fullWidth
                                    >
                                        <HelpLabel
                                            inputLabel={
                                            <span>
                                                Provide an assessment of how your program celebrates cultural diversity or reach diverse communities in Minnesota, including reaching low- and moderate-income households? (As
                                                &nbsp;<a rel="noopener noreferrer" href='https://www.revisor.mn.gov/statutes/cite/97A.056#stat.97A.056.11' target='_blank'>per 97A.056, subd 11 (d)</a>)
                                            </span>}
                                            showLabel={true}
                                            helpText={
                                                <div>
                                                    Some considerations:
                                                    <br/><br/>
                                                    Is the program nearby or accessible to diverse or low-income area on MN?
                                                    <br/><br/>
                                                    Does the program have opportunities for lower-barrier recreations (wildlife viewing /shore line fishing)?
                                                    <br/><br/>
                                                    How does your program communication and outreach focus on diversity?
                                                    <br/><br/>
                                                    How does your program engage with tribes and diverse organizations?
                                                    <br/><br/>
                                                    Is traditional ecological knowledge or community knowledge integrated into the program? 
                                                </div>
                                            }
                                        />
                                    </TextField>
                                )}
                            </Grid>
                            <Grid item xs={12} className={classes.rightAlign}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={is_read_only}
                                    onClick={() => this.setState({ draftClick: true, submitClicked: true })}
                                    className={classes.button}>
                                    Save Draft and Return to Dashboard
                                </Button>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={is_read_only}
                                    color="primary"
                                    onClick={() => this.setState({ draftClick: false, submitClicked: true })}>
                                    Save and Proceed to Budget <ChevronRight />
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Form>
        );
    }
}

class FutureMaintainTable extends Component {
    columnData = [
        { id: "year", numeric: false, label: "Year", allowSort: false },
        { id: "source", numeric: false, label: "Source of Funds", allowSort: false },
        { id: "step1", numeric: false, label: "Step 1", allowSort: false },
        { id: "step2", numeric: false, label: "Step 2", allowSort: false },
        { id: "step3", numeric: false, label: "Step 3", allowSort: false },
        { id: "actions", numeric: false, label: "Actions", allowSort: false, width: "20px" },
    ];
    typingTimer = [];

    updateRecord = (value, element, id) => {
        const { ormFinalFutureMaintainUpdate } = this.props;

        clearTimeout(this.typingTimer[element]);
        this.typingTimer[element] = setTimeout(function() {
            ormFinalFutureMaintainUpdate({
                id: id,
                [element]: value,
            });
        }, 1000);
    };

    render() {
        const { rows, classes, ormFinalFutureMaintainDelete, is_read_only } = this.props;

        return (
            <Table className={classes.table}>
                <EnhancedTableHead columnData={this.columnData} />
                <TableBody>
                    {rows.map(n => {
                        return (
                            <TableRow hover key={n.id}>
                                <CustomTableCell>
                                    <Form key={n.id + "year"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={is_read_only} multiline tableFormat eventHandle={(v, e) => this.updateRecord(v, e, n.id)} field="year" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form key={n.id + "source"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={is_read_only} multiline tableFormat eventHandle={(v, e) => this.updateRecord(v, e, n.id)} field="source" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form key={n.id + "step1"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={is_read_only} multiline tableFormat eventHandle={(v, e) => this.updateRecord(v, e, n.id)} field="step1" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form key={n.id + "step2"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={is_read_only} multiline tableFormat eventHandle={(v, e) => this.updateRecord(v, e, n.id)} field="step2" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form key={n.id + "step3"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={is_read_only} multiline tableFormat eventHandle={(v, e) => this.updateRecord(v, e, n.id)} field="step3" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell className={classes.nowrap}>
                                    <Tooltip title="Delete Record">
                                        <Button
                                            color="primary"
                                            disabled={is_read_only}
                                            className={classes.deleteWidth}
                                            onClick={() => ormFinalFutureMaintainDelete(n.id)}>
                                            <DeleteIcon color="primary" />
                                        </Button>
                                    </Tooltip>
                                </CustomTableCell>
                            </TableRow>
                        );
                    })}
                    {rows.length < 1 && (
                        <TableRow>
                            <CustomTableCell colSpan={6} className={classes.centerAlign}>
                                No Records Found
                            </CustomTableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        );
    }
}

NarrativeTab = connect(
    (state, ownProps) => ({
        futureMaintains: getFutureMaintains(state, ownProps),
        budgets: getBudgets(state, ownProps),
        authState: state.auth
    }),
    {
        ...FinalReport.actions,
        ...FinalFutureMaintain.actions
    }
)(NarrativeTab);

export default withStyles(styles)(withRouter(NarrativeTab));
